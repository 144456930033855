import React from "react";
import SearchFilter from "./SearchFilter/SearchFilter";

function SearchComponent() {
  return (
    <div>
      <SearchFilter />
    </div>
  );
}

export default SearchComponent;