import React, { useState } from "react";
import "./pagination-product.scss";
import { useDispatch, useSelector } from "react-redux";
import { addPage } from "../../redux/actions/pageActions";
import Pagination from "@mui/material/Pagination";

export default function Index() {
  const dispatch = useDispatch();
  const totalCount = useSelector((state) => state.filter.totalCount);
  const pageCount = totalCount <= 10000 ? Math.ceil(totalCount / 10) : 1000;
  // const [page, setPage] = useState(1);
  const page = useSelector((state) => state.page.page);

  function onChange(event, pageNumber) {
    dispatch(addPage(pageNumber));
    if (pageNumber * 10 <= 10000) {
      dispatch(addPage(pageNumber));
    } else {
      alert("REFINE YOUR SEARCH!");
    }
  }

  return (
    <div className="container Header">
      <div className="row">
        <div className="col-12">
          <Pagination
            showFirstButton
            showLastButton
            count={pageCount}
            page={page}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
}
