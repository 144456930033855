import { filterConstans } from "../constants";

const initialState = {
  serach: "",
  contents: [],
  error: "",
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case filterConstans.SEARCH_KEYWORD:
      return {
        ...state,
        serach: action.payload,
      };
    case filterConstans.GET_CONTENTS_SUCCESS:
      return {
        ...state,
        contents: [...state.contents, action.payload],
      };
    case filterConstans.GET_CONTENTS_FAILURE:
      return {
        ...state,
        error: "Something went wrong",
      };
    default:
      return state;
  }
};

export default searchReducer;
