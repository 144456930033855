import React, { useRef, useState, useEffect } from "react";
import ProductsIncart from "../products-in-cart/Index";
import emailjs from "emailjs-com";
import "./checkout.scss";
import { useDispatch, useSelector } from "react-redux";
import { clearCart } from "../../redux/actions/cartActions";
import SnackbarCustom from "../CustomSnackbar";
import Checkbox from "@mui/material/Checkbox";
import { useTranslation } from "react-i18next";
import { selectCartItems } from "../../redux/selectors/cartSelector";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { sendForm } from "../../httpClient/index";

const Checkout = () => {
  const { t } = useTranslation();
  const cartItems = useSelector(selectCartItems);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [checked, setChecked] = useState(false);
  const handleChange = (e) => setChecked(e.target.checked);

  const [alertMessage, setAlertMessage] = useState("");
  const [alertStatus, setAlertStatus] = useState("");
  const [open, setOpen] = useState(false);

  const ReCAPTCHA_KEY = "6Lelk5ggAAAAALl4pMcKSsm8jD-xn0ppKSWhelxU";
  const recaptchaRef = React.createRef();

  const dispatch = useDispatch();

  function isValidEmail(value) {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(String(value).toLowerCase());
  }

  const validateEmail = (e) => {
    var email = e.target.value;
    if (isValidEmail(email)) {
      setEmailError("");
    } else {
      setEmailError("Invalid Email");
    }
  };

  const countrySelected = window.location.pathname.split("/");
  const countrySplitted = countrySelected[1];

  const [formCountry, setFormCountry] = useState("");

  useEffect(() => {
    if (countrySplitted === "al") {
      setFormCountry("Albania");
    } else if (countrySplitted === "bih") {
      setFormCountry("Bosnia");
    } else if (countrySplitted === "bg") {
      setFormCountry("Bulgaria");
    } else if (countrySplitted === "hr") {
      setFormCountry("Croatia");
    } else if (countrySplitted === "cz") {
      setFormCountry("CzechRepublic");
    } else if (countrySplitted === "hu") {
      setFormCountry("Hungary");
    } else if (countrySplitted === "xs") {
      setFormCountry("Kosovo");
    } else if (countrySplitted === "mk") {
      setFormCountry("NorthMacedonia");
    } else if (countrySplitted === "me") {
      setFormCountry("Montenegro");
    } else if (countrySplitted === "pl") {
      setFormCountry("Poland");
    } else if (countrySplitted === "ro") {
      setFormCountry("Romania");
    } else if (countrySplitted === "rs") {
      setFormCountry("Serbia");
    } else if (countrySplitted === "sl") {
      setFormCountry("Slovakia");
    } else if (countrySplitted === "sk") {
      setFormCountry("Slovakia");
    } else if (countrySplitted === "si") {
      setFormCountry("Slovenia");
    } else if (countrySplitted === "ch") {
      setFormCountry("Switzerland");
    } else {
      // setEmailTemplate("template_bmzt1hf");
      // setService("service_ajpy745");
      // setTemplateUser("yuvbPPAHFmsn1wDZ_");
      setFormCountry("Austria");
    }
  }, [formCountry]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [street, setStreet] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [message, setMessage] = useState("");

  const productLink = localStorage.getItem("productLink")
    ? JSON.parse(localStorage.getItem("productLink"))
    : [];

  const [productTxt, setProdTxt] = useState([]);

  useEffect(() => {
    let prodList = cartItems?.map((cart, i) => ({
      productName: `${cart?.productName}`,
      productQuantity: `${cart?.quantity}`,
      productSize: `${cart?.size}`,
      productLink: productLink[i],
      productSupplier: `${cart?.supplier?.name}`,
      productCode: `${cart?.sku}`,
    }));
    setProdTxt(prodList);
  }, [cartItems]);

  const handleEmailSubmit = (e) => {
    e.preventDefault();

    const params = {
      firstName: firstName,
      lastName: lastName,
      street: street,
      zipCode: `${zip}`,
      city: city,
      country: country,
      phoneNumber: `${phoneNumber}`,
      eMail: email,
      companyName: companyName,
      message: message,
      productQuoteRequest: {
        rows: productTxt,
      },
    };
    sendForm(params, formCountry)
      .then((res) => {
        setOpen(true);
        setAlertStatus("success");
        dispatch(clearCart());
        setTimeout(() => {
          backToProducts();
        }, 2000);
        setAlertMessage("Email was sent successfully");
      })
      .catch((error) => {
        setOpen(true);
        setAlertStatus("error");
        setAlertMessage("Email not sent");
      });

    e.target.reset();
  };

  const countryPrefix = window.location.pathname.split("/");
  const [prefix, setPrefix] = useState("+43");

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (countryPrefix[1] === "hu") {
      setPrefix("+36");
    } else if (countryPrefix[1] === "al") {
      setPrefix("+355");
    } else if (countryPrefix[1] === "bih") {
      setPrefix("+387");
    } else if (countryPrefix[1] === "bg") {
      setPrefix("+359");
    } else if (countryPrefix[1] === "hr") {
      setPrefix("+385");
    } else if (countryPrefix[1] === "cz") {
      setPrefix("+420");
    } else if (countryPrefix[1] === "xs") {
      setPrefix("+383");
    } else if (countryPrefix[1] === "mk") {
      setPrefix("+389");
    } else if (countryPrefix[1] === "me") {
      setPrefix("+382");
    } else if (countryPrefix[1] === "pl") {
      setPrefix("+48");
    } else if (countryPrefix[1] === "ro") {
      setPrefix("+40");
    } else if (countryPrefix[1] === "rs") {
      setPrefix("+381");
    } else if (countryPrefix[1] === "sl") {
      setPrefix("+421");
    } else if (countryPrefix[1] === "ch") {
      setPrefix("+41");
    } else {
      setPrefix("+43");
    }
  }, [prefix]);

  let url = window.location.href;
  const [URL, newURL] = useState();

  useEffect(() => {
    if (url.includes("cart")) {
      let urlSplited = url.replace(`cart`, "");
      newURL(urlSplited);
    }
  }, []);

  const backToProducts = () => {
    localStorage.removeItem("productLink");
    if (url.includes("cart")) {
      window.location.href = `${URL}products`;
    } else if (url.includes("cart")) {
      window.location.href = `${URL}privacy-statement`;
    } else {
      window.location.href = `${url}products`;
    }
  };

  // function onChangeC(value) {
  //   console.log("Captcha value:", value);
  // }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="check">
            <h1>{t("Checkout")}</h1>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="product">
            <a onClick={backToProducts} className="back_button">
              {t("back_to_products")}
            </a>
          </div>
        </div>
      </div>
      <ProductsIncart />
      <div className="wrapper_contactform">
        <form onSubmit={handleEmailSubmit}>
          <h2 className="heading">{t("contact_details")}</h2>
          <div className="form-controls">
            <div className="field">
              <div className="control">
                <label>{t("first_name")}*</label>
                <input
                  type="text"
                  name="from_name"
                  required={true}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="control">
                <label>{t("last_Name")}*</label>
                <input
                  type="text"
                  name="from_lastname"
                  required={true}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <div className="control">
                <label>
                  {t("Street")}{" "}
                  <span className="optional_field">({t("optional")})</span>
                </label>
                <input
                  type="text"
                  name="street"
                  value={street}
                  onChange={(e) => setStreet(e.target.value)}
                />
              </div>
              <div className="control">
                <label>
                  {t("zip_code")}{" "}
                  <span className="optional_field">({t("optional")})</span>
                </label>
                <input
                  type="text"
                  name="zip"
                  value={zip}
                  onChange={(e) => setZip(e.target.value)}
                />
              </div>
            </div>
            <div className="field">
              <div className="control">
                <label>
                  {t("City")}{" "}
                  <span className="optional_field">({t("optional")})</span>
                </label>
                <input
                  type="text"
                  name="city"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
              <div className="control" style={{ display: "none" }}>
                <input
                  type="text"
                  name="products"
                  value={productTxt}
                  // onChange={(e) => setRequestedProducts(e.target.value)}
                />
              </div>

              <div className="control">
                <label> {t("Country")}*</label>
                <input
                  type="text"
                  name="country"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                />
              </div>
              <div className="control">
                <label>
                  {t("phone_number")}{" "}
                  <span className="optional_field">({t("optional")})</span>
                </label>
                <PhoneInput
                  country={"at"}
                  className="type_tel"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e)}
                  preferredCountries={["at", "xk"]}
                  countryCodeEditable={true}
                />
              </div>
              <div className="control">
                <label>{t("Email")}*</label>
                <input
                  type="email"
                  name="email"
                  required={true}
                  value={email}
                  onChange={(value) => {
                    validateEmail(value, setEmailError);
                    setEmail(value.target.value);
                  }}
                  style={{
                    border:
                      email === ""
                        ? "1px solid #ddd"
                        : email !== "" && emailError === ""
                        ? "1px solid #0bb3a2"
                        : "1px solid #FF0000",
                  }}
                />
              </div>
            </div>

            <div className="field">
              <div className="control">
                <label>
                  {t("company_name")}{" "}
                  <span className="optional_field">({t("optional")})</span>
                </label>
                <input
                  type="text"
                  name="company"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </div>
            </div>

            <div className="message">
              <label>
                {t("Message")}{" "}
                <span className="optional_field">({t("optional")})</span>
              </label>
              <textarea
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>
            <div className="checkout_footer">
              <div className="check_to_send">
                <Checkbox checked={checked} onChange={handleChange} />
                <p onClick={() => setChecked(true)}>
                  {t("terms_condition")}:{" "}
                  <a
                    href={`${URL}privacy-statement`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("privacy_policy")}
                  </a>
                </p>
              </div>

              {/* <input
                type="submit"
                value="Request a Quote"
                className="sendMessageBtn"
                onClick={handleClearCart}
                style={{ cursor: checked ? "pointer" : "not-allowed" }}
                disabled={checked ? false : true}
              /> */}

              <button
                className="sendMessageBtn"
                style={{
                  cursor: checked ? "pointer" : "not-allowed",
                  // opacity: checked ? 1 : 0.7,
                }}
                disabled={checked ? false : true}
              >
                {t("Request_a_Quote")}
              </button>
            </div>
            {/* <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={ReCAPTCHA_KEY}
              onChange={onChangeC}
            /> */}
          </div>
        </form>
      </div>
      <SnackbarCustom
        alertStatus={alertStatus}
        handleClose={handleCloseSnackbar}
        open={open}
      >
        {alertMessage}
      </SnackbarCustom>
    </div>
  );
};

export default Checkout;
