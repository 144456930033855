import React, { useState, useEffect } from "react";
import "./card-product-details.scss";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { Container } from "react-bootstrap";
import { getProductByid } from "../../httpClient/index";
import { useDispatch } from "react-redux";
import { addItemWithQuantity } from "../../redux/actions/cartActions";
import { useHistory, useLocation } from "react-router-dom";
import CardDescriptionContact from "../card-product-description&contact/Index";
import CustomSnackbar from "../CustomSnackbar";
import Button from "@mui/material/Button";
import {
  BsCartPlus,
  MdKeyboardArrowRight,
  MdKeyboardArrowLeft,
} from "react-icons/all";
import { BASE_URL } from "../../httpClient/index";
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";
import "swiper/swiper.scss";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import ProductNoIMG from "../../assets/product-pic.jpg";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

let productLinks = [];

function Index() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [currentValue, setCurrentValue] = useState(1);
  const history = useHistory();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let url = window.location.href;

  const query = useQuery();
  const id = query.get("id");
  const [productURL, setProductURL] = useState();
  const [newUrl, setNewUrl] = useState();

  useEffect(() => {
    if (!id && url.includes("/product-details")) {
      let urlReplaced = url.replace("/product-details", "");
      window.location.href = urlReplaced;
    }
  }, [id]);

  useEffect(() => {
    if (url.includes("product-details?id")) {
      let urlSplited = url.replace(`products/product-details?id=${id}`, "");
      setProductURL(urlSplited);
    }
    if (url.includes("product-details?id")) {
      let urlSplited = url.replace(`products/product-details?id=${id}`, "");
      setNewUrl(urlSplited);
    }
    if (url.includes("product-details")) {
      let urlSplited = url.replace(`products/product-details`, "");
      setProductURL(urlSplited);
    }
  }, []);

  const backToProducts = () => {
    if (url.includes("product-details?id")) {
      window.location.href = `${newUrl}products`;
    } else if (url.includes("product-details?id")) {
      window.location.href = `${productURL}privacy-statement`;
    } else if (url.includes("product-details")) {
      window.location.href = `${productURL}products`;
    } else {
      window.location.href = `${url}products`;
    }
  };

  const [product, setProduct] = useState([]);

  useEffect(() => {
    async function fetchData() {
      await getProductByid(id).then((res) => {
        setProduct(res.data);
      });
    }
    fetchData();
  }, [id]);

  const handleAddItem = () => {
    setCurrentValue(currentValue + 1);
  };
  const handleRemoveItem = () => {
    setCurrentValue(currentValue - 1);
  };

  const [alertMessage, setAlertMessage] = useState("");
  const [alertStatus, setAlertStatus] = useState("");
  const [open, setOpen] = useState(false);

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const openSnackbar = () => {
    setOpen(true);
    setAlertStatus("success");
    setAlertMessage("Product added successfully");
  };

  const handleAddItemWithQuantity = () => {
    dispatch(addItemWithQuantity(product, currentValue));
    let localArray = localStorage.getItem("productLink")
      ? JSON.parse(localStorage.getItem("productLink"))
      : [];
    if (localArray) productLinks = [...localArray];
    if (!productLinks.includes(window.location.href)) {
      productLinks.push(window.location.href);
      localStorage.setItem("productLink", JSON.stringify(productLinks));
    }

    setCurrentValue(1);
    openSnackbar();
  };

  const renderPrevButton = ({ isDisabled }) => {
    return (
      <MdKeyboardArrowLeft
        className={isDisabled ? "slider_icons_disabled" : "slider_icons"}
      />
    );
  };

  const renderNextButton = ({ isDisabled }) => {
    return (
      <MdKeyboardArrowRight
        className={isDisabled ? "slider_icons_disabled" : "slider_icons"}
      />
    );
  };

  return (
    <>
      <Helmet>
        <title>{product?.productName}</title>
        <meta name="description" content={`${product?.backgroundInfo || ""}`} />
      </Helmet>
      <Container>
        <div className="selected-product row">
          {/* Left Elements */}
          <div className="col-12 col-md-12 col-lg-8 col-xl-8">
            <a className="continue_shopping" onClick={backToProducts}>
              {t("back_to_products")}
            </a>
            <div className="selected-product-details_left">
              <h2 id="productName">
                {product?.productName}
                {/* / {product?.size ? product?.size : "-"} */}
              </h2>
              <div className="img_padding">
                <AliceCarousel
                  renderPrevButton={renderPrevButton}
                  renderNextButton={renderNextButton}
                  mouseTracking
                  keyboardNavigation
                  autoPlayInterval={3000}
                  animationDuration={800}
                >
                  {product?.images?.length === 0 ? (
                    <img src={ProductNoIMG} style={style.sliderImg} />
                  ) : (
                    product?.images?.map((item) => {
                      const splittedImg = item.url.split(";");
                      return (
                        <>
                          <Gallery withCaption withDownloadButton>
                            <Item
                              key={item.id}
                              original={`${BASE_URL}/${splittedImg?.[0]}`}
                              thumbnail={`${BASE_URL}/${splittedImg?.[0]}`}
                              caption={item.description}
                              width="1024"
                              height="768"
                            >
                              {({ ref, open }) => (
                                <>
                                  <img
                                    ref={ref}
                                    onClick={open}
                                    src={`${BASE_URL}/${splittedImg?.[0]}`}
                                    style={{
                                      width:
                                        product.id === "1151663"
                                          ? "400px"
                                          : "auto",
                                      height: "auto",
                                      display: "block",
                                      margin: "0 auto",
                                      cursor: "pointer",
                                    }}
                                  />
                                  <p style={style.centerTxt}>
                                    {item.description}
                                  </p>
                                </>
                              )}
                            </Item>
                          </Gallery>
                        </>
                      );
                    })
                  )}
                </AliceCarousel>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-4 col-xl-4">
            <div className="selected-product-details_right">
              <div className="selected-product-details_right-content">
                <h5>
                  <span>{t("catalog_number")}: </span>
                  {product?.sku ? product?.sku : "-"}
                </h5>
                <h5>
                  <span>{t("Supplier")}: </span>
                  {product?.supplier?.name ? product?.supplier?.name : "-"}
                </h5>
                <h5>
                  <span>Product Group: </span>
                  {product?.categories?.map((cat) => cat.name)}
                </h5>
                <h5>
                  <span>{t("Size")}:</span>{" "}
                  {product?.size ? product?.size : "-"}
                </h5>
                {/* <h5>
                  <span>Application:</span>{" "}
                  {product?.applicationsAbbreviation
                    ? product?.applicationsAbbreviation
                    : "-"}
                </h5> */}
                {product?.datasheetUrl ? (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${productURL}${product?.datasheetUrl}`}
                  >
                    {t("product_data_sheet")}
                  </a>
                ) : null}

                <div className="Quantity">
                  <h5>{t("Quantity")}</h5>
                  <button
                    className="minus_quantity"
                    onClick={handleRemoveItem}
                    disabled={currentValue === 1}
                  >
                    <RemoveIcon />
                  </button>
                  <input
                    className="quantity"
                    type="text"
                    value={currentValue}
                    onChange={(e) => setCurrentValue(e.target.value)}
                  />
                  {/* <InputArrows /> */}
                  <button
                    className="add_quantity"
                    onClick={() => handleAddItem()}
                  >
                    <AddIcon />
                  </button>
                </div>

                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<BsCartPlus className="add_cart_icon_start" />}
                  onClick={handleAddItemWithQuantity}
                >
                  {t("add_to_cart")}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <CardDescriptionContact product={product} />
      </Container>
      <CustomSnackbar
        alertStatus={alertStatus}
        handleClose={closeSnackbar}
        open={open}
      >
        {alertMessage}
      </CustomSnackbar>
    </>
  );
}

export default Index;

const style = {
  flexed: {
    display: "flex",
    alignItems: "center",
    gap: 30,
  },
  sliderImg: {
    width: "400px",
    height: "auto",
    display: "block",
    margin: "0 auto",
  },
  centerTxt: {
    textAlign: "center",
  },
};
