import React from "react";
import { BASE_URL } from "../../httpClient/index";

const ImageUrl = ({ img, className, onClick, style, alt, ref, id }) => {
  const handleDragStart = (e) => e.preventDefault();
  return (
    <img
      ref={ref}
      src={`${BASE_URL}/${img}`}
      alt={alt}
      onDragStart={handleDragStart}
      className={className}
      onClick={onClick}
      style={{ ...style }}
      id={id || ''}
    />
  );
};

export default ImageUrl;
