import axios from "axios";
import { filterConstans } from "../redux/constants";

// export const BASE_URL = "https://bmgrp.eu";
export const BASE_URL = process.env.REACT_APP_API_KEY;
export const FORM_TOKEN = process.env.REACT_APP_FORM_TOKEN;
const API_KEY = "40c807f8-3005-4c7a-acb5-4c4e536e76a5";

const axiosn = axios.create({
  baseURL: `${BASE_URL}/api/biomedica`,
  headers: {
    "x-api-key": API_KEY,
  },
});

export const getProductsByFilters = (filter, keyword, country, page) => {
  return async (dispatch) => {
    dispatch({ type: filterConstans.GET_PRODUCTS_LOADING });
    await axiosn
      .get(
        `/products?keyword=${keyword}&country=${country}&from=${page}${filter}`
      )
      .then((res) => {
        dispatch({
          type: filterConstans.GET_PRODUCTS_BY_FILTERS_SUCCESS,
          payload: res?.data,
        }).catch(
          dispatch({
            type: filterConstans.GET_PRODUCTS_BY_FILTERS_FAILURE,
            payload: { error: "Something went wrong" },
          })
        );
      });
  };
};

export const getSuppliers = (filter, keyword, country, page, currLng) => {
  return async (dispatch) => {
    await axiosn
      .get(
        `/suppliers?keyword=${keyword}&country=${country}&from=${page}${filter}&locale=${currLng}`
      )
      .then((res) => {
        dispatch({
          type: filterConstans.GET_SUPPLIERS_SUCCESS_NEW,
          payload: res.data,
        });
      });
  };
};

export const getSuppliersWithId = (id, currLng) => {
  return axiosn.get(`/suppliers/${id}?locale=${currLng}`);
};

export const getProductByid = (id) => {
  return axiosn.get(`/products/${id}`);
};

export const getCategorySuggestions = (keyword) => {
  return async (dispatch) => {
    await axiosn
      .get(`/groups/by-keyword?keyword=${keyword}&group=Categories`)
      .then((res) => {
        dispatch({
          type: filterConstans.GET_CATEGORY_SUGGESTIONS_SUCCESS,
          payload: res.data?.categories,
        });
      });
  };
};

export const getContents = (keyword) => {
  return function (dispatch) {
    axios
      .get(`${BASE_URL}/search?q=${keyword}`)
      .then((response) => {
        dispatch({ type: "GET_CONTENTS_SUCCESS", payload: response.data });
      })
      .catch((err) => {
        dispatch({ type: "GET_CONTENTS_FAILURE", payload: err });
      });
  };
};

export const getSupplierSuggestions = (keyword) => {
  return async (dispatch) => {
    await axiosn
      .get(`/groups/by-keyword?keyword=${keyword}&group=Divisions`)
      .then((res) => {
        dispatch({
          type: filterConstans.GET_SUPPLIER_SUGGESTIONS_SUCCESS,
          payload: res.data?.divisions,
        });
      });
  };
};

export const sendForm = (params, place) => {
  const form = axios.create({
    baseURL: `${BASE_URL}`,
    headers: {
      Authorization: FORM_TOKEN,
    },
  });
  return form.post("/api", {
    query: `mutation saveSubmission(
      $firstName: String,
      $lastName: String,
      $street: String,
      $zipCode: String,
      $city: String,
      $country: String,
      $phoneNumber: String,
      $eMail: String,
      $companyName: String,
      $message:String,
      $productQuoteRequest: productsRequestQuote${place}_productsRequested_FormieRepeaterInput
    ) {
      save_productsRequestQuote${place}_Submission(
        firstName: $firstName
        lastName: $lastName
        street: $street
        zipCode: $zipCode
        city: $city
        country: $country
        phoneNumber: $phoneNumber
        eMail: $eMail
        companyName: $companyName
        message: $message
        productsRequested: $productQuoteRequest
      ) {
        title
        firstName
        lastName
        street
        zipCode
        city
        country
        phoneNumber
        eMail
        companyName
        message
        productsRequested {
         rows {
          productName
          productQuantity
          productSize
         }
        }
      }
    }`,
    variables: { ...params },
  });
};
