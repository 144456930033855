import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectCartItems } from "../../redux/selectors/cartSelector";
import "./products-in-cart.scss";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import ProductsIncartItem from "../product-in-cart-item/Index";

const ProductsIncart = () => {
  let url = window.location.href;
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const [newURL, setNewURL] = useState();

  const cartItems = useSelector(selectCartItems);

  const { t } = useTranslation();

  useEffect(() => {
    if (url.includes("cart")) {
      let urlSplited2 = url.replace(`cart`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("cart-overview")) {
      let urlSplited2 = url.replace(`cart-overview`, "");
      setNewURL(urlSplited2);
    }
  }, []);

  const redirectToProducts = () => {
    if (url.includes("cart")) {
      window.location.href = `${newURL}products`;
    } else if (url.includes("cart-overview")) {
      window.location.href = `${newURL}products`;
    } else {
      window.location.href = `${url}products`;
    }
  };

  return (
    <div className="checkout_table">
      <div className="row">
        <div className="col-12">
          <div className="checkout_head">
            <div className="col-12 col-md-6 col-lg-8">
              <div className="header-block">
                <span>{t("Products_in_cart")}</span>
              </div>
            </div>
            <div className="col-4 d-none col-md-6 col-lg-4 d-md-block">
              <div className="header-block1">
                <span>{t("Quantity")}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {cartItems.length ? (
        cartItems.map((cartItem) => (
          <ProductsIncartItem key={cartItem.id} cartItem={cartItem} />
        ))
      ) : (
        <div style={style.flexed}>
          <span className="empty-message">{t("empty_cart")}</span>
          <button className="find_products" onClick={redirectToProducts}>
            Find Products
          </button>
        </div>
      )}
    </div>
  );
};

export default ProductsIncart;

const style = {
  flexed: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: 20,
    marginTop: 10,
    borderBottom: "1px solid #dadada",
    paddingBottom: 30,
  },
};
