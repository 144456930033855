import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./utils/index.css";
import reportWebVitals from "./reportWebVitals";
import { Switch, Router } from "react-router-dom";
import { store, persistor } from "./redux/store";
import Checkout from "./components/checkout/Index";
import SearchComponent from "./searchComponent/SearchComponent";
import SearchHomeComponent from "./searchComponentHome/SearchHomeComponent";
import { PersistGate } from "redux-persist/integration/react";
import history from "./history";
import CartDropdown from "./components/cart-dropdown/Index";
import CartDropdownDe from "./components/cart-dropdown/de/Index";
import CartDropdownEn from "./components/cart-dropdown/en/Index";
import ProductDetails from "./components/card-prodcut-details/Index";
import SupplierPage from "./pages/SupplierPage";
import ShopIcon from "./ShopIconComponent/ShopIcon";
import ShopIconMobile from "./ShopIconComponentMobile/ShopIconMobile";
import CartPageOnly from "./pages/CartPageOnly";
import ProductPage from "./pages/ProductPage";
import SuppliersPage from "./pages/SuppliersPage";
import Home from "./pages/Home";
import Services from "./pages/Services";
import ProductSearchFilter from "./productSearch/ProductSearchFilter";
import {
  HUSearch,
  BGSearch,
  BIHSearch,
  CHSearch,
  CroatiaSearch,
  CzechSearch,
  MKSearch,
  MOSearch,
  PLSearch,
  ROSearch,
  SKSearch,
  SLSearch,
  SRSearch,
  XSSearch,
  ATSearch,
  ALSearch,
} from "./SearchCountryRoutes/index";
import {
  HUHeaderSearch,
  BGHeaderSearch,
  BIHHeaderSearch,
  CHHeaderSearch,
  CroatiaHeaderSearch,
  CzechHeaderSearch,
  MKHeaderSearch,
  MOHeaderSearch,
  PLHeaderSearch,
  ROHeaderSearch,
  SKHeaderSearch,
  SLHeaderSearch,
  SRHeaderSearch,
  XSHeaderSearch,
  ATHeaderSearch,
  ALHeaderSearch,
} from "./SearchHeaderRoutes/index";

const huSearch = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <PersistGate persistor={persistor}>
            <HUSearch />
          </PersistGate>
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);
const bgSearch = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <PersistGate persistor={persistor}>
            <BGSearch />
          </PersistGate>
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);
const bihSearch = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <PersistGate persistor={persistor}>
            <BIHSearch />
          </PersistGate>
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);

const chSearch = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <PersistGate persistor={persistor}>
            <CHSearch />
          </PersistGate>
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);

const croatiaSearch = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <PersistGate persistor={persistor}>
            <CroatiaSearch />
          </PersistGate>
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);
const mkSearch = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <PersistGate persistor={persistor}>
            <MKSearch />
          </PersistGate>
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);

const plSearch = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <PersistGate persistor={persistor}>
            <PLSearch />
          </PersistGate>
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);

const moSearch = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <PersistGate persistor={persistor}>
            <MOSearch />
          </PersistGate>
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);

const czSearch = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <PersistGate persistor={persistor}>
            <CzechSearch />
          </PersistGate>
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);

const roSearch = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <PersistGate persistor={persistor}>
            <ROSearch />
          </PersistGate>
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);
const skSearch = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <PersistGate persistor={persistor}>
            <SKSearch />
          </PersistGate>
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);

const slSearch = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <PersistGate persistor={persistor}>
            <SLSearch />
          </PersistGate>
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);

const srSearch = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <PersistGate persistor={persistor}>
            <SRSearch />
          </PersistGate>
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);

const xsSearch = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <PersistGate persistor={persistor}>
            <XSSearch />
          </PersistGate>
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);

const alSearch = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <PersistGate persistor={persistor}>
            <ALSearch />
          </PersistGate>
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);

const atSearch = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <PersistGate persistor={persistor}>
            <ATSearch />
          </PersistGate>
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);

// SEARCH FILTER HEADER COUNTRY ROUTES

const huHeaderSearch = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <PersistGate persistor={persistor}>
            <HUHeaderSearch />
          </PersistGate>
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);
const bgHeaderSearch = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <PersistGate persistor={persistor}>
            <BGHeaderSearch />
          </PersistGate>
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);
const bihHeaderSearch = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <PersistGate persistor={persistor}>
            <BIHHeaderSearch />
          </PersistGate>
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);

const chHeaderSearch = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <PersistGate persistor={persistor}>
            <CHHeaderSearch />
          </PersistGate>
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);

const croatiaHeaderSearch = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <PersistGate persistor={persistor}>
            <CroatiaHeaderSearch />
          </PersistGate>
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);
const mkHeaderSearch = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <PersistGate persistor={persistor}>
            <MKHeaderSearch />
          </PersistGate>
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);

const plHeaderSearch = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <PersistGate persistor={persistor}>
            <PLHeaderSearch />
          </PersistGate>
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);

const moHeaderSearch = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <PersistGate persistor={persistor}>
            <MOHeaderSearch />
          </PersistGate>
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);

const czHeaderSearch = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <PersistGate persistor={persistor}>
            <CzechHeaderSearch />
          </PersistGate>
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);

const roHeaderSearch = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <PersistGate persistor={persistor}>
            <ROHeaderSearch />
          </PersistGate>
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);
const skHeaderSearch = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <PersistGate persistor={persistor}>
            <SKHeaderSearch />
          </PersistGate>
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);

const slHeaderSearch = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <PersistGate persistor={persistor}>
            <SLHeaderSearch />
          </PersistGate>
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);

const srHeaderSearch = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <PersistGate persistor={persistor}>
            <SRHeaderSearch />
          </PersistGate>
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);

const xsHeaderSearch = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <PersistGate persistor={persistor}>
            <XSHeaderSearch />
          </PersistGate>
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);

const alHeaderSearch = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <PersistGate persistor={persistor}>
            <ALHeaderSearch />
          </PersistGate>
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);

const atHeaderSearch = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <PersistGate persistor={persistor}>
            <ATHeaderSearch />
          </PersistGate>
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);

const products = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <PersistGate persistor={persistor}>
            <ProductPage />
          </PersistGate>
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);
const productsDetailsPage = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <PersistGate persistor={persistor}>
            <ProductDetails />
          </PersistGate>
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);

const supplierDetailsPage = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <SupplierPage />
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);

const suppliers = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <SuppliersPage />
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);

const search = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <SearchComponent />
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);

const productSearch = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <ProductSearchFilter />
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);

const searchHome = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <SearchHomeComponent />
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);

const checkout = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <Checkout />
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);

const checkoutDropdown = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <CartDropdown />
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);

const cartDropdownDe = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <CartDropdownDe />
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);

const cartDropdownEn = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <CartDropdownEn />
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);

const shopIcon = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <ShopIcon />
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);

const shopIconComponentMobile = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <ShopIconMobile />
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);

const CartOnly = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <CartPageOnly />
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);
const HomePage = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <Home />
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);
const ServicePage = (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <React.StrictMode>
          <Services />
        </React.StrictMode>
      </Switch>
    </Router>
  </Provider>
);

if (document.getElementById("servicesRoot") !== null) {
  ReactDOM.render(ServicePage, document.getElementById("servicesRoot"));
}
if (document.getElementById("homeRoot") !== null) {
  ReactDOM.render(HomePage, document.getElementById("homeRoot"));
}

if (document.getElementById("supplierDetailsRoot") !== null) {
  ReactDOM.render(
    supplierDetailsPage,
    document.getElementById("supplierDetailsRoot")
  );
}

if (document.getElementById("productsDetailsRoot") !== null) {
  ReactDOM.render(
    productsDetailsPage,
    document.getElementById("productsDetailsRoot")
  );
}

if (document.getElementById("searchRoot") !== null) {
  ReactDOM.render(search, document.getElementById("searchRoot"));
}

// if (document.getElementById("searchHeaderRoot") !== null) {
//   ReactDOM.render(searchHeader, document.getElementById("searchHeaderRoot"));
// }

if (document.getElementById("productSearchRoot") !== null) {
  ReactDOM.render(productSearch, document.getElementById("productSearchRoot"));
}

if (document.getElementById("searchHomeRoot") !== null) {
  ReactDOM.render(searchHome, document.getElementById("searchHomeRoot"));
}

if (document.getElementById("productsRoot") !== null) {
  ReactDOM.render(products, document.getElementById("productsRoot"));
}

if (document.getElementById("suppliersRoot") !== null) {
  ReactDOM.render(suppliers, document.getElementById("suppliersRoot"));
}

if (document.getElementById("checkoutRoot") !== null) {
  ReactDOM.render(checkout, document.getElementById("checkoutRoot"));
}
if (document.getElementById("checkoutDropdownRoot") !== null) {
  ReactDOM.render(
    checkoutDropdown,
    document.getElementById("checkoutDropdownRoot")
  );
}

if (document.getElementById("cartDropdownDe") !== null) {
  ReactDOM.render(cartDropdownDe, document.getElementById("cartDropdownDe"));
}
if (document.getElementById("cartDropdownEn") !== null) {
  ReactDOM.render(cartDropdownEn, document.getElementById("cartDropdownEn"));
}

if (document.getElementById("shopIconRoot") !== null) {
  ReactDOM.render(shopIcon, document.getElementById("shopIconRoot"));
}
if (document.getElementById("shopIconMobileRoot") !== null) {
  ReactDOM.render(
    shopIconComponentMobile,
    document.getElementById("shopIconMobileRoot")
  );
}
if (document.getElementById("cartOverviewRoot") !== null) {
  ReactDOM.render(CartOnly, document.getElementById("cartOverviewRoot"));
}

if (document.getElementById("xsSearchRoot") !== null) {
  ReactDOM.render(xsSearch, document.getElementById("xsSearchRoot"));
}

if (document.getElementById("srSearchRoot") !== null) {
  ReactDOM.render(srSearch, document.getElementById("srSearchRoot"));
}

if (document.getElementById("slSearchRoot") !== null) {
  ReactDOM.render(slSearch, document.getElementById("slSearchRoot"));
}

if (document.getElementById("skSearchRoot") !== null) {
  ReactDOM.render(skSearch, document.getElementById("skSearchRoot"));
}

if (document.getElementById("roSearchRoot") !== null) {
  ReactDOM.render(roSearch, document.getElementById("roSearchRoot"));
}

if (document.getElementById("czSearchRoot") !== null) {
  ReactDOM.render(czSearch, document.getElementById("czSearchRoot"));
}

if (document.getElementById("moSearchRoot") !== null) {
  ReactDOM.render(moSearch, document.getElementById("moSearchRoot"));
}

if (document.getElementById("plSearchRoot") !== null) {
  ReactDOM.render(plSearch, document.getElementById("plSearchRoot"));
}

if (document.getElementById("mkSearchRoot") !== null) {
  ReactDOM.render(mkSearch, document.getElementById("mkSearchRoot"));
}

if (document.getElementById("croatiaSearchRoot") !== null) {
  ReactDOM.render(croatiaSearch, document.getElementById("croatiaSearchRoot"));
}

if (document.getElementById("chSearchRoot") !== null) {
  ReactDOM.render(chSearch, document.getElementById("chSearchRoot"));
}

if (document.getElementById("bihSearchRoot") !== null) {
  ReactDOM.render(bihSearch, document.getElementById("bihSearchRoot"));
}

if (document.getElementById("bgSearchRoot") !== null) {
  ReactDOM.render(bgSearch, document.getElementById("bgSearchRoot"));
}

if (document.getElementById("huSearchRoot") !== null) {
  ReactDOM.render(huSearch, document.getElementById("huSearchRoot"));
}

if (document.getElementById("atSearchRoot") !== null) {
  ReactDOM.render(atSearch, document.getElementById("atSearchRoot"));
}
if (document.getElementById("alSearchRoot") !== null) {
  ReactDOM.render(alSearch, document.getElementById("alSearchRoot"));
}

if (document.getElementById("xsHeaderSearchRoot") !== null) {
  ReactDOM.render(
    xsHeaderSearch,
    document.getElementById("xsHeaderSearchRoot")
  );
}

if (document.getElementById("srHeaderSearchRoot") !== null) {
  ReactDOM.render(
    srHeaderSearch,
    document.getElementById("srHeaderSearchRoot")
  );
}

if (document.getElementById("slHeaderSearchRoot") !== null) {
  ReactDOM.render(
    slHeaderSearch,
    document.getElementById("slHeaderSearchRoot")
  );
}

if (document.getElementById("skHeaderSearchRoot") !== null) {
  ReactDOM.render(
    skHeaderSearch,
    document.getElementById("skHeaderSearchRoot")
  );
}

if (document.getElementById("roHeaderSearchRoot") !== null) {
  ReactDOM.render(
    roHeaderSearch,
    document.getElementById("roHeaderSearchRoot")
  );
}

if (document.getElementById("czHeaderSearchRoot") !== null) {
  ReactDOM.render(
    czHeaderSearch,
    document.getElementById("czHeaderSearchRoot")
  );
}

if (document.getElementById("moHeaderSearchRoot") !== null) {
  ReactDOM.render(
    moHeaderSearch,
    document.getElementById("moHeaderSearchRoot")
  );
}

if (document.getElementById("plHeaderSearchRoot") !== null) {
  ReactDOM.render(
    plHeaderSearch,
    document.getElementById("plHeaderSearchRoot")
  );
}

if (document.getElementById("mkHeaderSearchRoot") !== null) {
  ReactDOM.render(
    mkHeaderSearch,
    document.getElementById("mkHeaderSearchRoot")
  );
}

if (document.getElementById("croatiaHeaderSearchRoot") !== null) {
  ReactDOM.render(
    croatiaHeaderSearch,
    document.getElementById("croatiaHeaderSearchRoot")
  );
}

if (document.getElementById("chHeaderSearchRoot") !== null) {
  ReactDOM.render(
    chHeaderSearch,
    document.getElementById("chHeaderSearchRoot")
  );
}

if (document.getElementById("bihHeaderSearchRoot") !== null) {
  ReactDOM.render(
    bihHeaderSearch,
    document.getElementById("bihHeaderSearchRoot")
  );
}

if (document.getElementById("bgHeaderSearchRoot") !== null) {
  ReactDOM.render(
    bgHeaderSearch,
    document.getElementById("bgHeaderSearchRoot")
  );
}

if (document.getElementById("huHeaderSearchRoot") !== null) {
  ReactDOM.render(
    huHeaderSearch,
    document.getElementById("huHeaderSearchRoot")
  );
}

if (document.getElementById("atHeaderSearchRoot") !== null) {
  ReactDOM.render(
    atHeaderSearch,
    document.getElementById("atHeaderSearchRoot")
  );
}
if (document.getElementById("alHeaderSearchRoot") !== null) {
  ReactDOM.render(
    alHeaderSearch,
    document.getElementById("alHeaderSearchRoot")
  );
}

reportWebVitals();
