import React from "react";
import SearchHomeFilter from "./SearchFilter/SearchHomeFilter";
function SearchHomeComponent() {
  return (
    <div>
      <SearchHomeFilter />
    </div>
  );
}

export default SearchHomeComponent;
