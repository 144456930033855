import SearchIcon from "@material-ui/icons/Search";
import React, { useEffect, useRef, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductsByFilters,
  getCategorySuggestions,
  getContents,
  getSuppliers,
} from "../../httpClient";
import { searchKeyword } from "../../redux/actions/searchAction";
import "./MKSearch.scss";
import { IoIosArrowDown } from "react-icons/all";
import { useTranslation } from "react-i18next";
import { getCurrentLanguage } from "../../components/GetCurrentLanguage";

function MKSearch() {
  const { t } = useTranslation();
  const [keyword, setKeyword] = useState("");
  const dispatch = useDispatch();
  const page = useSelector((state) => state.page.page);
  const productSuggestions = useSelector((state) => state.filter.products);
  const categorySuggestionsNew = useSelector(
    (state) => state.suggestions.catSuggestions
  );
  const suppliersState = useSelector((state) => state.suppliers.suppliersnew);
  const [isShow, setIsShow] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const ref = useRef();
  const searchDropdown = useRef();

  const toggleDropdown = () => setOpen(!isOpen);
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (isShow && ref.current && !ref.current.contains(e.target)) {
        setIsShow(false);
      }
      if (
        isOpen &&
        searchDropdown.current &&
        !searchDropdown.current.contains(e.target)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isShow, isOpen]);

  let url = window.location.href;
  const [chkbox, setChkbox] = useState(1);
  const handleChange = (theVal) => {
    setChkbox(theVal);
    setOpen(!isOpen);
  };
  const handleProductSuggestionClicked = (id) => {
    window.location.href = `${url}/products/product-details?id=${id}`;
  };

  const handleCategorySuggestionClicked = (id) => {
    window.location.href = `${url}/products?category=${id}`;
  };

  const handleSupplierSuggestionClicked = (id) => {
    window.location.href = `${url}/suppliers/supplier-detail?sid=${id}`;
  };

  const handleProductsSearch = () => {
    window.location.href = `${url}/products?search=${keyword}`;
  };

  const handleContentsSearch = () => {
    dispatch(getContents(keyword));
    window.location.href = `${url}/search?q=${keyword}`;
  };
  const handleDebounceChange = (e) => {
    if (chkbox === 0) {
      setKeyword(e.target.value);
    }
    const currLng = getCurrentLanguage();
    if (chkbox === 1) {
      setKeyword(e.target.value);
      dispatch(getProductsByFilters("", e.target.value, "MK", (page - 1) * 10));
      dispatch(getCategorySuggestions(e.target.value));
      dispatch(
        getSuppliers("", e.target.value, "MK", (page - 1) * 10, currLng)
      );
      setIsShow(true);
    }
  };

  const handleSearch = () => {
    if (keyword.length >= 3) {
      dispatch(searchKeyword(keyword));
      if (chkbox === 0) {
        handleContentsSearch();
      }
      if (chkbox === 1 && keyword.length >= 3) {
        handleProductsSearch();
      }
    }
  };
  const renderAutocomplete = () => {
    if (isShow && keyword) {
      return (
        <div ref={ref}>
          <ul className="autocomplete">
            {productSuggestions.length === 0 &&
            categorySuggestionsNew.length === 0 &&
            suppliersState.length === 0 ? (
              <p id="no_search_result">
                Жалиме, но ништо не одговараше на вашите термини за пребарување.
                Обидете се повторно со неколку различни клучни зборови.
              </p>
            ) : null}
            {productSuggestions.length > 0 ? (
              <>
                <p className="suggestion_title">Производи</p>
                {productSuggestions?.slice(0, 5)?.map((item, index) => {
                  return (
                    <li
                      key={item.documentId}
                      onClick={() =>
                        handleProductSuggestionClicked(item.documentId)
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 15,
                        }}
                      >
                        <div>
                          {item?.productName}{" "}
                          <span className="search_size">/ {item?.size}</span>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </>
            ) : null}

            {categorySuggestionsNew.length > 0 ? (
              <>
                <p className="suggestion_title">Product Group</p>
                {categorySuggestionsNew?.slice(0, 5)?.map((item) => (
                  <li
                    key={item.id}
                    onClick={() => handleCategorySuggestionClicked(item.id)}
                  >
                    {item.name}
                  </li>
                ))}
              </>
            ) : null}

            {suppliersState.length > 0 ? (
              <>
                <p className="suggestion_title">Добавувачи</p>
                {suppliersState?.slice(0, 5)?.map((item) => {
                  return (
                    <li
                      key={item.id}
                      onClick={() => handleSupplierSuggestionClicked(item.o_id)}
                    >
                      {item.SupplierName}
                    </li>
                  );
                })}
              </>
            ) : null}
          </ul>
        </div>
      );
    }
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSearch();
    }
  };
  return (
    <div className="search-container-banner">
      <div className="inputsearch">
        <div className="search-button">
          <div className="dropdown" ref={searchDropdown}>
            <div className="dropdown-header" onClick={toggleDropdown}>
              {chkbox === 1 ? "Производи" : "Cодржина"}
              <IoIosArrowDown />
            </div>
            <div className={`dropdown-body ${isOpen && "open"}`}>
              <div className="dropdown-item-container">
                <div className="dropdown-item" onClick={() => handleChange(0)}>
                  <input id="radio1" type="radio" checked={chkbox === 0} />{" "}
                  Cодржина
                </div>
                <div className="dropdown-item" onClick={() => handleChange(1)}>
                  <input id="radio2" type="radio" checked={chkbox === 1} />{" "}
                  Производи
                </div>
              </div>
            </div>
          </div>

          <DebounceInput
            type="text"
            name="text"
            placeholder={
              chkbox === 0
                ? "Пребарувај за секаква содржина тука..."
                : "Пребарување за секакви производи тука..."
            }
            value={keyword}
            onChange={(e) => handleDebounceChange(e)}
            onKeyDown={onKeyDown}
            minLength={3}
          />
          {chkbox === 0 && null}
          {chkbox === 1 && renderAutocomplete()}
          <button className="search-icon" onClick={handleSearch}>
            <SearchIcon color="secondary" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default MKSearch;
