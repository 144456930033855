import React, { useState, useEffect, useCallback } from "react";
import "./supplier.scss";
import { getSuppliersWithId } from "../../httpClient/index";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ImageUrl from "../customImage/ImageUrl";
import CustomAccordian from "../accordian/CustomAccordian";
import { BsDot } from "react-icons/all";
import { useTranslation } from "react-i18next";
import ProductNoIMG from "../../assets/product-pic.jpg";
import { getCurrentLanguage } from "../GetCurrentLanguage";
import { BASE_URL } from "../../httpClient/index";
import ReactHtmlParser from "react-html-parser";

const Supplier = () => {
  const [supplier, setSupplier] = useState();
  const suppliersState = useSelector((state) => state.suppliers.suppliersnew);
  const { t } = useTranslation();

  let urlOrigin = window.location.origin;
  function handleSupplierClicked(id) {
    window.location.href = `${urlOrigin}/suppliers/supplier-detail?sid=${id}`;
  }

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let url = window.location.href;
  const query = useQuery();
  const supplierId = query.get("sid");
  const [suppUrl, newSuppUrl] = useState();
  const [newUrl, newNewUrl] = useState();

  useEffect(() => {
    const currLng = getCurrentLanguage();
    async function fetchData() {
      await getSuppliersWithId(supplierId, currLng).then((res) => {
        setSupplier(res.data);
      });
    }
    fetchData();
  }, [supplierId]);

  useEffect(() => {
    if (!supplierId && url.includes("/supplier-detail")) {
      let urlReplaced = url.replace("/supplier-detail", "");
      window.location.href = urlReplaced;
    }
  }, [supplierId]);

  const BrochureJson = eval(supplier?.brochureFiles);

  // const brochureFilesPdf = supplier?.brochureFiles?.split(";");
  // const brochureFilesTitle = brochureFilesPdf
  //   ?.map((e) => e.split("/"))
  //   ?.map((title) => title?.[3]?.split(".")?.[0]);

  // console.log("supplierDetails", brochureFilesPdf);
  // console.log("brochureFilesTitle", brochureFilesTitle);

  // let brochureFilesObj = [];
  // brochureFilesPdf?.forEach((pdf, pdfIdx) => {
  //   brochureFilesTitle?.forEach((title, titleIdx) => {
  //     if (pdfIdx == titleIdx) {
  //       brochureFilesObj.push({
  //         pdf: pdf.replaceAll(" ", "%20"),
  //         title: title,
  //       });
  //     }
  //   });
  // });
  // console.log("brochureFilesObj", brochureFilesObj);

  useEffect(() => {
    if (url.includes("supplier-detail?sid")) {
      let urlSplited2 = url.replace(
        `suppliers/supplier-detail?sid=${supplierId}`,
        ""
      );
      newNewUrl(urlSplited2);
    }
    if (url.includes("supplier-detail")) {
      let urlSplited = url.replace(`suppliers/supplier-detail`, "");
      newSuppUrl(urlSplited);
    }
  }, []);

  const goBackSuppliers = () => {
    if (url.includes("supplier-detail?sid")) {
      window.location.href = `${newUrl}suppliers`;
      let urlSplited = newUrl.split("supplier-detail?id", 1);
      window.location.href = `${urlSplited}suppliers`;
    } else if (url.includes("supplier-detail")) {
      window.location.href = `${suppUrl}suppliers`;
    } else {
      window.location.href = `${url}suppliers`;
    }
  };

  const goToSuppWithProd = () => {
    if (url.includes("suppliers/supplier-detail?sid")) {
      window.location.href = `${newUrl}products?sid=${supplierId}`;
    } else {
      window.location.href = `${url}products?sid=${supplierId}`;
    }
  };

  const goToOtherSuppliers = (id) => {
    if (url.includes("supplier-detail?sid")) {
      window.location.href = `${suppUrl}suppliers/supplier-detail?sid=${id}`;
    } else {
      window.location.href = `${url}suppliers/supplier-detail?sid=${id}`;
    }
  };

  const SupplierKey = ({ state }) => {
    return (
      <ul style={style.list}>
        {state?.map((item) => (
          <div className="flexed">
            <BsDot size={26} />
            <li>{item}</li>
          </div>
        ))}
      </ul>
    );
  };

  console.log("supplier?.suppliersKeyProducts", supplier?.suppliersKeyProducts);

  const data = [
    {
      id: 1,
      title: "Key Areas",
      show:
        supplier?.suppliersKeyAreas?.length === 0 ||
        supplier?.suppliersKeyAreas === undefined
          ? "hidden"
          : "",
      component: <SupplierKey state={supplier?.suppliersKeyAreas} />,
    },
    {
      id: 2,
      title: "Key Products",
      show:
        supplier?.suppliersKeyProducts?.length === 0 ||
        supplier?.suppliersKeyProducts === undefined
          ? "hidden"
          : "",
      component: <SupplierKey state={supplier?.suppliersKeyProducts} />,
    },
    // {
    //   id: 3,
    //   title: "Main Areas",
    //   show:
    //     supplier?.suppliersMainAreas?.name
    //       ? ""
    //       : "hidden",

    //   component: <SupplierKey state={supplier?.suppliersMainAreas} />,
    // },
    // {
    //   id: 4,
    //   title: "Documents",
    //   show: "",
    //   component: <p>Documents</p>,
    // },
  ];

  // const theObj = { __html: supplier?.description };
  //  <p style={style.marginTxt} aboutdangerouslySetInnerHTML={theObj} />

  return (
    <div className="container">
      <div className="row rowSupplier">
        <div className="offset-2 col-8 offset-md-2 col-md-8 offset-lg-3 col-lg-6 head">
          {supplier?.imgURL ? (
            <ImageUrl
              img={supplier?.imgURL}
              alt="No Image"
              className="headImg"
            />
          ) : (
            <img src={ProductNoIMG} alt="" className="headImg" />
          )}
        </div>
        <div className="col-12 offset-md-1 col-md-10 offset-lg-2 col-lg-8 text_supplier">
          <h1 className="title">{supplier?.SupplierName}</h1>
          {/* <div>{ReactHtmlParser(supplier?.description)}</div> */}
          <p style={style.marginTxt}>
            {ReactHtmlParser(supplier?.description)}
          </p>
        </div>
        <div className="col-12 main_features" style={{ margin: "0 auto" }}>
          <CustomAccordian data={data} />
          {/* Brochure Downloads */}
          {supplier?.brochureFiles !== "[]"
            ? supplier?.brochureFiles && (
                <>
                  <h2>Downloads</h2>
                  <div className="downloads_content">
                    {BrochureJson?.map((brochure) => {
                      return (
                        <>
                          <div className="division_pdf">
                            <a
                              href={`${BASE_URL}${brochure.file}`}
                              className="pdf-image"
                              // download
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={`${BASE_URL}${brochure.thumbnail}`}
                                alt=""
                              />
                            </a>
                            <a
                              href={`${BASE_URL}${brochure.file}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {brochure.title}
                            </a>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </>
              )
            : null}
        </div>
        <div className="col-12 button_suppliers" style={style.marginTxt}>
          <a className="back_button" onClick={goBackSuppliers}>
            {t("Back_to_Suppliers")}
          </a>
          <a
            className="back_button"
            onClick={goToSuppWithProd}
            style={{
              display: supplier?.hasProducts === true ? "block" : "none",
            }}
          >
            {t("Supplier_Products")}
          </a>
        </div>
        {/* <h4>Other Suppliers</h4>
        <hr />
        <div className="suppliersGroup row">
          {suppliersState.map((supp) => {
            return (
              <div
                className="supplier_content col-6 col-md-4 col-lg-2"
                onClick={() => goToOtherSuppliers(supp?.o_id)}
              >
                <div className="square">
                  <ImageUrl img={supp?.imgURL} alt="No Image" />
                </div>
              </div>
            );
          })}
        </div> */}
      </div>
    </div>
  );
};

export default Supplier;

const style = {
  flexed: {
    display: "flex",
    alignItems: "center",
    gap: 20,
    flexBasis: "33.3%",
    width: "500px",
  },
  list: {
    fontFamily: "Axiforma",
    fontWeight: "300",
    fontStyle: "normal",
    listStyle: "none",
    display: "flex",
    // alignItems: "center",
    flexWrap: "wrap",
    // justifyContent: "center",
    // gap: 15,
  },
  marginTxt: {
    marginBottom: 70,
  },
};
