import React, { useState, useEffect } from "react";
import ProductsIncart from "../components/products-in-cart/Index";
import i18next from "../i18n";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { clearCart } from "../redux/actions/cartActions";

function CartPageOnly() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let url = window.location.href;
  const [URL, newURL] = useState();

  useEffect(() => {
    if (url.includes("cart-overview")) {
      let urlSplited = url.replace(`cart-overview`, "");
      newURL(urlSplited);
    }
  }, [url]);

  const goTO = () => {
    if (url.includes("cart-overview")) {
      window.location.href = `${URL}cart`;
    } else {
      window.location.href = `${URL}cart`;
    }
  };

  const countrySelected = window.location.pathname.split("/");
  const countrySplitted = countrySelected[1];
  const countryLanguage = countrySelected[2];

  localStorage.setItem("country", countrySplitted);
  const country = localStorage.getItem("country");

  useEffect(() => {
    if (country !== countrySplitted) {
      dispatch(clearCart());
      // localStorage.clear();
    }
  }, [country]);

  useEffect(() => {
    if (countrySplitted === "al" && countryLanguage === "en") {
      i18next.changeLanguage("en");
    } else if (countrySplitted === "al") {
      i18next.changeLanguage("en");
    }

    if (countrySplitted === "xs" && countryLanguage === "en") {
      i18next.changeLanguage("en");
    } else if (countrySplitted === "xs") {
      i18next.changeLanguage("en");
    }

    if (countrySplitted === "bih" && countryLanguage === "en") {
      i18next.changeLanguage("en");
    } else if (countrySplitted === "bih") {
      i18next.changeLanguage("bih");
    }
    if (countrySplitted === "bg" && countryLanguage === "en") {
      i18next.changeLanguage("en");
    } else if (countrySplitted === "bg") {
      i18next.changeLanguage("bg");
    }
    if (countrySplitted === "hr" && countryLanguage === "en") {
      i18next.changeLanguage("en");
    } else if (countrySplitted === "hr") {
      i18next.changeLanguage("hr");
    }
    if (countrySplitted === "cz" && countryLanguage === "en") {
      i18next.changeLanguage("en");
    } else if (countrySplitted === "cz") {
      i18next.changeLanguage("cz");
    }

    if (countrySplitted === "hu" && countryLanguage === "en") {
      i18next.changeLanguage("en");
    } else if (countrySplitted === "hu") {
      i18next.changeLanguage("hu");
    }

    if (countrySplitted === "mk" && countryLanguage === "en") {
      i18next.changeLanguage("en");
    } else if (countrySplitted === "mk") {
      i18next.changeLanguage("mk");
    }

    if (countrySplitted === "me" && countryLanguage === "en") {
      i18next.changeLanguage("en");
    } else if (countrySplitted === "me") {
      i18next.changeLanguage("en");
    }
    if (countrySplitted === "pl" && countryLanguage === "en") {
      i18next.changeLanguage("en");
    } else if (countrySplitted === "pl") {
      i18next.changeLanguage("pl");
    }
    if (countrySplitted === "ro" && countryLanguage === "en") {
      i18next.changeLanguage("en");
    } else if (countrySplitted === "ro") {
      i18next.changeLanguage("ro");
    }
    if (countrySplitted === "rs" && countryLanguage === "en") {
      i18next.changeLanguage("en");
    } else if (countrySplitted === "rs") {
      i18next.changeLanguage("rs");
    }
    if (countrySplitted === "sl" && countryLanguage === "en") {
      i18next.changeLanguage("en");
    } else if (countrySplitted === "sl") {
      i18next.changeLanguage("sl");
    }
    if (countrySplitted === "sk" && countryLanguage === "en") {
      i18next.changeLanguage("en");
    } else if (countrySplitted === "sk") {
      i18next.changeLanguage("sl");
    }
    if (countrySplitted === "si" && countryLanguage === "en") {
      i18next.changeLanguage("en");
    } else if (countrySplitted === "si") {
      i18next.changeLanguage("si");
    }
    if (countrySplitted === "ch" && countryLanguage === "en") {
      i18next.changeLanguage("en");
    } else if (countrySplitted === "ch") {
      i18next.changeLanguage("de");
    }

    if (countrySplitted === "en") {
      i18next.changeLanguage("en");
    }

    if (countrySelected.length === 2) {
      i18next.changeLanguage("de");
    }
  }, []);

  return (
    <div style={{ display: "block", position: "relative" }}>
      <ProductsIncart />
      <a className="redirect_to_quote" onClick={goTO}>
        {t("Request_a_Quote")}
      </a>
    </div>
  );
}
export default CartPageOnly;
