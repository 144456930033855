export const addItemToCart = (cartItems, cartItemToAdd) => {
  const existingCartItem = cartItems.find(
    (cartItem) => cartItem.documentId === cartItemToAdd.documentId
  );

  if (existingCartItem) {
    return cartItems.map((cartItem) =>
      cartItem.documentId === cartItemToAdd.documentId
        ? { ...cartItem, quantity: cartItem.quantity + 1 }
        : cartItem
    );
  }

  return [...cartItems, { ...cartItemToAdd, quantity: 1 }];
};

export const addItemToCartFromList = (cartItems, cartItemToAdd) => {
  const existingCartItem = cartItems.find(
    (cartItem) => cartItem?.node?.id === cartItemToAdd?.node?.id
  );

  if (existingCartItem) {
    return cartItems.map((cartItem) =>
      cartItem?.node?.id === cartItemToAdd?.node?.id
        ? { ...cartItem, quantity: cartItem.quantity + 1 }
        : cartItem
    );
  }

  return [...cartItems, { ...cartItemToAdd, quantity: 1 }];
};

export const removeItemFromCart = (cartItems, cartItemToRemove) => {
  const existingCartItem = cartItems.find((cartItem) => [
    cartItem?.documentId === cartItemToRemove.documentId,
  ]);

  if (existingCartItem?.quantity === 1) {
    return cartItems.filter(
      (cartItem) => cartItem?.documentId !== cartItemToRemove.documentId
    );
  }

  return cartItems.map((cartItem) =>
    cartItem?.documentId === cartItemToRemove.documentId
      ? { ...cartItem, quantity: cartItem.quantity - 1 }
      : cartItem
  );
};

export const addWithQuantity = (cartItems, cartItemToAdd, quantityToAdd) => {
  const existingCartItem = cartItems.find(
    (cartItem) => cartItem.documentId === cartItemToAdd.documentId
  );

  if (existingCartItem) {
    return cartItems.map((cartItem) =>
      cartItem.documentId === cartItemToAdd.documentId
        ? { ...cartItem, quantity: cartItem.quantity + quantityToAdd }
        : cartItem
    );
  }

  return [...cartItems, { ...cartItemToAdd, quantity: quantityToAdd }];
};
