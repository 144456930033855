import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { clearCart } from "../redux/actions/cartActions";

const Services = () => {
  const dispatch = useDispatch();
  const countrySelected = window.location.pathname.split("/");
  const countrySplitted = countrySelected[1];

  const country = localStorage.getItem("country");

  if (country !== countrySplitted) {
    dispatch(clearCart());
    // localStorage.clear();
  }
  localStorage.setItem("country", countrySplitted);
  return <div></div>;
};

export default Services;
