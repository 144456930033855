import { filterConstans } from "../constants";

const initialState = {
  page: 1,
  totalCount: 10,
};

const pageReducer = (state = initialState, action) => {
  switch (action.type) {
    case filterConstans.ADD_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case filterConstans.ADD_TOTAL_COUNT:
      return {
        ...state,
        totalCount: action.payload,
      };
    default:
      return state;
  }
};

export default pageReducer;
