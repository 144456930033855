import React, { useEffect, useState } from "react";
import arlindBio from "../../assets/arlindbio.png";
import "./product-in-cart-item.scss";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { useSelector, useDispatch } from "react-redux";
import { clearItemFromCart } from "../../redux/actions/cartActions";
import { addItem, removeItem } from "../../redux/actions/cartActions";
import { selectCartItemsCount } from "../../redux/selectors/cartSelector";
import { IoTrashBinSharp } from "react-icons/all";
import ImageUrl from "../customImage/ImageUrl";

const ProductInCartItem = ({ cartItem }) => {
  const dispatch = useDispatch();
  const cartCount = useSelector(selectCartItemsCount);

  const InputArrows = ({ topArrowClick, bottomArrowClick }) => {
    return (
      <div className="arrows-component-next">
        <div className="arrows-next">
          <button
            className="arrow-bottom-next"
            onClick={() => dispatch(removeItem(cartItem))}
            disabled={cartCount === 0}
          >
            <RemoveIcon />
          </button>

          <div className="numbering-next">
            <h5>{cartItem.quantity}</h5>
          </div>
          <button
            className="arrow-top-next"
            onClick={() => dispatch(addItem(cartItem))}
          >
            <AddIcon />
          </button>
        </div>
      </div>
    );
  };

  const [productURL, setProductURL] = useState();
  useEffect(() => {
    if (url.includes("cart")) {
      let urlSplited2 = url.replace(`cart`, "");
      setProductURL(urlSplited2);
    }
    if (url.includes("cart-overview")) {
      let urlSplited2 = url.replace(`cart-overview`, "");
      setProductURL(urlSplited2);
    }
  }, []);

  let url = window.location.href;
  function pageRedirect(id) {
    if (url.includes("cart")) {
      window.location.href = `${productURL}products/product-details?id=${id}`;
    } else if (url.includes("cart-overview")) {
      window.location.href = `${productURL}products/product-details?id=${id}`;
    } else {
      window.location.href = `${productURL}products/product-details?id=${id}`;
    }
  }
  const imageComingSoon = "/src/repository/product-pic.jpg";

  const cartLinks = JSON.parse(localStorage.getItem("productLink"));
  const removeFromCart = () => {
    const findProdUrl = cartLinks.filter((urls) =>
      urls.includes(`${cartItem.id}`)
    );

    if (findProdUrl) {
      const copyLocalUrls = cartLinks.filter((urls) => urls !== findProdUrl[0]);
      localStorage.removeItem("productLink");
      localStorage.setItem("productLink", JSON.stringify(copyLocalUrls));
    }
  };

  console.log("cartItems", cartItem);
  // const splittedImg = cartItem?.images[0]?.url?.split(";");
  // console.log("splittedImg", splittedImg[0]);

  return (
    <div className="container">
      <div className="row product_table">
        <div className="offset-2 col-8 offset-md-0 col-md-2 col-lg-1">
          <div
            className="image-container"
            onClick={() => pageRedirect(cartItem?.id)}
          >
            {/* {cartItem?.images?.length === 0 ? (
              <ImageUrl img={`/${imageComingSoon}`} style={{ width: 70 }} />
            ) : (
              <ImageUrl
                img={`/${cartItem?.images[0]?.url}`}
                style={{ width: 70 }}
              />
            )} */}
            <ImageUrl img={`/${imageComingSoon}`} style={{ width: 70 }} />
            {/* <ImageUrl img={splittedImg[0]} style={{ width: 70 }} /> */}
          </div>
        </div>
        <div className="col-12 col-md-5 col-lg-7 text_name">
          <span className="name" onClick={() => pageRedirect(cartItem?.id)}>
            {cartItem.productName}
          </span>
        </div>
        <div className="col-12 d-block col-md-4 d-md-none">
          <div className="header-block1">
            <span>Quantity</span>
          </div>
        </div>
        <div className="col-11 col-md-3">
          <InputArrows />
        </div>
        <div className="col-1">
          <div
            className="remove-button"
            onClick={() => {
              removeFromCart();
              dispatch(clearItemFromCart(cartItem));
            }}
          >
            <IoTrashBinSharp color="#0bb3a2" fontSize={24} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductInCartItem;
