import { filterConstans } from "../constants";

export const fullSelectedList = (fullSelectList) => {
  return {
    type: filterConstans.FULL_SELECT_FILTER,
    payload: fullSelectList,
  };
};
export const fullUnselectedList = (fullUnselectList) => {
  return {
    type: filterConstans.FULL_UNSELECT_FILTER,
    payload: fullUnselectList,
  };
};
export const fullUnselectedListId = (fullUnselectList) => {
  return {
    type: filterConstans.FULL_UNSELECT_FILTERID,
    payload: fullUnselectList,
  };
};

export const selectList = (selectList) => {
  return {
    type: filterConstans.SELECT_FILTER,
    payload: selectList,
  };
};

export const unselectList = (unselectList) => {
  return {
    type: filterConstans.UNSELECT_FILTER,
    payload: unselectList,
  };
};

export const removeallselected = (removeallselected) => {
  return {
    type: filterConstans.REMOVE_ALL_FILTERS,
    payload: removeallselected,
  };
};

export const clearFilteredList = () => {
  return {
    type: filterConstans.CLEAR_FILTERED_LIST,
  };
};
