import { filterConstans } from "../constants";

export const toggleCartHIdden = () => ({
  type: filterConstans.TOGGLE_CART_HIDDEN,
});

export const clearCart = () => ({
  type: filterConstans.CLEAR_CART,
});

export const addItem = (item) => ({
  type: filterConstans.ADD_ITEM,
  payload: item,
});

export const addItemFromList = (item) => ({
  type: filterConstans.ADD_ITEM_TO_CART_FROM_LIST,
  payload: item,
});

export const removeItem = (item) => ({
  type: filterConstans.REMOVE_ITEM,
  payload: item,
});

export const clearItemFromCart = (item) => ({
  type: filterConstans.CLEAR_ITEM_FROM_CART,
  payload: item,
});

export const addItemWithQuantity = (item, quantity) => ({
  type: filterConstans.ADD_WITH_QUANTITY,
  payload: [item, parseInt(quantity)],
});
