import { combineReducers } from "redux";
import { filterReducer } from "./reducers/filterReducer";
import pageReducer from "./reducers/pageReducer";
import productsReducer from "./reducers/productsReducer";
import searchReducer from "./reducers/searchReducer";
import { cartReducer } from "./reducers/cartReducer";
import suggestionsReducer from "./reducers/suggestionsReducer";
import suppliersReducer from "./reducers/suppliersReducer";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const rootPersistConfig = {
  key: "root",
  storage,
  whitelist: ["cart"],
};

const persistCartAT = {
  key: "cartAT",
  storage,
};
const persistCartXS = {
  key: "cartXS",
  storage,
};

const rootReducer = combineReducers({
  filter: filterReducer,
  page: pageReducer,
  search: searchReducer,
  products: productsReducer,
  // cart: cartReducer,
  // cart: persistReducer(persistCartXS, cartReducer),
  cart: persistReducer(persistCartAT, cartReducer),
  suggestions: suggestionsReducer,
  suppliers: suppliersReducer,
});

export default persistReducer(rootPersistConfig, rootReducer);
