import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./CustomAccordian.scss";

const Accordion = withStyles({
  root: {
    border: "2px solid #c6c6c6",
    boxShadow: "none",
    borderRadius: 15,
    marginBottom: 20,
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const CustomAccordian = ({ data, disabled }) => {
  //
  const [expanded, setExpanded] = useState(
    "panel1",
    "panel2",
    "panel3",
    "panel4",
    "panel5"
  );

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <>
      {data?.map((item) => {
        return (
          <>
            {item.component ? (
              <Accordion
                square
                key={item.id}
                expanded={expanded === item.id}
                onChange={handleChange(item.id)}
                disabled={disabled}
                className={item.show ? "hideAccordian" : ""}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  {item.titleIcon}
                  <Typography>{item.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="description">{item.component}</div>
                </AccordionDetails>
              </Accordion>
            ) : null}
          </>
        );
      })}
    </>
  );
};

export default CustomAccordian;
