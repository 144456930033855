import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { clearItemFromCart } from "../../redux/actions/cartActions";
import { addItem, removeItem } from "../../redux/actions/cartActions";
import { IoTrashBinSharp, IoIosAdd, IoMdRemove } from "react-icons/all";
import "./cart-dropdown-item.scss";
import { useLocation } from "react-router-dom";
import ImageUrl from "../customImage/ImageUrl";

const CartDropdownItem = ({ cartItem }) => {
  const imageComingSoon = "/src/repository/product-pic.jpg";
  const dispatch = useDispatch();
  let url = window.location.href;
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const search = query.get("search");
  const categoryId = query.get("category");
  const productId = query.get("id");
  const supplierId = query.get("sid");
  const divisionId = query.get("div");
  const mainAreaId = query.get("ma");
  const [newURL, setNewURL] = useState();
  const [productURL, setProductURL] = useState();
  const [detailURL, setDetailURL] = useState();
  const [suppURL, setSuppURL] = useState();
  const [categoryURL, setCategoryURL] = useState();
  const [contentURL, setContentURL] = useState();
  const [divisionURL, setDivisionURL] = useState();
  const [mainAreaURL, setMainAreaURL] = useState();
  const [supplierMainAreaURL, setSupplierMainAreaURL] = useState();

  useEffect(() => {
    if (url.includes("product-details?id")) {
      let urlSplited = url.replace(
        `products/product-details?id=${productId}`,
        ""
      );
      setDetailURL(urlSplited);
    }
    if (url.includes("products?search")) {
      let urlSplited2 = url.replace(`products?search=${search}`, "");
      setProductURL(urlSplited2);
    }
    if (url.includes("search?q")) {
      let urlSplited2 = url.replace(`search?q=${search}`, "");
      setContentURL(urlSplited2);
    }
    if (url.includes("products?sid")) {
      let urlSplited2 = url.replace(`products?sid=${supplierId}`, "");
      setSuppURL(urlSplited2);
    }

    if (url.includes("products?category")) {
      let urlSplited2 = url.replace(`products?category=${categoryId}`, "");
      setCategoryURL(urlSplited2);
    }

    if (url.includes("products?div")) {
      let urlSplited2 = url.replace(`products?div=${divisionId}`, "");
      setDivisionURL(urlSplited2);
    }

    if (url.includes("products?ma")) {
      let urlSplited2 = url.replace(`products?ma=${mainAreaId}`, "");
      setMainAreaURL(urlSplited2);
    }

    if (url.includes("suppliers?ma")) {
      let urlSplited2 = url.replace(`suppliers?ma=${mainAreaId}`, "");
      setSupplierMainAreaURL(urlSplited2);
    }

    if (url.includes("products")) {
      let urlSplited2 = url.replace(`products`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("home")) {
      let urlSplited2 = url.replace(`home`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("about-us")) {
      let urlSplited2 = url.replace(`about-us`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("divisions-page")) {
      let urlSplited2 = url.replace(`divisions-page`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("services")) {
      let urlSplited2 = url.replace(`services`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("news-overview")) {
      let urlSplited2 = url.replace(`news-overview`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("downloads")) {
      let urlSplited2 = url.replace(`downloads`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("privacy-statement")) {
      let urlSplited2 = url.replace(`privacy-statement`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("legal-statement")) {
      let urlSplited2 = url.replace(`legal-statement`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("agb")) {
      let urlSplited2 = url.replace(`agb`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("suppliers")) {
      let urlSplited2 = url.replace(`suppliers`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("cart")) {
      let urlSplited2 = url.replace(`cart`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("cart-overview")) {
      let urlSplited2 = url.replace(`cart-overview`, "");
      setNewURL(urlSplited2);
    }
  }, []);

  const pageRedirect = (id) => {
    if (url.includes("products?search")) {
      window.location.href = `${productURL}products/product-details?id=${id}`; // this works
    } else if (url.includes("product-details?id")) {
      window.location.href = `${detailURL}products/product-details?id=${id}`; // this works
    } else if (url.includes("products?sid")) {
      window.location.href = `${suppURL}products/product-details?id=${id}`; // this works
    } else if (url.includes("products?category")) {
      window.location.href = `${categoryURL}products/product-details?id=${id}`; // this works
    } else if (url.includes("products?div")) {
      window.location.href = `${divisionURL}products/product-details?id=${id}`; // this works
    } else if (url.includes("products?ma")) {
      window.location.href = `${mainAreaURL}products/product-details?id=${id}`; // this works
    } else if (url.includes("suppliers?ma")) {
      window.location.href = `${supplierMainAreaURL}products/product-details?id=${id}`; // this works
    } else if (url.includes("search?q")) {
      window.location.href = `${contentURL}products/product-details?id=${id}`; // this dont work
    }

    // these WORK
    else if (url.includes("products")) {
      window.location.href = `${newURL}products/product-details?id=${id}`;
    } else if (url.includes("products?search")) {
      window.location.href = `${newURL}products/product-details?id=${id}`;
    } else if (url.includes("about-us")) {
      window.location.href = `${newURL}products/product-details?id=${id}`;
    } else if (url.includes("divisions-page")) {
      window.location.href = `${newURL}products/product-details?id=${id}`;
    } else if (url.includes("services")) {
      window.location.href = `${newURL}products/product-details?id=${id}`;
    } else if (url.includes("home")) {
      window.location.href = `${newURL}products/product-details?id=${id}`;
    } else if (url.includes("news-overview")) {
      window.location.href = `${newURL}products/product-details?id=${id}`;
    } else if (url.includes("downloads")) {
      window.location.href = `${newURL}products/product-details?id=${id}`;
    } else if (url.includes("privacy-statement")) {
      window.location.href = `${newURL}products/product-details?id=${id}`;
    } else if (url.includes("legal-statement")) {
      window.location.href = `${newURL}products/product-details?id=${id}`;
    } else if (url.includes("agb")) {
      window.location.href = `${newURL}products/product-details?id=${id}`;
    } else if (url.includes("suppliers")) {
      window.location.href = `${newURL}products/product-details?id=${id}`;
    } else if (url.includes("cart")) {
      window.location.href = `${newURL}products/product-details?id=${id}`;
    } else if (url.includes("cart-overview")) {
      window.location.href = `${newURL}products/product-details?id=${id}`;
    } else {
      window.location.href = `${url}products/product-details?id=${id}`;
    }
  };

  const cartLinks = JSON.parse(localStorage.getItem("productLink"));

  const removeFromCart = () => {
    const findProdUrl = cartLinks.filter((urls) =>
      urls.includes(`${cartItem.id}`)
    );

    if (findProdUrl) {
      const copyLocalUrls = cartLinks.filter((urls) => urls !== findProdUrl[0]);
      localStorage.removeItem("productLink");
      localStorage.setItem("productLink", JSON.stringify(copyLocalUrls));
    }
  };

  return (
    <div className="cart-item">
      <div className="row">
        <div className="col-12">
          <div className="div item-details">
            {/* {cartItem?.images.length === 0 ? (
              <ImageUrl img={`/${imageComingSoon}`} style={{ width: 70 }} />
            ) : (
              <ImageUrl
                img={`/${cartItem?.images[0]?.url}`}
                style={{ width: 70 }}
              />
            )} */}
            <span className="name" onClick={() => pageRedirect(cartItem.id)}>
              {cartItem.productName}
            </span>
            <div className="cart_dropdown_quantity">
              <IoMdRemove
                className="icons_in_cart"
                color="#0bb3a2"
                onClick={() => dispatch(removeItem(cartItem))}
              />
              <span className="quantity_number">{cartItem.quantity}</span>
              <IoIosAdd
                className="icons_in_cart"
                color="#0bb3a2"
                onClick={() => dispatch(addItem(cartItem))}
              />
            </div>
            <IoTrashBinSharp
              onClick={() => {
                removeFromCart();
                dispatch(clearItemFromCart(cartItem));
              }}
              className="icons_in_cart_delete"
              color="#0bb3a2"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartDropdownItem;
