import { filterConstans } from "../constants/index";

const initialState = {
  suppliers: [],
  suppliersnew: [],
  supplierTotalCount: "",
  areasInSupplier: [],
  divisionsInSupplier: [],
  supplierKeyAreasInSupplier: [],
  supplierKeyProductsInSupplier: [],
  supplierMainAreasInSupplier: [],
  totalCount: "",
  loading: false,
  error: "",
  filterSuppliers: [],
  filterTotalCount: "",
};

const suppliersReducer = (state = initialState, action) => {
  switch (action.type) {
    case filterConstans.GET_SUPPLIERS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case filterConstans.GET_SUPPLIERS_SUCCESS:
      return {
        ...state,
        suppliers: action.payload,
        totalCount: action.payload,
        loading: false,
      };

    case filterConstans.GET_SUPPLIERS_SUCCESS_NEW:
      return {
        ...state,
        suppliersnew: action.payload?.suppliersData
          ? action.payload?.suppliersData
          : [],
        areasInSupplier: action.payload?.areas ? action.payload?.areas : [],
        divisionsInSupplier: action.payload?.divisions
          ? action.payload?.divisions
          : [],
        supplierKeyAreasInSupplier: action.payload?.supplierKeyAreas
          ? action.payload?.supplierKeyAreas
          : [],
        supplierKeyProductsInSupplier: action.payload?.supplierKeyProducts
          ? action.payload?.supplierKeyProducts
          : [],
        supplierMainAreasInSupplier: action.payload?.supplierMainAreas
          ? action.payload?.supplierMainAreas
          : [],
        supplierTotalCount: action.payload?.totalHits
          ? action.payload?.totalHits
          : "",
        loading: false,
      };
    case filterConstans.GET_SUPPLIERS_FAILED:
      return {
        ...state,
        error: action.payload.error,
      };

    case filterConstans.FILTER_SUPPLIERS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case filterConstans.FILTER_SUPPLIERS_SUCCESS:
      return {
        ...state,
        filterSuppliers: action.payload,
        loading: false,
      };
    case filterConstans.FILTER_SUPPLIERS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export default suppliersReducer;
