import React, { useEffect } from "react";
import Suppliers from "../components/suppliers/Index";
import i18next from "../i18n";
import { useDispatch } from "react-redux";
import { clearCart } from "../redux/actions/cartActions";

const SuppliersPage = () => {
  const countrySelected = window.location.pathname.split("/");
  const countrySplitted = countrySelected[1];
  const countryLanguage = countrySelected[2];

  const dispatch = useDispatch();

  localStorage.setItem("country", countrySplitted);
  const country = localStorage.getItem("country");

  useEffect(() => {
    if (country !== countrySplitted) {
      dispatch(clearCart());
      // localStorage.clear();
    }
  }, [country]);

  useEffect(() => {
    if (countrySplitted === "al" && countryLanguage === "en") {
      i18next.changeLanguage("en");
    } else if (countrySplitted === "al") {
      i18next.changeLanguage("en");
      // dispatch(clearCart());
    }

    if (countrySplitted === "xs" && countryLanguage === "en") {
      i18next.changeLanguage("en");
    } else if (countrySplitted === "xs") {
      i18next.changeLanguage("en");
      // dispatch(clearCart());
    }

    if (countrySplitted === "bih" && countryLanguage === "en") {
      i18next.changeLanguage("en");
    } else if (countrySplitted === "bih") {
      i18next.changeLanguage("bih");
      // dispatch(clearCart());
    }
    if (countrySplitted === "bg" && countryLanguage === "en") {
      i18next.changeLanguage("en");
    } else if (countrySplitted === "bg") {
      i18next.changeLanguage("bg");
      // dispatch(clearCart());
    }
    if (countrySplitted === "hr" && countryLanguage === "en") {
      i18next.changeLanguage("en");
    } else if (countrySplitted === "hr") {
      i18next.changeLanguage("hr");
      // dispatch(clearCart());
    }
    if (countrySplitted === "cz" && countryLanguage === "en") {
      i18next.changeLanguage("en");
    } else if (countrySplitted === "cz") {
      i18next.changeLanguage("cz");
      // dispatch(clearCart());
    }

    if (countrySplitted === "hu" && countryLanguage === "en") {
      i18next.changeLanguage("en");
    } else if (countrySplitted === "hu") {
      i18next.changeLanguage("hu");
      // dispatch(clearCart());
    }

    if (countrySplitted === "mk" && countryLanguage === "en") {
      i18next.changeLanguage("en");
    } else if (countrySplitted === "mk") {
      i18next.changeLanguage("mk");
      // dispatch(clearCart());
    }

    if (countrySplitted === "me" && countryLanguage === "en") {
      i18next.changeLanguage("en");
    } else if (countrySplitted === "me") {
      i18next.changeLanguage("en");
      // dispatch(clearCart());
    }
    if (countrySplitted === "pl" && countryLanguage === "en") {
      i18next.changeLanguage("en");
    } else if (countrySplitted === "pl") {
      i18next.changeLanguage("pl");
      // dispatch(clearCart());
    }
    if (countrySplitted === "ro" && countryLanguage === "en") {
      i18next.changeLanguage("en");
    } else if (countrySplitted === "ro") {
      i18next.changeLanguage("ro");
      // dispatch(clearCart());
    }
    if (countrySplitted === "rs" && countryLanguage === "en") {
      i18next.changeLanguage("en");
    } else if (countrySplitted === "rs") {
      i18next.changeLanguage("rs");
      // dispatch(clearCart());
    }
    if (countrySplitted === "sl" && countryLanguage === "en") {
      i18next.changeLanguage("en");
    } else if (countrySplitted === "sl") {
      i18next.changeLanguage("sl");
      // dispatch(clearCart());
    }
    if (countrySplitted === "sk" && countryLanguage === "en") {
      i18next.changeLanguage("en");
    } else if (countrySplitted === "sk") {
      i18next.changeLanguage("sl");
      // dispatch(clearCart());
    }
    if (countrySplitted === "si" && countryLanguage === "en") {
      i18next.changeLanguage("en");
    } else if (countrySplitted === "si") {
      i18next.changeLanguage("si");
      // dispatch(clearCart());
    }
    if (countrySplitted === "ch" && countryLanguage === "en") {
      i18next.changeLanguage("en");
    } else if (countrySplitted === "ch") {
      i18next.changeLanguage("de");
      // dispatch(clearCart());
    }

    if (countrySplitted === "en") {
      i18next.changeLanguage("en");
    }

    if (countrySelected.length === 2) {
      i18next.changeLanguage("de");
      // dispatch(clearCart());
    }
  }, []);

  return <Suppliers />;
};

export default SuppliersPage;
