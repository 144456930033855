export const getCurrentLanguage = () => {
  const countrySelected = window.location.pathname.split("/");
  const countrySplitted = countrySelected[1];
  const countryLanguage = countrySelected[2];

  let lng = "de";
  if (countrySplitted === "al" && countryLanguage === "en") {
    lng = "en";
  } else if (countrySplitted === "al") {
    lng = "en";
  }

  if (countrySplitted === "xs" && countryLanguage === "en") {
    lng = "en";
  } else if (countrySplitted === "xs") {
    lng = "en";
  }

  if (countrySplitted === "bih" && countryLanguage === "en") {
    lng = "en";
  } else if (countrySplitted === "bih") {
    lng = "bih";
  }
  if (countrySplitted === "bg" && countryLanguage === "en") {
    lng = "en";
  } else if (countrySplitted === "bg") {
    lng = "bg";
  }
  if (countrySplitted === "hr" && countryLanguage === "en") {
    lng = "en";
  } else if (countrySplitted === "hr") {
    lng = "hr";
  }
  if (countrySplitted === "cz" && countryLanguage === "en") {
    lng = "en";
  } else if (countrySplitted === "cz") {
    lng = "cz";
  }

  if (countrySplitted === "hu" && countryLanguage === "en") {
    lng = "en";
  } else if (countrySplitted === "hu") {
    lng = "hu";
  }

  if (countrySplitted === "mk" && countryLanguage === "en") {
    lng = "en";
  } else if (countrySplitted === "mk") {
    lng = "mk";
  }

  if (countrySplitted === "me" && countryLanguage === "en") {
  } else if (countrySplitted === "me") {
    lng = "en";
  }
  if (countrySplitted === "pl" && countryLanguage === "en") {
    lng = "en";
  } else if (countrySplitted === "pl") {
    lng = "pl";
  }
  if (countrySplitted === "ro" && countryLanguage === "en") {
    lng = "en";
  } else if (countrySplitted === "ro") {
    lng = "ro";
  }
  if (countrySplitted === "rs" && countryLanguage === "en") {
    lng = "en";
  } else if (countrySplitted === "rs") {
    lng = "rs";
  }
  if (countrySplitted === "sl" && countryLanguage === "en") {
    lng = "en";
  } else if (countrySplitted === "sl") {
    lng = "sl";
  }
  if (countrySplitted === "sk" && countryLanguage === "en") {
    lng = "en";
  } else if (countrySplitted === "sk") {
    lng = "sl";
  }
  if (countrySplitted === "si" && countryLanguage === "en") {
    lng = "en";
  } else if (countrySplitted === "si") {
    lng = "si";
  }
  if (countrySplitted === "ch" && countryLanguage === "en") {
    lng = "en";
  } else if (countrySplitted === "ch") {
    lng = "de";
  }

  if (countrySplitted === "en") {
    lng = "en";
  }

  if (countrySelected.length === 2) {
    lng = "de";
  }
  // if (countrySplitted === "al") {
  //   lng = "en";
  // } else if (countrySplitted === "bih") {
  //   lng = "en";
  // } else if (countrySplitted === "bg") {
  //   lng = "en";
  // } else if (countrySplitted === "hr") {
  //   lng = "en";
  // } else if (countrySplitted === "cz") {
  //   lng = "en";
  // } else if (countrySplitted === "xs") {
  //   lng = "en";
  // } else if (countrySplitted === "me") {
  //   lng = "en";
  // } else if (countrySplitted === "hu") {
  //   lng = "hu";
  // } else if (countrySplitted === "pl") {
  //   lng = "en";
  // } else if (countrySplitted === "ro") {
  //   lng = "en";
  // } else if (countrySplitted === "rs") {
  //   lng = "en";
  // } else if (countrySplitted === "sl") {
  //   lng = "en";
  // } else if (countrySplitted === "si") {
  //   lng = "en";
  // } else if (countrySplitted === "ch") {
  //   lng = "de";
  // } else {
  //   lng = "de";
  // }
  return lng;
};
