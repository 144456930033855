import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectCartItems } from "../../../redux/selectors/cartSelector";
import CartDropdownItem from "../../cart-dropdown-item/Index";
import { useLocation } from "react-router-dom";
import "../cart-dropdown.scss";

const CartDropdownDe = () => {
  const cartItems = useSelector(selectCartItems);
  let url = window.location.href;
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const search = query.get("search");
  const categoryId = query.get("category");
  const productId = query.get("id");
  const supplierId = query.get("sid");
  const divisionId = query.get("div");
  const mainAreaId = query.get("ma");
  const [newURL, setNewURL] = useState();
  const [productURL, setProductURL] = useState();
  const [detailURL, setDetailURL] = useState();
  const [suppURL, setSuppURL] = useState();
  const [suppDetailUrl, setSuppDetailUrl] = useState();
  const [categoryURL, setCategoryURL] = useState();
  const [contentURL, setContentURL] = useState();
  const [divisionURL, setDivisionURL] = useState();
  const [mainAreaURL, setMainAreaURL] = useState();
  const [supplierMainAreaURL, setSupplierMainAreaURL] = useState();

  useEffect(() => {
    if (url.includes("product-details?id")) {
      let urlSplited = url.replace(
        `products/product-details?id=${productId}`,
        ""
      );
      setDetailURL(urlSplited);
    }
    if (url.includes("products?search")) {
      let urlSplited2 = url.replace(`products?search=${search}`, "");
      setProductURL(urlSplited2);
    }
    if (url.includes("search?q")) {
      let urlSplited2 = url.replace(`search?q=${search}`, "");
      setContentURL(urlSplited2);
    }
    if (url.includes("products?sid")) {
      let urlSplited2 = url.replace(`products?sid=${supplierId}`, "");
      setSuppURL(urlSplited2);
    }

    if (url.includes("supplier-detail?sid")) {
      let urlSplited2 = url.replace(
        `suppliers/supplier-detail?sid=${supplierId}`,
        ""
      );
      setSuppDetailUrl(urlSplited2);
    }

    if (url.includes("products?category")) {
      let urlSplited2 = url.replace(`products?category=${categoryId}`, "");
      setCategoryURL(urlSplited2);
    }

    if (url.includes("products?div")) {
      let urlSplited2 = url.replace(`products?div=${divisionId}`, "");
      setDivisionURL(urlSplited2);
    }

    if (url.includes("products?ma")) {
      let urlSplited2 = url.replace(`products?ma=${mainAreaId}`, "");
      setMainAreaURL(urlSplited2);
    }

    if (url.includes("suppliers?ma")) {
      let urlSplited2 = url.replace(`suppliers?ma=${mainAreaId}`, "");
      setSupplierMainAreaURL(urlSplited2);
    }

    if (url.includes("products")) {
      let urlSplited2 = url.replace(`products`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("about-us")) {
      let urlSplited2 = url.replace(`about-us`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("divisions-page")) {
      let urlSplited2 = url.replace(`divisions-page`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("services")) {
      let urlSplited2 = url.replace(`services`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("home")) {
      let urlSplited2 = url.replace(`home`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("news-overview")) {
      let urlSplited2 = url.replace(`news-overview`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("downloads")) {
      let urlSplited2 = url.replace(`downloads`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("privacy-statement")) {
      let urlSplited2 = url.replace(`privacy-statement`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("legal-statement")) {
      let urlSplited2 = url.replace(`legal-statement`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("impressum")) {
      let urlSplited2 = url.replace(`impressum`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("agb")) {
      let urlSplited2 = url.replace(`agb`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("suppliers")) {
      let urlSplited2 = url.replace(`suppliers`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("cart")) {
      let urlSplited2 = url.replace(`cart`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("cart-overview")) {
      let urlSplited2 = url.replace(`cart-overview`, "");
      setNewURL(urlSplited2);
    }

    if (url.includes("divisions/in-vitro-diagnostics")) {
      let urlSplited2 = url.replace(`divisions/in-vitro-diagnostics`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("divisions/medical-devices")) {
      let urlSplited2 = url.replace(`divisions/medical-devices`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("divisions/life-science")) {
      let urlSplited2 = url.replace(`divisions/life-science`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("divisions/veterinary")) {
      let urlSplited2 = url.replace(`divisions/veterinary`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("divisions/clinical-it")) {
      let urlSplited2 = url.replace(`divisions/clinical-it`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("divisions/industry")) {
      let urlSplited2 = url.replace(`divisions/industry`, "");
      setNewURL(urlSplited2);
    }
  }, []);

  const redirectQuote = () => {
    if (url.includes("products?search")) {
      window.location.href = `${productURL}cart`; // this works
    } else if (url.includes("product-details?id")) {
      window.location.href = `${detailURL}cart`; // this works
    } else if (url.includes("products?sid")) {
      window.location.href = `${suppURL}cart`; // this works
    } else if (url.includes("supplier-detail?sid")) {
      window.location.href = `${suppDetailUrl}cart`; // this works
    } else if (url.includes("products?category")) {
      window.location.href = `${categoryURL}cart`; // this works
    } else if (url.includes("products?div")) {
      window.location.href = `${divisionURL}cart`; // this works
    } else if (url.includes("products?ma")) {
      window.location.href = `${mainAreaURL}cart`; // this works
    } else if (url.includes("suppliers?ma")) {
      window.location.href = `${supplierMainAreaURL}cart`; // this works
    } else if (url.includes("search?q")) {
      window.location.href = `${contentURL}cart`; // this dont work
    }
    // these WORK
    else if (url.includes("products")) {
      window.location.href = `${newURL}cart`;
    } else if (url.includes("about-us")) {
      window.location.href = `${newURL}cart`;
    } else if (url.includes("divisions-page")) {
      window.location.href = `${newURL}cart`;
    } else if (url.includes("home")) {
      window.location.href = `${newURL}cart`;
    } else if (url.includes("impressum")) {
      window.location.href = `${newURL}cart`;
    } else if (url.includes("services")) {
      window.location.href = `${newURL}cart`;
    } else if (url.includes("news-overview")) {
      window.location.href = `${newURL}cart`;
    } else if (url.includes("downloads")) {
      window.location.href = `${newURL}cart`;
    } else if (url.includes("privacy-statement")) {
      window.location.href = `${newURL}cart`;
    } else if (url.includes("legal-statement")) {
      window.location.href = `${newURL}cart`;
    } else if (url.includes("agb")) {
      window.location.href = `${newURL}cart`;
    } else if (url.includes("suppliers")) {
      window.location.href = `${newURL}cart`;
    } else if (url.includes("cart")) {
      window.location.href = `${newURL}cart`;
    } else if (url.includes("cart-overview")) {
      window.location.href = `${newURL}cart`;
    } else if (url.includes("divisions/in-vitro-diagnostics")) {
      window.location.href = `${newURL}cart`;
    } else if (url.includes("divisions/medical-devices")) {
      window.location.href = `${newURL}cart`;
    } else if (url.includes("divisions/life-science")) {
      window.location.href = `${newURL}cart`;
    } else if (url.includes("divisions/veterinary")) {
      window.location.href = `${newURL}cart`;
    } else if (url.includes("divisions/clinical-it")) {
      window.location.href = `${newURL}cart`;
    } else if (url.includes("divisions/industry")) {
      window.location.href = `${newURL}cart`;
    } else {
      window.location.href = `${url}cart`;
    }
  };

  const redirectCartOnly = () => {
    if (url.includes("products?search")) {
      window.location.href = `${productURL}cart-overview`; // this works
    } else if (url.includes("product-details?id")) {
      window.location.href = `${detailURL}cart-overview`; // this works
    } else if (url.includes("products?sid")) {
      window.location.href = `${suppURL}cart-overview`; // this works
    } else if (url.includes("supplier-detail?sid")) {
      window.location.href = `${suppDetailUrl}cart-overview`; // this works
    } else if (url.includes("products?category")) {
      window.location.href = `${categoryURL}cart-overview`; // this works
    } else if (url.includes("products?div")) {
      window.location.href = `${divisionURL}cart-overview`; // this works
    } else if (url.includes("products?ma")) {
      window.location.href = `${mainAreaURL}cart-overview`; // this works
    } else if (url.includes("suppliers?ma")) {
      window.location.href = `${supplierMainAreaURL}cart-overview`; // this works
    } else if (url.includes("search?q")) {
      window.location.href = `${contentURL}cart-overview`; // this dont work
    }
    // these WORK
    else if (url.includes("products")) {
      window.location.href = `${newURL}cart-overview`;
    } else if (url.includes("about-us")) {
      window.location.href = `${newURL}cart-overview`;
    } else if (url.includes("impressum")) {
      window.location.href = `${newURL}cart-overview`;
    } else if (url.includes("divisions-page")) {
      window.location.href = `${newURL}cart-overview`;
    } else if (url.includes("services")) {
      window.location.href = `${newURL}cart-overview`;
    } else if (url.includes("home")) {
      window.location.href = `${newURL}cart-overview`;
    } else if (url.includes("news-overview")) {
      window.location.href = `${newURL}cart-overview`;
    } else if (url.includes("downloads")) {
      window.location.href = `${newURL}cart-overview`;
    } else if (url.includes("privacy-statement")) {
      window.location.href = `${newURL}cart-overview`;
    } else if (url.includes("legal-statement")) {
      window.location.href = `${newURL}cart-overview`;
    } else if (url.includes("agb")) {
      window.location.href = `${newURL}cart-overview`;
    } else if (url.includes("suppliers")) {
      window.location.href = `${newURL}cart-overview`;
    } else if (url.includes("cart")) {
      window.location.href = `${newURL}cart-overview`;
    } else if (url.includes("cart-overview")) {
      window.location.href = `${newURL}cart-overview`;
    } else if (url.includes("divisions/in-vitro-diagnostics")) {
      window.location.href = `${newURL}cart-overview`;
    } else if (url.includes("divisions/medical-devices")) {
      window.location.href = `${newURL}cart-overview`;
    } else if (url.includes("divisions/life-science")) {
      window.location.href = `${newURL}cart-overview`;
    } else if (url.includes("divisions/veterinary")) {
      window.location.href = `${newURL}cart-overview`;
    } else if (url.includes("divisions/clinical-it")) {
      window.location.href = `${newURL}cart-overview`;
    } else if (url.includes("divisions/industry")) {
      window.location.href = `${newURL}cart-overview`;
    } else {
      window.location.href = `${url}cart-overview`;
    }
  };

  const redirectToProducts = () => {
    if (url.includes("products?search")) {
      window.location.href = `${productURL}products`; // this works
    } else if (url.includes("product-details?id")) {
      window.location.href = `${detailURL}products`; // this works
    } else if (url.includes("products?sid")) {
      window.location.href = `${suppURL}products`; // this works
    } else if (url.includes("supplier-detail?sid")) {
      window.location.href = `${suppDetailUrl}products`; // this works
    } else if (url.includes("products?category")) {
      window.location.href = `${categoryURL}products`; // this works
    } else if (url.includes("products?div")) {
      window.location.href = `${divisionURL}products`; // this works
    } else if (url.includes("products?ma")) {
      window.location.href = `${mainAreaURL}products`; // this works
    } else if (url.includes("suppliers?ma")) {
      window.location.href = `${supplierMainAreaURL}products`; // this works
    } else if (url.includes("search?q")) {
      window.location.href = `${contentURL}products`; // this dont work
    }
    // these WORK
    else if (url.includes("products")) {
      window.location.href = `${newURL}products`;
    } else if (url.includes("about-us")) {
      window.location.href = `${newURL}products`;
    } else if (url.includes("impressum")) {
      window.location.href = `${newURL}products`;
    } else if (url.includes("divisions-page")) {
      window.location.href = `${newURL}products`;
    } else if (url.includes("services")) {
      window.location.href = `${newURL}products`;
    } else if (url.includes("home")) {
      window.location.href = `${newURL}products`;
    } else if (url.includes("news-overview")) {
      window.location.href = `${newURL}products`;
    } else if (url.includes("downloads")) {
      window.location.href = `${newURL}products`;
    } else if (url.includes("privacy-statement")) {
      window.location.href = `${newURL}products`;
    } else if (url.includes("legal-statement")) {
      window.location.href = `${newURL}products`;
    } else if (url.includes("agb")) {
      window.location.href = `${newURL}products`;
    } else if (url.includes("suppliers")) {
      window.location.href = `${newURL}products`;
    } else if (url.includes("cart")) {
      window.location.href = `${newURL}products`;
    } else if (url.includes("cart-overview")) {
      window.location.href = `${newURL}products`;
    }
    // new routes
    else if (url.includes("divisions/in-vitro-diagnostics")) {
      window.location.href = `${newURL}products`;
    } else if (url.includes("divisions/medical-devices")) {
      window.location.href = `${newURL}products`;
    } else if (url.includes("divisions/life-science")) {
      window.location.href = `${newURL}products`;
    } else if (url.includes("divisions/veterinary")) {
      window.location.href = `${newURL}products`;
    } else if (url.includes("divisions/clinical-it")) {
      window.location.href = `${newURL}products`;
    } else if (url.includes("divisions/industry")) {
      window.location.href = `${newURL}products`;
    }
    // new routes
    else {
      window.location.href = `${url}products`;
    }
  };

  return (
    <div className="cart-dropdown">
      <div className="cart_header">
        <h3>Produkt</h3>
        <h3>Menge</h3>
        {/* <h3>X</h3> */}
      </div>
      <div className="cart-items">
        {cartItems.length ? (
          cartItems.map((cartItem) => (
            <CartDropdownItem key={cartItem.id} cartItem={cartItem} />
          ))
        ) : (
          <>
            <span className="empty-message">Ihr Warenkorb ist leer</span>
            <button className="find_products" onClick={redirectToProducts}>
              Produkte finden
            </button>
          </>
        )}
      </div>
      {cartItems.length > 0 ? (
        <div className="cart_btns">
          <a className="go_to_cart" onClick={redirectCartOnly}>
            Warenkorb
          </a>
          <a className="go_to_cart" onClick={redirectQuote}>
            Anfrage senden
          </a>
        </div>
      ) : null}
    </div>
  );
};

export default CartDropdownDe;
