import React, { useEffect, useState } from "react";
import "./card-product.scss";
import ImageUrl from "../customImage/ImageUrl";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ProductNoIMG from "../../assets/product-pic.jpg";

function Index({
  description,
  category,
  size,
  id,
  productName,
  productImg,
  cloneNumber,
  isotype,
  hostFull,
  reactivityFull,
  clonality,
  supplier,
  application,
  sku,
  source,
  assayRange,
  sampleType,
  sensitivity,
  host_Source,
  reactivity,
}) {
  const loadingState = useSelector((state) => state.filter.loading);
  const imageComingSoon = "/src/repository/product-pic.jpg";

  const CheckProductItem = ({ item, title }) => {
    if (item === "-") {
      return null;
    } else {
      return (
        <tr>
          <td style={{ width: "250px" }}>
            {loadingState ? <Skeleton width={100} height={15} /> : title}
          </td>
          <td style={{ width: "250px" }}>
            {loadingState ? <Skeleton width={200} height={15} /> : item}
          </td>
        </tr>
      );
    }
  };
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const search = query.get("search");
  const categoryId = query.get("category");
  const supplierId = query.get("sid");
  const divisionId = query.get("div");
  const mainAreaId = query.get("ma");

  const [newURL, setNewURL] = useState();
  const [productURL, setProductURL] = useState();
  const [suppURL, setSuppURL] = useState();
  const [categoryURL, setCategoryURL] = useState();
  const [divisionURL, setDivisionURL] = useState();
  const [mainAreaURL, setMainAreaURL] = useState();

  useEffect(() => {
    if (url.includes("products?search")) {
      let searchWithOutSpaces = search.replaceAll(" ", "%20");
      let urlSplited2 = url.replace(
        `products?search=${searchWithOutSpaces}`,
        ""
      );
      setProductURL(urlSplited2);
    }

    if (url.includes("products?sid")) {
      let urlSplited2 = url.replace(`products?sid=${supplierId}`, "");
      setSuppURL(urlSplited2);
    }

    if (url.includes("products?div")) {
      let urlSplited2 = url.replace(`products?div=${divisionId}`, "");
      setDivisionURL(urlSplited2);
    }

    if (url.includes("products?ma")) {
      let urlSplited2 = url.replace(`products?ma=${mainAreaId}`, "");
      setMainAreaURL(urlSplited2);
    }

    if (url.includes("products?category")) {
      let urlSplited2 = url.replace(`products?category=${categoryId}`, "");
      setCategoryURL(urlSplited2);
    }

    if (url.includes("products")) {
      let urlSplited2 = url.replace(`products`, "");
      setNewURL(urlSplited2);
    }
  }, []);

  let url = window.location.href;
  const pageRedirect = (ids) => {
    if (url.includes("products?search")) {
      window.location.href = `${productURL}products/product-details?id=${ids}`;
    } else if (url.includes("products?sid")) {
      window.location.href = `${suppURL}products/product-details?id=${ids}`;
    } else if (url.includes("products?category")) {
      window.location.href = `${categoryURL}products/product-details?id=${ids}`;
    } else if (url.includes("products?div")) {
      window.location.href = `${divisionURL}products/product-details?id=${ids}`; // this works
    } else if (url.includes("products?ma")) {
      window.location.href = `${mainAreaURL}products/product-details?id=${ids}`; // this works
    } else if (url.includes("products")) {
      window.location.href = `${newURL}products/product-details?id=${ids}`;
    } else {
      window.location.href = `${url}products/product-details?id=${ids}`;
    }
  };

  return (
    <SkeletonTheme color="#eee" highlightColor="#ffffff">
      <div
        className="card_product_items view_product"
        onClick={() => pageRedirect(id)}
        style={{ cursor: "pointer" }}
        id="product_item_track"
      >
        <h2 onClick={() => pageRedirect(id)} title={`${description} / ${size}`}>
          {loadingState ? <Skeleton width={400} height={30} /> : description} /{" "}
          {loadingState ? <Skeleton width={200} height={30} /> : size}
        </h2>
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12 col-xl-3 img_responsive">
            {productImg === "-" ? (
              <img src={ProductNoIMG} alt="" />
            ) : (
              // <ImageUrl img={`/${imageComingSoon}`} />
              <>
                {loadingState ? (
                  <Skeleton width={200} height={200} />
                ) : (
                  <ImageUrl onClick={() => pageRedirect(id)} img={productImg} />
                )}
              </>
            )}
          </div>
          <div className="col-12 col-md-12 col-lg-12 col-xl-6">
            <table className="table-products-info">
              <tbody>
                <CheckProductItem title="Product Code:" item={sku} />
                <CheckProductItem title="Product Name:" item={productName} />
                <CheckProductItem title="Supplier:" item={supplier} />
                <CheckProductItem title="Size:" item={size} />
                <CheckProductItem title="Reactivity:" item={reactivityFull} />
                {/* <CheckProductItem title="Source:" item={source} /> */}
                <CheckProductItem
                  title="Applications Abbreviations:"
                  item={application}
                />
                <CheckProductItem title="Assay Range:" item={assayRange} />
                <CheckProductItem title="Sample Type:" item={sampleType} />
                <CheckProductItem title="Sensitivity:" item={sensitivity} />
                <CheckProductItem title="Clonality:" item={clonality} />
                <CheckProductItem title="Clone Number:" item={cloneNumber} />
                <CheckProductItem title="IsoType" item={isotype} />
                <CheckProductItem title="Host:" item={hostFull} />
                <CheckProductItem title="Product Group:" item={category} />
                <CheckProductItem title="Host/Source" item={host_Source} />
                <CheckProductItem title="Reactivity" item={reactivity} />
                {/* <CheckProductItem title="Country" item={country} /> */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
}

export default Index;
