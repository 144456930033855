import React, { useState, useEffect } from "react";
import "./suppliers.scss";
import Pagination from "@material-ui/lab/Pagination";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import ImageUrl from "../customImage/ImageUrl";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { getSuppliers } from "../../httpClient/index";
import { DebounceInput } from "react-debounce-input";
import { supPage } from "../../redux/actions/pageActions";
import ProductNoIMG from "../../assets/product-pic.jpg";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getCurrentLanguage } from "../GetCurrentLanguage";

function CustomSelect({ options, placeholder, onChange, total, selectedList }) {
  const animatedComponents = makeAnimated();
  return (
    <Select
      closeMenuOnSelect
      components={animatedComponents}
      isMulti
      noOptionsMessage={() => "No filter Found"}
      isSearchable
      onChange={onChange}
      placeholder={`${placeholder}${" "}(${total})`}
      menuPlacement="bottom"
      value={[...selectedList]}
      options={options
        .sort((a, b) => a?.name?.localeCompare(b?.name))
        .map((filter) => ({
          id: filter?.id,
          value: filter?.name,
          label: filter?.name,
        }))}
      name="filter"
    />
  );
}

const Suppliers = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [wordEntered, setWordEntered] = useState("");
  const [page, setPage] = useState(1);
  const suppliersState = useSelector((state) => state.suppliers.suppliersnew);
  const divisionList = useSelector(
    (state) => state.suppliers.divisionsInSupplier
  );
  // const supplierKeyAreasList = useSelector(
  //   (state) => state.suppliers.supplierKeyAreasInSupplier
  // );
  // const supplierKeyProductsList = useSelector(
  //   (state) => state.suppliers.supplierKeyProductsInSupplier
  // );
  const supplierMainAreasList = useSelector(
    (state) => state.suppliers.supplierMainAreasInSupplier
  );
  const totalCount = useSelector((state) => state.suppliers.supplierTotalCount);
  const handleChange = (event, value) => {
    setPage(value);
    dispatch(supPage(value));
  };
  const [divisionSelectedList, setDivisionSelectedList] = useState([]);
  const [keyAreaSelectedList, setKeyAreaSelectedList] = useState([]);
  const [keyProductsSelectedList, setKeyProductsSelectedList] = useState([]);
  const [mainAreaSelectedList, setMainAreaSelectedList] = useState([]);
  const countrySelected = window.location.pathname.split("/");
  const countrySplitted = countrySelected[1];
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  let url = window.location.href;
  const [mainAreaId, setMainAreaId] = useState(query.get("ma"));
  const [divisionId, setDivisionId] = useState(query.get("div"));
  const [suppURL, setSuppURL] = useState();
  const [mainAreaList, setMainAreaList] = useState();
  const [divisionAreaList, setDivisionAreaList] = useState();
  const [triggerMain, setTriggerMain] = useState(true);
  const [triggerDiv, setTriggerDiv] = useState(true);

  useEffect(() => {
    setPage(1);
  }, [
    wordEntered,
    divisionAreaList,
    mainAreaList,
    mainAreaSelectedList,
    divisionSelectedList,
  ]);

  useEffect(() => {
    if (triggerMain) {
      supplierMainAreasList?.map((mainArea) => {
        if (mainArea.id === mainAreaId) {
          setMainAreaList(mainArea);
          setMainAreaSelectedList([
            ...mainAreaSelectedList,
            {
              id: mainArea?.id,
              label: mainArea?.name,
              value: mainArea?.name,
            },
          ]);
          setTriggerMain(false);
        }
      });
    }
    if (triggerDiv) {
      divisionList?.map((div) => {
        if (div.id === divisionId) {
          setDivisionAreaList(div);
          setDivisionSelectedList([
            ...divisionSelectedList,
            {
              id: div?.id,
              label: div?.name,
              value: div?.name,
            },
          ]);
          setTriggerDiv(false);
        }
      });
    }
  }, [supplierMainAreasList, mainAreaList, divisionList, divisionAreaList]);

  const currLng = getCurrentLanguage();
  useEffect(() => {
    let divisionFilters = "&did=";
    divisionSelectedList.map((division) => {
      divisionFilters += `${division.id},`;
    });
    let keyAreaFilters = "&skaid=";
    keyAreaSelectedList.map((keyArea) => {
      keyAreaFilters += `${keyArea.id},`;
    });
    let keyProductsFilters = "&skpid=";
    keyProductsSelectedList.map((keyProducts) => {
      keyProductsFilters += `${keyProducts.id},`;
    });
    let mainAreaFilters = "&smaid=";
    mainAreaSelectedList.map((main) => {
      mainAreaFilters += `${main.id},`;
    });
    let suppliersFilters =
      divisionFilters.slice(0, -1) +
      // keyAreaFilters.slice(0, -1) +
      // keyProductsFilters.slice(0, -1) +
      mainAreaFilters.slice(0, -1);
    // if (divisionId !== null) {
    //   suppliersFilters = `${divisionFilters}${divisionId}`;
    // }
    // if (mainAreaId !== null) {
    //   suppliersFilters = `${mainAreaFilters}${mainAreaId}`;
    //   // mainAreaSelectedList.push(mainAreaId);
    // }

    if (countrySplitted === "al") {
      dispatch(
        getSuppliers(
          suppliersFilters,
          wordEntered,
          "AL",
          (page - 1) * 10,
          currLng
        )
      );
    } else if (countrySplitted === "bih") {
      dispatch(
        getSuppliers(
          suppliersFilters,
          wordEntered,
          "BIH",
          (page - 1) * 10,
          currLng
        )
      );
    } else if (countrySplitted === "bg") {
      dispatch(
        getSuppliers(
          suppliersFilters,
          wordEntered,
          "BG",
          (page - 1) * 10,
          currLng
        )
      );
    } else if (countrySplitted === "hr") {
      dispatch(
        getSuppliers(
          suppliersFilters,
          wordEntered,
          "HR",
          (page - 1) * 10,
          currLng
        )
      );
    } else if (countrySplitted === "cz") {
      dispatch(
        getSuppliers(
          suppliersFilters,
          wordEntered,
          "CZ",
          (page - 1) * 10,
          currLng
        )
      );
    } else if (countrySplitted === "hu") {
      dispatch(
        getSuppliers(
          suppliersFilters,
          wordEntered,
          "HU",
          (page - 1) * 10,
          currLng
        )
      );
    } else if (countrySplitted === "xs") {
      dispatch(
        getSuppliers(
          suppliersFilters,
          wordEntered,
          "XK",
          (page - 1) * 10,
          currLng
        )
      );
    } else if (countrySplitted === "mk") {
      dispatch(
        getSuppliers(
          suppliersFilters,
          wordEntered,
          "MK",
          (page - 1) * 10,
          currLng
        )
      );
    } else if (countrySplitted === "me") {
      dispatch(
        getSuppliers(
          suppliersFilters,
          wordEntered,
          "ME",
          (page - 1) * 10,
          currLng
        )
      );
    } else if (countrySplitted === "pl") {
      dispatch(
        getSuppliers(
          suppliersFilters,
          wordEntered,
          "PL",
          (page - 1) * 10,
          currLng
        )
      );
    } else if (countrySplitted === "ro") {
      dispatch(
        getSuppliers(
          suppliersFilters,
          wordEntered,
          "RO",
          (page - 1) * 10,
          currLng
        )
      );
    } else if (countrySplitted === "rs") {
      dispatch(
        getSuppliers(
          suppliersFilters,
          wordEntered,
          "RS",
          (page - 1) * 10,
          currLng
        )
      );
    } else if (countrySplitted === "sl") {
      dispatch(
        getSuppliers(
          suppliersFilters,
          wordEntered,
          "SK",
          (page - 1) * 10,
          currLng
        )
      );
    } else if (countrySplitted === "sk") {
      dispatch(
        getSuppliers(
          suppliersFilters,
          wordEntered,
          "SK",
          (page - 1) * 10,
          currLng
        )
      );
    } else if (countrySplitted === "si") {
      dispatch(
        getSuppliers(
          suppliersFilters,
          wordEntered,
          "SI",
          (page - 1) * 10,
          currLng
        )
      );
    } else if (countrySplitted === "ch") {
      dispatch(
        getSuppliers(
          suppliersFilters,
          wordEntered,
          "CH",
          (page - 1) * 10,
          currLng
        )
      );
    } else {
      dispatch(
        getSuppliers(
          suppliersFilters,
          wordEntered,
          "AT",
          (page - 1) * 10,
          currLng
        )
      );
    }
  }, [
    divisionSelectedList,
    keyAreaSelectedList,
    keyProductsSelectedList,
    mainAreaSelectedList,
    page,
    wordEntered,
  ]);

  useEffect(() => {
    if (url.includes("suppliers")) {
      let urlSplited2 = url.replace(`suppliers`, "");
      setSuppURL(urlSplited2);
    }
    if (url.includes("suppliers?ma")) {
      let urlSplited2 = url.replace(`suppliers?ma=${mainAreaId}`, "");
      setSuppURL(urlSplited2);
    }
    if (url.includes("suppliers?div")) {
      let urlSplited2 = url.replace(`suppliers?div=${divisionId}`, "");
      setSuppURL(urlSplited2);
    }
  }, []);

  function handleSupplierClicked(id) {
    if (url.includes("suppliers")) {
      window.location.href = `${suppURL}suppliers/supplier-detail?sid=${id}`;
    } else if (url.includes("suppliers?ma")) {
      window.location.href = `${suppURL}suppliers/supplier-detail?sid=${id}`;
    } else if (url.includes("suppliers?div")) {
      window.location.href = `${suppURL}suppliers/supplier-detail?sid=${id}`;
    } else {
      window.location.href = `${url}suppliers/supplier-detail?sid=${id}`;
    }
  }

  // const handleSearch = () => {
  //   return dispatch(getSuppliers("", wordEntered, "", (page - 1) * 10));
  // };
  // const onKeyDown = (e) => {
  //   if (e.keyCode === 13) {
  //     handleSearch();
  //   }
  // };

  return (
    <div className="container">
      <div className="row rowSuppliers">
        <div className="supplier_filters">
          <CustomSelect
            placeholder={t("Divisions")}
            options={divisionList}
            total={divisionList.length}
            selectedList={divisionSelectedList}
            onChange={(e) => {
              setDivisionSelectedList(e);
            }}
          />
          <CustomSelect
            placeholder={t("Main_Areas")}
            options={supplierMainAreasList}
            total={supplierMainAreasList.length}
            selectedList={mainAreaSelectedList}
            onChange={(e) => {
              setMainAreaSelectedList(e);
            }}
          />
          {/* <CustomSelect
            placeholder="Key Products"
            options={supplierKeyProductsList}
            total={supplierKeyProductsList.length}
            onChange={(e) => setKeyProductsSelectedList(e)}
          />
          <CustomSelect
            placeholder="Key Areas"
            options={supplierKeyAreasList}
            total={supplierKeyAreasList.length}
            onChange={(e) => setKeyAreaSelectedList(e)}
          /> */}
          <div className="suppliers-button">
            <DebounceInput
              id="inputKey"
              type="text"
              placeholder={t("Search_for_suppliers")}
              onChange={(e) => setWordEntered(e.target.value)}
              value={wordEntered}
              // onKeyDown={onKeyDown}
              minLength={3}
            />
            <button className="search-icon">
              <SearchIcon
                color="secondary"
                // onClick={handleSearch}
              />
            </button>
          </div>
        </div>
        <hr id="hr2" />
        <div className="suppliers">
          {suppliersState.length > 0 ? (
            suppliersState?.map((supp) => {
              return (
                <div
                  className="suppliers_content"
                  onClick={() => handleSupplierClicked(supp?.o_id)}
                >
                  <div className="square" id={`${supp?.SupplierName}`}>
                    {supp?.imgURL ? (
                      <ImageUrl
                        img={supp?.imgURL}
                        alt={`${supp?.SupplierName}`}
                        className="supp_imgs"
                        id={`${supp?.SupplierName}`}
                      />
                    ) : (
                      <img
                        src={ProductNoIMG}
                        alt=""
                        className="no_img_supp"
                        id={`${supp?.SupplierName}`}
                      />
                    )}
                  </div>
                  <p id={`${supp?.SupplierName}`}>{supp?.SupplierName}</p>
                </div>
              );
            })
          ) : (
            <div>
              <p style={{ fontFamily: "axiformalight", color: "#777" }}>
                {t("No_Suppliers_found")}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="col-12 supPagination">
        <Pagination
          showFirstButton
          showLastButton
          count={Math.ceil(totalCount / 10)}
          page={page}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};
export default Suppliers;
