import SearchIcon from "@material-ui/icons/Search";
import React, { useEffect, useRef, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  getProductsByFilters,
  getCategorySuggestions,
  getContents,
  getSuppliers,
} from "../httpClient";
import { searchKeyword } from "../redux/actions/searchAction";
import "./SearchHeaderCountryRoute.scss";
import { IoIosArrowDown } from "react-icons/all";
import { useTranslation } from "react-i18next";
import { getCurrentLanguage } from "../components/GetCurrentLanguage";

function CroatiaSearch() {
  const { t } = useTranslation();
  const [keyword, setKeyword] = useState("");
  const dispatch = useDispatch();
  const page = useSelector((state) => state.page.page);
  const productSuggestions = useSelector((state) => state.filter.products);
  const categorySuggestionsNew = useSelector(
    (state) => state.suggestions.catSuggestions
  );
  const suppliersState = useSelector((state) => state.suppliers.suppliersnew);
  const [isShow, setIsShow] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const ref = useRef();
  const searchDropdown = useRef();
  const [chkbox, setChkbox] = useState(1);
  const toggleDropdown = () => setOpen(!isOpen);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (isShow && ref.current && !ref.current.contains(e.target)) {
        setIsShow(false);
      }
      if (
        isOpen &&
        searchDropdown.current &&
        !searchDropdown.current.contains(e.target)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isShow, isOpen]);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  let url = window.location.href;
  const search = query.get("search");
  const content = query.get("q");
  const categoryId = query.get("category");
  const productId = query.get("id");
  const supplierId = query.get("sid");
  const divisionId = query.get("div");
  const mainAreaId = query.get("ma");

  const [newURL, setNewURL] = useState();
  const [productURL, setProductURL] = useState();
  const [detailURL, setDetailURL] = useState();
  const [suppURL, setSuppURL] = useState();
  const [suppDetailUrl, setSuppDetailUrl] = useState();
  const [categoryURL, setCategoryURL] = useState();
  const [contentURL, setContentURL] = useState();
  const [divisionURL, setDivisionURL] = useState();
  const [mainAreaURL, setMainAreaURL] = useState();
  const [supplierMainAreaURL, setSupplierMainAreaURL] = useState();

  const handleChange = (theVal) => {
    setChkbox(theVal);
    setOpen(!isOpen);
  };

  useEffect(() => {
    if (url.includes("product-details?id")) {
      let urlSplited = url.replace(
        `products/product-details?id=${productId}`,
        ""
      );
      setDetailURL(urlSplited);
    }
    if (url.includes("products?search")) {
      let searchWithOutSpaces = search.replaceAll(" ", "%20");
      let urlSplited2 = url.replace(
        `products?search=${searchWithOutSpaces}`,
        ""
      );
      // setProductURL(origin + "/" + pathNameXS + "/");
      setProductURL(urlSplited2);
    }
    if (url.includes("search?q")) {
      let searchWithOutSpaces = content.replaceAll(" ", "%20");
      let urlSplited2 = url.replace(`search?q=${searchWithOutSpaces}`, "");
      setContentURL(urlSplited2);
    }
    if (url.includes("products?sid")) {
      let urlSplited2 = url.replace(`products?sid=${supplierId}`, "");
      setSuppURL(urlSplited2);
    }

    if (url.includes("supplier-detail?sid")) {
      let urlSplited2 = url.replace(
        `suppliers/supplier-detail?sid=${supplierId}`,
        ""
      );
      setSuppDetailUrl(urlSplited2);
    }

    if (url.includes("products?div")) {
      let urlSplited2 = url.replace(`products?div=${divisionId}`, "");
      setDivisionURL(urlSplited2);
    }

    if (url.includes("suppliers?div")) {
      let urlSplited2 = url.replace(`suppliers?div=${divisionId}`, "");
      setDivisionURL(urlSplited2);
    }

    if (url.includes("products?ma")) {
      let urlSplited2 = url.replace(`products?ma=${mainAreaId}`, "");
      setMainAreaURL(urlSplited2);
    }

    if (url.includes("suppliers?ma")) {
      let urlSplited2 = url.replace(`suppliers?ma=${mainAreaId}`, "");
      setSupplierMainAreaURL(urlSplited2);
    }

    if (url.includes("products?category")) {
      let urlSplited2 = url.replace(`products?category=${categoryId}`, "");
      setCategoryURL(urlSplited2);
    }

    if (url.includes("products")) {
      let urlSplited2 = url.replace(`products`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("search")) {
      let urlSplited2 = url.replace(`search`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("career")) {
      let urlSplited2 = url.replace(`career`, "");
      setNewURL(urlSplited2);
    }

    if (url.includes("home")) {
      let urlSplited2 = url.replace(`home`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("about-us")) {
      let urlSplited2 = url.replace(`about-us`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("divisions-page")) {
      let urlSplited2 = url.replace(`divisions-page`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("services")) {
      let urlSplited2 = url.replace(`services`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("news-overview")) {
      let urlSplited2 = url.replace(`news-overview`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("downloads")) {
      let urlSplited2 = url.replace(`downloads`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("privacy-statement")) {
      let urlSplited2 = url.replace(`privacy-statement`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("legal-statement")) {
      let urlSplited2 = url.replace(`legal-statement`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("impressum")) {
      let urlSplited2 = url.replace(`impressum`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("agb")) {
      let urlSplited2 = url.replace(`agb`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("suppliers")) {
      let urlSplited2 = url.replace(`suppliers`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("cart")) {
      let urlSplited2 = url.replace(`cart`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("cart-overview")) {
      let urlSplited2 = url.replace(`cart-overview`, "");
      setNewURL(urlSplited2);
    }

    if (url.includes("divisions/in-vitro-diagnostics")) {
      let urlSplited2 = url.replace(`divisions/in-vitro-diagnostics`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("divisions/medical-devices")) {
      let urlSplited2 = url.replace(`divisions/medical-devices`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("divisions/life-science")) {
      let urlSplited2 = url.replace(`divisions/life-science`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("divisions/veterinary")) {
      let urlSplited2 = url.replace(`divisions/veterinary`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("divisions/clinical-it")) {
      let urlSplited2 = url.replace(`divisions/clinical-it`, "");
      setNewURL(urlSplited2);
    }
    if (url.includes("divisions/industry")) {
      let urlSplited2 = url.replace(`divisions/industry`, "");
      setNewURL(urlSplited2);
    }
  }, []);

  const handleProductSuggestionClicked = (id) => {
    if (url.includes("products?search")) {
      window.location.href = `${productURL}products/product-details?id=${id}&search=${keyword}`; // this works
    } else if (url.includes("product-details?id")) {
      window.location.href = `${detailURL}products/product-details?id=${id}`; // this works
    } else if (url.includes("supplier-detail?sid")) {
      window.location.href = `${suppDetailUrl}products/product-details?id=${id}`; // this works
    } else if (url.includes("products?div")) {
      window.location.href = `${divisionURL}products/product-details?id=${id}`; // this works
    } else if (url.includes("suppliers?div")) {
      window.location.href = `${divisionURL}products/product-details?id=${id}`; // this works
    } else if (url.includes("products?ma")) {
      window.location.href = `${mainAreaURL}products/product-details?id=${id}`; // this works
    } else if (url.includes("suppliers?ma")) {
      window.location.href = `${supplierMainAreaURL}products/product-details?id=${id}`; // this works
    } else if (url.includes("products?sid")) {
      window.location.href = `${suppURL}products/product-details?id=${id}`; // this works
    } else if (url.includes("products?category")) {
      window.location.href = `${categoryURL}products/product-details?id=${id}`; // this works
    } else if (url.includes("search?q")) {
      window.location.href = `${contentURL}products/product-details?id=${id}`; // this dont work
    }

    // these WORK
    else if (url.includes("products")) {
      window.location.href = `${newURL}products/product-details?id=${id}`;
    } else if (url.includes("career")) {
      window.location.href = `${newURL}products/product-details?id=${id}`;
    } else if (url.includes("search")) {
    } else if (url.includes("products?search")) {
      window.location.href = `${newURL}products/product-details?id=${id}`;
    } else if (url.includes("about-us")) {
      window.location.href = `${newURL}products/product-details?id=${id}`;
    } else if (url.includes("home")) {
      window.location.href = `${newURL}products/product-details?id=${id}`;
    } else if (url.includes("impressum")) {
      window.location.href = `${newURL}products/product-details?id=${id}`;
    } else if (url.includes("divisions-page")) {
      window.location.href = `${newURL}products/product-details?id=${id}`;
    } else if (url.includes("services")) {
      window.location.href = `${newURL}products/product-details?id=${id}`;
    } else if (url.includes("news-overview")) {
      window.location.href = `${newURL}products/product-details?id=${id}`;
    } else if (url.includes("downloads")) {
      window.location.href = `${newURL}products/product-details?id=${id}`;
    } else if (url.includes("privacy-statement")) {
      window.location.href = `${newURL}products/product-details?id=${id}`;
    } else if (url.includes("legal-statement")) {
      window.location.href = `${newURL}products/product-details?id=${id}`;
    } else if (url.includes("agb")) {
      window.location.href = `${newURL}products/product-details?id=${id}`;
    } else if (url.includes("suppliers")) {
      window.location.href = `${newURL}products/product-details?id=${id}`;
    } else if (url.includes("cart")) {
      window.location.href = `${newURL}products/product-details?id=${id}`;
    } else if (url.includes("cart-overview")) {
      window.location.href = `${newURL}products/product-details?id=${id}`;
    } else if (url.includes("divisions/in-vitro-diagnostics")) {
      window.location.href = `${newURL}products/product-details?id=${id}`;
    } else if (url.includes("divisions/medical-devices")) {
      window.location.href = `${newURL}products/product-details?id=${id}`;
    } else if (url.includes("divisions/life-science")) {
      window.location.href = `${newURL}products/product-details?id=${id}`;
    } else if (url.includes("divisions/veterinary")) {
      window.location.href = `${newURL}products/product-details?id=${id}`;
    } else if (url.includes("divisions/clinical-it")) {
      window.location.href = `${newURL}products/product-details?id=${id}`;
    } else if (url.includes("divisions/industry")) {
      window.location.href = `${newURL}products/product-details?id=${id}`;
    } else {
      window.location.href = `${url}products/product-details?id=${id}`;
    }
  };

  const handleCategorySuggestionClicked = (id) => {
    if (url.includes("products?search")) {
      window.location.href = `${productURL}products?category=${id}`; // this works
    } else if (url.includes("product-details?id")) {
      window.location.href = `${detailURL}products?category=${id}`; // this works
    } else if (url.includes("supplier-detail?sid")) {
      window.location.href = `${suppDetailUrl}products?category=${id}`; // this works
    } else if (url.includes("products?div")) {
      window.location.href = `${divisionURL}products?category=${id}`; // this works
    } else if (url.includes("suppliers?div")) {
      window.location.href = `${divisionURL}products?category=${id}`; // this works
    } else if (url.includes("products?ma")) {
      window.location.href = `${mainAreaURL}products?category=${id}`; // this works
    } else if (url.includes("suppliers?ma")) {
      window.location.href = `${supplierMainAreaURL}products?category=${id}`; // this works
    } else if (url.includes("products?sid")) {
      window.location.href = `${suppURL}products?category=${id}`; // this works
    } else if (url.includes("products?category")) {
      window.location.href = `${categoryURL}products?category=${id}`; // this works
    } else if (url.includes("search?q")) {
      window.location.href = `${contentURL}products?category=${id}`; // this dont work
    }
    // -- these work
    else if (url.includes("products")) {
      window.location.href = `${newURL}products?category=${id}`;
    } else if (url.includes("career")) {
      window.location.href = `${newURL}products?category=${id}`;
    } else if (url.includes("search")) {
      window.location.href = `${newURL}products?category=${id}`;
    } else if (url.includes("about-us")) {
      window.location.href = `${newURL}products?category=${id}`;
    } else if (url.includes("impressum")) {
      window.location.href = `${newURL}products?category=${id}`;
    } else if (url.includes("home")) {
      window.location.href = `${newURL}products?category=${id}`;
    } else if (url.includes("divisions-page")) {
      window.location.href = `${newURL}products?category=${id}`;
    } else if (url.includes("services")) {
      window.location.href = `${newURL}products?category=${id}`;
    } else if (url.includes("news-overview")) {
      window.location.href = `${newURL}products?category=${id}`;
    } else if (url.includes("downloads")) {
      window.location.href = `${newURL}products?category=${id}`;
    } else if (url.includes("privacy-statement")) {
      window.location.href = `${newURL}products?category=${id}`;
    } else if (url.includes("legal-statement")) {
      window.location.href = `${newURL}products?category=${id}`;
    } else if (url.includes("agb")) {
      window.location.href = `${newURL}products?category=${id}`;
    } else if (url.includes("suppliers")) {
      window.location.href = `${newURL}products?category=${id}`;
    } else if (url.includes("cart")) {
      window.location.href = `${newURL}products?category=${id}`;
    } else if (url.includes("cart-overview")) {
      window.location.href = `${newURL}products?category=${id}`;
    } else if (url.includes("divisions/in-vitro-diagnostics")) {
      window.location.href = `${newURL}products?category=${id}`;
    } else if (url.includes("divisions/medical-devices")) {
      window.location.href = `${newURL}products?category=${id}`;
    } else if (url.includes("divisions/life-science")) {
      window.location.href = `${newURL}products?category=${id}`;
    } else if (url.includes("divisions/veterinary")) {
      window.location.href = `${newURL}products?category=${id}`;
    } else if (url.includes("divisions/clinical-it")) {
      window.location.href = `${newURL}products?category=${id}`;
    } else if (url.includes("divisions/industry")) {
      window.location.href = `${newURL}products?category=${id}`;
    } else {
      window.location.href = `${url}products?category=${id}`;
    }
  };

  const handleSupplierSuggestionClicked = (id) => {
    if (url.includes("products?search")) {
      window.location.href = `${productURL}suppliers/supplier-detail?sid=${id}`; // this works
    } else if (url.includes("product-details?id")) {
      window.location.href = `${detailURL}suppliers/supplier-detail?sid=${id}`; // this works
    } else if (url.includes("products?sid")) {
      window.location.href = `${suppURL}suppliers/supplier-detail?sid=${id}`; // this works
    } else if (url.includes("products?div")) {
      window.location.href = `${divisionURL}suppliers/supplier-detail?sid=${id}`; // this works
    } else if (url.includes("suppliers?div")) {
      window.location.href = `${divisionURL}suppliers/supplier-detail?sid=${id}`; // this works
    } else if (url.includes("products?ma")) {
      window.location.href = `${mainAreaURL}suppliers/supplier-detail?sid=${id}`; // this works
    } else if (url.includes("suppliers?ma")) {
      window.location.href = `${supplierMainAreaURL}suppliers/supplier-detail?sid=${id}`; // this works
    } else if (url.includes("supplier-detail?sid")) {
      window.location.href = `${suppDetailUrl}suppliers/supplier-detail?sid=${id}`; // this works
    } else if (url.includes("products?category")) {
      window.location.href = `${categoryURL}suppliers/supplier-detail?sid=${id}`; // this works
    } else if (url.includes("search?q")) {
      window.location.href = `${contentURL}suppliers/supplier-detail?sid=${id}`; // this dont work
    }
    // -- these work
    else if (url.includes("products")) {
      window.location.href = `${newURL}suppliers/supplier-detail?sid=${id}`;
    } else if (url.includes("search")) {
      window.location.href = `${newURL}suppliers/supplier-detail?sid=${id}`;
    } else if (url.includes("career")) {
      window.location.href = `${newURL}suppliers/supplier-detail?sid=${id}`;
    } else if (url.includes("about-us")) {
      window.location.href = `${newURL}suppliers/supplier-detail?sid=${id}`;
    } else if (url.includes("home")) {
      window.location.href = `${newURL}suppliers/supplier-detail?sid=${id}`;
    } else if (url.includes("impressum")) {
      window.location.href = `${newURL}suppliers/supplier-detail?sid=${id}`;
    } else if (url.includes("divisions-page")) {
      window.location.href = `${newURL}suppliers/supplier-detail?sid=${id}`;
    } else if (url.includes("services")) {
      window.location.href = `${newURL}suppliers/supplier-detail?sid=${id}`;
    } else if (url.includes("news-overview")) {
      window.location.href = `${newURL}suppliers/supplier-detail?sid=${id}`;
    } else if (url.includes("downloads")) {
      window.location.href = `${newURL}suppliers/supplier-detail?sid=${id}`;
    } else if (url.includes("privacy-statement")) {
      window.location.href = `${newURL}suppliers/supplier-detail?sid=${id}`;
    } else if (url.includes("legal-statement")) {
      window.location.href = `${newURL}suppliers/supplier-detail?sid=${id}`;
    } else if (url.includes("agb")) {
      window.location.href = `${newURL}suppliers/supplier-detail?sid=${id}`;
    } else if (url.includes("suppliers")) {
      window.location.href = `${newURL}suppliers/supplier-detail?sid=${id}`;
    } else if (url.includes("cart")) {
      window.location.href = `${newURL}suppliers/supplier-detail?sid=${id}`;
    } else if (url.includes("cart-overview")) {
      window.location.href = `${newURL}suppliers/supplier-detail?sid=${id}`;
    } else if (url.includes("divisions/in-vitro-diagnostics")) {
      window.location.href = `${newURL}suppliers/supplier-detail?sid=${id}`;
    } else if (url.includes("divisions/medical-devices")) {
      window.location.href = `${newURL}suppliers/supplier-detail?sid=${id}`;
    } else if (url.includes("divisions/life-science")) {
      window.location.href = `${newURL}suppliers/supplier-detail?sid=${id}`;
    } else if (url.includes("divisions/veterinary")) {
      window.location.href = `${newURL}suppliers/supplier-detail?sid=${id}`;
    } else if (url.includes("divisions/clinical-it")) {
      window.location.href = `${newURL}suppliers/supplier-detail?sid=${id}`;
    } else if (url.includes("divisions/industry")) {
      window.location.href = `${newURL}suppliers/supplier-detail?sid=${id}`;
    } else {
      window.location.href = `${url}suppliers/supplier-detail?sid=${id}`;
    }
  };

  const handleProductsSearch = () => {
    if (url.includes("products?search")) {
      window.location.href = `${productURL}products?search=${keyword}`; // this works
    } else if (url.includes("product-details?id")) {
      window.location.href = `${detailURL}products?search=${keyword}`; // this works
    } else if (url.includes("supplier-detail?sid")) {
      window.location.href = `${suppDetailUrl}products?search=${keyword}`; // this works
    } else if (url.includes("products?div")) {
      window.location.href = `${divisionURL}products?search=${keyword}`; // this works
    } else if (url.includes("suppliers?div")) {
      window.location.href = `${divisionURL}products?search=${keyword}`; // this works
    } else if (url.includes("products?ma")) {
      window.location.href = `${mainAreaURL}products?search=${keyword}`; // this works
    } else if (url.includes("suppliers?ma")) {
      window.location.href = `${supplierMainAreaURL}products?search=${keyword}`; // this works
    } else if (url.includes("products?sid")) {
      window.location.href = `${suppURL}products?search=${keyword}`; // this works
    } else if (url.includes("products?category")) {
      window.location.href = `${categoryURL}products?search=${keyword}`; // this works
    } else if (url.includes("search?q")) {
      window.location.href = `${contentURL}products?search=${keyword}`; // this dont work
    }
    //--  these works
    else if (url.includes("products")) {
      window.location.href = `${newURL}products?search=${keyword}`;
    } else if (url.includes("search")) {
      window.location.href = `${newURL}products?search=${keyword}`;
    } else if (url.includes("career")) {
      window.location.href = `${newURL}products?search=${keyword}`;
    } else if (url.includes("impressum")) {
      window.location.href = `${newURL}products?search=${keyword}`;
    } else if (url.includes("about-us")) {
      window.location.href = `${newURL}products?search=${keyword}`;
    } else if (url.includes("divisions-page")) {
      window.location.href = `${newURL}products?search=${keyword}`;
    } else if (url.includes("services")) {
      window.location.href = `${newURL}products?search=${keyword}`;
    } else if (url.includes("news-overview")) {
      window.location.href = `${newURL}products?search=${keyword}`;
    } else if (url.includes("downloads")) {
      window.location.href = `${newURL}products?search=${keyword}`;
    } else if (url.includes("home")) {
      window.location.href = `${newURL}products?search=${keyword}`;
    } else if (url.includes("privacy-statement")) {
      window.location.href = `${newURL}products?search=${keyword}`;
    } else if (url.includes("legal-statement")) {
      window.location.href = `${newURL}products?search=${keyword}`;
    } else if (url.includes("agb")) {
      window.location.href = `${newURL}products?search=${keyword}`;
    } else if (url.includes("suppliers")) {
      window.location.href = `${newURL}products?search=${keyword}`;
    } else if (url.includes("cart")) {
      window.location.href = `${newURL}products?search=${keyword}`;
    } else if (url.includes("cart-overview")) {
      window.location.href = `${newURL}products?search=${keyword}`;
    } else if (url.includes("divisions/in-vitro-diagnostics")) {
      window.location.href = `${newURL}products?search=${keyword}`;
    } else if (url.includes("divisions/medical-devices")) {
      window.location.href = `${newURL}products?search=${keyword}`;
    } else if (url.includes("divisions/life-science")) {
      window.location.href = `${newURL}products?search=${keyword}`;
    } else if (url.includes("divisions/veterinary")) {
      window.location.href = `${newURL}products?search=${keyword}`;
    } else if (url.includes("divisions/clinical-it")) {
      window.location.href = `${newURL}products?search=${keyword}`;
    } else if (url.includes("divisions/industry")) {
      window.location.href = `${newURL}products?search=${keyword}`;
    } else {
      window.location.href = `${url}products?search=${keyword}`;
    }
  };

  const handleContentsSearch = () => {
    dispatch(getContents(keyword));
    if (url.includes("search?q")) {
      window.location.href = `${contentURL}search?q=${keyword}`; // this dont work
    } else if (url.includes("product-details?id")) {
      window.location.href = `${detailURL}search?q=${keyword}`; // this works
    } else if (url.includes("supplier-detail?sid")) {
      window.location.href = `${suppDetailUrl}search?q=${keyword}`; // this works
    } else if (url.includes("products?div")) {
      window.location.href = `${divisionURL}search?q=${keyword}`; // this works
    } else if (url.includes("suppliers?div")) {
      window.location.href = `${divisionURL}search?q=${keyword}`; // this works
    } else if (url.includes("products?ma")) {
      window.location.href = `${mainAreaURL}search?q=${keyword}`; // this works
    } else if (url.includes("suppliers?ma")) {
      window.location.href = `${supplierMainAreaURL}search?q=${keyword}`; // this works
    } else if (url.includes("products?sid")) {
      window.location.href = `${suppURL}search?q=${keyword}`; // this works
    } else if (url.includes("products?category")) {
      window.location.href = `${categoryURL}search?q=${keyword}`; // this works
    } else if (url.includes("products?search")) {
      window.location.href = `${productURL}search?q=${keyword}`; // this works
    }
    //-- All Working
    else if (url.includes("products")) {
      window.location.href = `${newURL}search?q=${keyword}`;
    } else if (url.includes("career")) {
      window.location.href = `${newURL}search?q=${keyword}`;
    } else if (url.includes("search")) {
    } else if (url.includes("about-us")) {
      window.location.href = `${newURL}search?q=${keyword}`;
    } else if (url.includes("divisions-page")) {
      window.location.href = `${newURL}search?q=${keyword}`;
    } else if (url.includes("impressum")) {
      window.location.href = `${newURL}search?q=${keyword}`;
    } else if (url.includes("services")) {
      window.location.href = `${newURL}search?q=${keyword}`;
    } else if (url.includes("home")) {
      window.location.href = `${newURL}search?q=${keyword}`;
    } else if (url.includes("news-overview")) {
      window.location.href = `${newURL}search?q=${keyword}`;
    } else if (url.includes("downloads")) {
      window.location.href = `${newURL}search?q=${keyword}`;
    } else if (url.includes("privacy-statement")) {
      window.location.href = `${newURL}search?q=${keyword}`;
    } else if (url.includes("legal-statement")) {
      window.location.href = `${newURL}search?q=${keyword}`;
    } else if (url.includes("agb")) {
      window.location.href = `${newURL}search?q=${keyword}`;
    } else if (url.includes("suppliers")) {
      window.location.href = `${newURL}search?q=${keyword}`;
    } else if (url.includes("cart")) {
      window.location.href = `${newURL}search?q=${keyword}`;
    } else if (url.includes("cart-overview")) {
      window.location.href = `${newURL}search?q=${keyword}`;
    } else if (url.includes("divisions/in-vitro-diagnostics")) {
      window.location.href = `${newURL}search?q=${keyword}`;
    } else if (url.includes("divisions/medical-devices")) {
      window.location.href = `${newURL}search?q=${keyword}`;
    } else if (url.includes("divisions/life-science")) {
      window.location.href = `${newURL}search?q=${keyword}`;
    } else if (url.includes("divisions/veterinary")) {
      window.location.href = `${newURL}search?q=${keyword}`;
    } else if (url.includes("divisions/clinical-it")) {
      window.location.href = `${newURL}search?q=${keyword}`;
    } else if (url.includes("divisions/industry")) {
      window.location.href = `${newURL}search?q=${keyword}`;
    } else {
      window.location.href = `${url}search?q=${keyword}`;
    }
  };

  const handleDebounceChange = (e) => {
    if (chkbox === 0) {
      setKeyword(e.target.value);
    }
    const currLng = getCurrentLanguage();
    if (chkbox === 1) {
      setKeyword(e.target.value);
      // dispatch(getProductsByFilters("", e.target.value, "", (page - 1) * 10));
      dispatch(getCategorySuggestions(e.target.value));
      dispatch(
        getSuppliers("", e.target.value, "HR", (page - 1) * 10, currLng)
      );
      setIsShow(true);
    }
  };

  const handleSearch = (e) => {
    if (keyword.length >= 3) {
      dispatch(searchKeyword(keyword));
      if (chkbox === 0) {
        handleContentsSearch();
      }
      if (chkbox === 1 && keyword.length >= 3) {
        dispatch(
          getProductsByFilters("", e.target.value, "HR", (page - 1) * 10)
        );
        handleProductsSearch();
      }
    }
  };

  const renderAutocomplete = () => {
    if (isShow && keyword) {
      return (
        <div ref={ref}>
          <ul className="autocomplete">
            {productSuggestions.length === 0 &&
            categorySuggestionsNew.length === 0 &&
            suppliersState.length === 0 ? (
              <p id="no_search_result">
                Žao nam je, ali ništa se ne podudara s vašim pojmovima za
                pretragu. Molimo pokušajte ponovo s drugim ključnim riječima.
              </p>
            ) : null}
            {productSuggestions.length > 0 ? (
              <>
                <p className="suggestion_title">Proizvode</p>
                {productSuggestions?.slice(0, 5)?.map((item, index) => {
                  return (
                    <li
                      key={item.documentId}
                      onClick={() =>
                        handleProductSuggestionClicked(item.documentId)
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 15,
                        }}
                      >
                        <div>
                          {item?.productName}{" "}
                          <span className="search_size">/ {item?.size}</span>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </>
            ) : null}

            {categorySuggestionsNew.length > 0 ? (
              <>
                <p className="suggestion_title">Product Group</p>
                {categorySuggestionsNew?.slice(0, 5)?.map((item) => (
                  <li
                    key={item.id}
                    onClick={() => handleCategorySuggestionClicked(item.id)}
                  >
                    {item.name}
                  </li>
                ))}
              </>
            ) : null}

            {suppliersState.length > 0 ? (
              <>
                <p className="suggestion_title">Dobavljače</p>
                {suppliersState?.slice(0, 5)?.map((item) => {
                  return (
                    <li
                      key={item.id}
                      onClick={() => handleSupplierSuggestionClicked(item.o_id)}
                    >
                      {item.SupplierName}
                    </li>
                  );
                })}
              </>
            ) : null}
          </ul>
        </div>
      );
    }
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSearch();
    }
  };

  return (
    <div className="search-container-home">
      <div className="inputsearch">
        <div className="search-button">
          <div className="dropdown" ref={searchDropdown}>
            <div className="dropdown-header" onClick={toggleDropdown}>
              {chkbox === 1 ? "Proizvode" : "Sadržaj"}
              <IoIosArrowDown />
            </div>
            <div className={`dropdown-body ${isOpen && "open"}`}>
              <div className="dropdown-item-container">
                <div className="dropdown-item" onClick={() => handleChange(0)}>
                  <input id="radio1" type="radio" checked={chkbox === 0} />{" "}
                  Sadržaj
                </div>
                <div className="dropdown-item" onClick={() => handleChange(1)}>
                  <input id="radio2" type="radio" checked={chkbox === 1} />{" "}
                  Proizvode
                </div>
              </div>
            </div>
          </div>

          <DebounceInput
            type="text"
            name="text"
            placeholder={
              chkbox === 0
                ? "Pretražite sadržaj ovdje..."
                : "Pretražite proizvode ovdje..."
            }
            value={keyword}
            onChange={(e) => handleDebounceChange(e)}
            onKeyDown={onKeyDown}
            minLength={3}
          />
          {chkbox === 0 && null}
          {chkbox === 1 && renderAutocomplete()}
          <button className="search-icon" onClick={handleSearch}>
            <SearchIcon color="secondary" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default CroatiaSearch;
