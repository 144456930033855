import "./card-product-details-description&contact.scss";
import CustomAccordian from "../accordian/CustomAccordian";

function CardDescriptionContact({ product }) {
  const openLinkInNewTab = (url) => {
    const newTab = window.open(url, "_blank", "noopener,noreferrer");
    if (newTab) newTab.opener = null;
  };

  const CustomDesc = ({ span, text, datasheet, citation }) => {
    // Function to check if the URL starts with 'https://'
    const isValidUrl = (url) => {
      return url.startsWith("https://");
    };

    // Function to open link in a new tab
    const openLinkInNewTab = (url) => {
      window.open(url, "_blank");
    };

    return (
      <>
        {text || citation || datasheet ? (
          <div className="custom_desc">
            {span && <span className="custom_desc_span">{span}</span>}
            {datasheet && (
              <>
                {citation && <p className="custom_desc_p">{citation}</p>}
                {/* Check if datasheet is a valid URL */}
                {isValidUrl(datasheet) ? (
                  <button
                    onClick={() => openLinkInNewTab(datasheet)}
                    className={
                      citation
                        ? "custom_desc_href custom_desc_href_citation"
                        : "custom_desc_href"
                    }
                  >
                    {datasheet}
                  </button>
                ) : (
                  // If datasheet is not a valid URL, prepend 'https://' before rendering the button
                  <button
                    onClick={() => openLinkInNewTab(`https://${datasheet}`)}
                    className={
                      citation
                        ? "custom_desc_href custom_desc_href_citation"
                        : "custom_desc_href"
                    }
                  >
                    {datasheet}
                  </button>
                )}
              </>
            )}
            {text && <p className="custom_desc_p">{text}</p>}
          </div>
        ) : null}
      </>
    );
  };

  // let newProductsObj = [];
  // newProductsObj.push({
  //   ...product,
  //   disease: product?.disease ? product?.disease : null,
  //   concentration: product?.concentration ? product?.concentration : null,
  // });
  // console.log("productAccoridnas", newProductsObj[0]);

  let citation = product?.citation?.split("Citation URL:");
  console.log("citation", citation?.[0]);
  let citationLink = citation?.[1]?.replace(" ", "%20");
  let citationNoSpace = citationLink?.replace(/^%20+/, "");
  console.log("citationNoSpace", citationNoSpace);

  const details = [
    {
      id: "panel1",
      titleIcon: <div className="descr"></div>,
      title: "Summary",
      show:
        product?.applicationsFull || product?.longDescription ? "" : "hidden",
      component: (
        <>
          <CustomDesc span="Applications" text={product?.applicationsFull} />
          <CustomDesc span="Description" text={product?.longDescription} />
          <CustomDesc
            span="Shipping Temperature"
            text={product?.shippingTemperature}
          />
          <CustomDesc
            span="Storage Conditions"
            text={product?.storageConditions}
          />
        </>
      ),
    },
    {
      id: "panel2",
      titleIcon: <div className="properties"></div>,
      title: "Details",
      show:
        product?.concentration ||
        product?.disease ||
        product?.researchArea ||
        product?.backgroundInfo ||
        product?.citation ||
        product?.geneProteinInfo ||
        product?.geneProteinAliases
          ? ""
          : "hidden",
      component: (
        <>
          <CustomDesc span="Concentration" text={product?.concentration} />
          <CustomDesc span="Disease" text={product?.disease} />
          <CustomDesc span="Research Area" text={product?.researchArea} />
          <CustomDesc span="Background Info" text={product?.backgroundInfo} />
          <CustomDesc
            span="Citation"
            // text={" "}
            citation={citation?.[0]}
            datasheet={citationNoSpace}
          />
          <CustomDesc
            span="Gene Protein Info"
            text={product?.geneProteinInfo}
          />
          <CustomDesc
            span="Gene Protein Aliases"
            text={product?.geneProteinAliases}
          />
        </>
      ),
    },
    {
      id: "panel3",
      titleIcon: <div className="properties"></div>,
      title: "Product Specification",
      show:
        product?.conjugate ||
        product?.purity ||
        product?.reactivityDetails ||
        product?.assayRange ||
        product?.sampleType ||
        product?.sensitivity ||
        product?.mainAssayGroup ||
        product?.hostSource
          ? ""
          : "hidden",
      component: (
        <>
          <CustomDesc span="Conjugate" text={product?.conjugate} />
          <CustomDesc span="Purity" text={product?.purity} />
          <CustomDesc span="Host Source" text={product?.hostSource} />
          <CustomDesc
            span="Reactivity Details"
            text={product?.reactivityDetails}
          />
          <CustomDesc span="Assay Range" text={product?.assayRange} />
          <CustomDesc span="Sample Type" text={product?.sampleType} />
          <CustomDesc span="Sensitivity" text={product?.sensitivity} />
          <CustomDesc span="Main Assay Group" text={product?.mainAssayGroup} />
        </>
      ),
    },
    {
      id: "panel4",
      titleIcon: <div className="properties"></div>,
      title: "Product Data",
      show:
        product?.productDatasheetUrl ||
        product?.productProtocolUrl ||
        product?.productSdsMsdsUrl
          ? ""
          : "hidden",
      component: (
        <>
          <CustomDesc
            span="Datasheet Url"
            text={" "}
            datasheet={product?.productDatasheetUrl}
          />
          <CustomDesc
            span="Protocol Url"
            text={" "}
            datasheet={product?.productProtocolUrl}
          />
          <CustomDesc
            span="Sds Msds Url"
            text={" "}
            datasheet={product?.productSdsMsdsUrl}
          />
        </>
      ),
    },
    {
      id: "panel5",
      titleIcon: <div className="images"></div>,
      title: "Datasheets",
      show: product?.datasheetUrl ? "" : "hidden",
      component: (
        <>
          <CustomDesc
            span="Datasheet"
            text={" "}
            datasheet={product?.datasheetUrl}
          />
        </>
      ),
    },
  ];

  return (
    <div className="card-product-details-description_contact row">
      <div className="col-12 col-md-12 col-lg-8 col-xl-9">
        <div className="card-product-details-description_contact-left">
          <CustomAccordian data={details} />
        </div>
      </div>
    </div>
  );
}

export default CardDescriptionContact;
