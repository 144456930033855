import i18n from "i18next";

import { initReactI18next } from "react-i18next";
import {
  EN,
  DE,
  BIH,
  BG,
  SR,
  HR,
  CZ,
  MK,
  HU,
  PL,
  RO,
  SI,
  SL,
} from "./translations";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: {
    translation: EN,
  },
  de: {
    translation: DE,
  },
  bih: {
    translation: BIH,
  },
  bg: {
    translation: BG,
  },
  rs: {
    translation: SR,
  },
  hr: {
    translation: HR,
  },
  cz: {
    translation: CZ,
  },
  hu: {
    translation: HU,
  },
  mk: {
    translation: MK,
  },
  pl: {
    translation: PL,
  },
  ro: {
    translation: RO,
  },
  si: {
    translation: SI,
  },
  sl: {
    translation: SL,
  },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
