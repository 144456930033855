import { filterConstans } from "../constants";

const initialState = {
  fullSelectedList: [],
  selectedList: [],
  divisions: [],
  category: [],
  area: [],
  filterAntigenSpecies: [],
  filterApplication: [],
  filterConjugate: [],
  filterHost: [],
  filterReactivity: [],
  supplier: [],
  promotion: [],
  subCategory: [],
  products: [],
  countriesAvailable: [],
  loading: true,
  totalCount: 0,
};

export const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case filterConstans.FULL_SELECT_FILTER:
      const checkItems = state.fullSelectedList.filter(
        (item) => item.id !== action.payload.id
      );

      return {
        ...state,
        fullSelectedList: [...checkItems, action.payload],
      };

    case filterConstans.FULL_UNSELECT_FILTER:
      const unCheckItems = state.fullSelectedList.filter(
        (item) => item !== action.payload
      );
      return {
        ...state,
        fullSelectedList: unCheckItems,
      };

    case filterConstans.FULL_UNSELECT_FILTERID:
      const fullUnCheckItems = state.fullSelectedList.filter(
        (item) => item.id !== action.payload
      );
      return {
        ...state,
        fullSelectedList: fullUnCheckItems,
      };

    case filterConstans.SELECT_FILTER:
      const fullCheckItems = state.selectedList.filter(
        (item) => item !== action.payload
      );
      return {
        ...state,
        selectedList: [...fullCheckItems, action.payload],
      };

    case filterConstans.UNSELECT_FILTER:
      const UnCheckItems = state.selectedList.filter(
        (item) => item !== action.payload
      );
      return {
        ...state,
        selectedList: UnCheckItems,
      };

    case filterConstans.REMOVE_ALL_FILTERS:
      return {
        ...state,
        selectedList: [],
        fullSelectedList: [],
      };

    case filterConstans.GET_PRODUCTS_BY_FILTERS_SUCCESS:
      let areaUpdated = [];
      action?.payload?.areas?.map((area) => {
        areaUpdated.push(area);
      });

      let categoriesUpdated = [];
      action?.payload?.categories?.map((category) => {
        categoriesUpdated.push(category);
      });

      let divisionsUpdated = [];
      action?.payload?.divisions?.map((division) => {
        divisionsUpdated.push(division);
      });

      let promotionUpdated = [];
      action?.payload?.promotions?.map((prom) => {
        promotionUpdated.push(prom);
      });

      let productSubCategoryUpdated = [];
      action?.payload?.productSubCategories?.map((sub) => {
        productSubCategoryUpdated.push(sub);
      });

      let filterAntigenSpeciesUpdated = [];
      action?.payload?.antigenSpecies?.map((antigen) => {
        filterAntigenSpeciesUpdated.push(antigen);
      });

      let filterApplicationUpdated = [];
      action?.payload?.applications?.map((filterApp) => {
        filterApplicationUpdated.push(filterApp);
      });

      let filterConjugateUpdated = [];
      action?.payload?.conjugates?.map((conjugate) => {
        filterConjugateUpdated.push(conjugate);
      });

      let filterHostUpdated = [];
      action?.payload?.hosts?.map((host) => {
        filterHostUpdated.push(host);
      });

      let filterReactivityUpdated = [];
      action?.payload?.reactivities?.map((reactivity) => {
        filterReactivityUpdated.push(reactivity);
      });

      let supplierUpdated = [];
      action?.payload?.suppliers?.map((supp) => {
        supplierUpdated.push(supp);
      });

      let productsUpdated = [];
      let contryTypes = [];
      action?.payload?.products.map((prod) => {
        productsUpdated.push(prod);
        contryTypes.push(prod.countriesAvailable);
      });

      return {
        ...state,
        area: areaUpdated,
        category: categoriesUpdated,
        divisions: divisionsUpdated,
        filterAntigenSpecies: filterAntigenSpeciesUpdated,
        filterApplication: filterApplicationUpdated,
        filterConjugate: filterConjugateUpdated,
        filterHost: filterHostUpdated,
        filterReactivity: filterReactivityUpdated,
        supplier: supplierUpdated,
        products: productsUpdated,
        promotion: promotionUpdated,
        subCategory: productSubCategoryUpdated,
        loading: false,
        totalCount: action?.payload?.totalHits,
        countriesAvailable: contryTypes,
      };

    default:
      return state;
  }
};
