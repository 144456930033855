import React, { useState, useEffect } from "react";
import "./categories-selected.scss";
import ClearIcon from "@material-ui/icons/Clear";
import { useSelector, useDispatch } from "react-redux";
import {
  unselectList,
  removeallselected,
  fullUnselectedListId,
} from "../../redux/actions/filterAction";
import SearchBar from "../SearchFilter/SearchBar";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Index() {
  const { t } = useTranslation();
  const selectedList = useSelector((state) => state.filter.selectedList);
  const dispatch = useDispatch();
  const totalCount = useSelector((state) => state.filter.totalCount);
  const page = useSelector((state) => state.page.page);
  const fullSelectedList = useSelector(
    (state) => state.filter.fullSelectedList
  );

  const newProductState = useSelector((state) => state.filter.products);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const searchKeyword1 = query.get("search");
  const categoryId = query.get("category");
  const supplierId = query.get("sid");
  const divisionId = query.get("div");

  function resultProd() {
    if (totalCount < 10) {
      return totalCount;
    } else {
      return page * 10;
    }
  }
  const areaStorage = localStorage.getItem("AreaID");
  const categoryStorage = localStorage.getItem("CategoryID");
  const divisionStorage = localStorage.getItem("DivisionID");
  const supplierStorage = localStorage.getItem("SupplierID");
  const promotionStorage = localStorage.getItem("PromotionID");
  const subCategoryStorage = localStorage.getItem("SubCategoryID");
  const antigenStorage = localStorage.getItem("AntigenID");
  const applicationStorage = localStorage.getItem("ApplicationID");
  const conjugateStorage = localStorage.getItem("ConjugateID");
  const hostStorage = localStorage.getItem("HostID");
  const reactivityStorage = localStorage.getItem("ReactivityID");

  const DivisionFilter = JSON.parse(localStorage.getItem("DivisionFilter"));
  const AreaFilter = JSON.parse(localStorage.getItem("AreaFilter"));
  const CategoryFilter = JSON.parse(localStorage.getItem("CategoryFilter"));
  const SupplierFilter = JSON.parse(localStorage.getItem("SupplierFilter"));
  const PromotionFilter = JSON.parse(localStorage.getItem("PromotionFilter"));
  const SubCategoryFilter = JSON.parse(
    localStorage.getItem("SubCategoryFilter")
  );
  const AntigenFilter = JSON.parse(localStorage.getItem("AntigenFilter"));
  const ApplicationFilter = JSON.parse(
    localStorage.getItem("ApplicationFilter")
  );
  const ConjugateFilter = JSON.parse(localStorage.getItem("ConjugateFilter"));
  const HostFilter = JSON.parse(localStorage.getItem("HostFilter"));
  const ReactivityFilter = JSON.parse(localStorage.getItem("ReactivityFilter"));

  const removeFitlerFromSelctedAndFull = (item) => {
    dispatch(fullUnselectedListId(item));
    dispatch(unselectList(item));
    if (divisionStorage == item) localStorage.removeItem("DivisionID");
    if (DivisionFilter?.id == item) localStorage.removeItem("DivisionFilter");

    if (areaStorage == item) localStorage.removeItem("AreaID");
    if (AreaFilter?.id == item) localStorage.removeItem("AreaFilter");

    if (categoryStorage == item) localStorage.removeItem("CategoryID");
    if (CategoryFilter?.id == item) localStorage.removeItem("CategoryFilter");

    if (supplierStorage == item) localStorage.removeItem("SupplierID");
    if (SupplierFilter?.id == item) localStorage.removeItem("SupplierFilter");

    if (promotionStorage == item) localStorage.removeItem("PromotionID");
    if (PromotionFilter?.id == item) localStorage.removeItem("PromotionFilter");

    if (subCategoryStorage == item) localStorage.removeItem("SubCategoryID");
    if (SubCategoryFilter?.id == item)
      localStorage.removeItem("SubCategoryFilter");

    if (antigenStorage == item) localStorage.removeItem("AntigenID");
    if (AntigenFilter?.id == item) localStorage.removeItem("AntigenFilter");

    if (applicationStorage == item) localStorage.removeItem("ApplicationID");
    if (ApplicationFilter?.id == item)
      localStorage.removeItem("ApplicationFilter");

    if (conjugateStorage == item) localStorage.removeItem("ConjugateID");
    if (ConjugateFilter?.id == item) localStorage.removeItem("ConjugateFilter");

    if (hostStorage == item) localStorage.removeItem("HostID");
    if (HostFilter?.id == item) localStorage.removeItem("HostFilter");

    if (reactivityStorage == item) localStorage.removeItem("ReactivityID");
    if (ReactivityFilter?.id == item)
      localStorage.removeItem("ReactivityFilter");

    if (categoryId || supplierId || divisionId) {
      window.location.replace(`http://localhost:3001/products`);
    }
  };

  const countrySelected = window.location.pathname.split("/");
  const countrySplitted = countrySelected[1];
  const [country, setCountry] = useState("Austria");

  useEffect(() => {
    if (countrySplitted === "al") {
      setCountry("Albania");
    } else if (countrySplitted === "bih") {
      setCountry("Bosna I Hercegovina");
    } else if (countrySplitted === "bg") {
      setCountry("България");
    } else if (countrySplitted === "hr") {
      setCountry("Hrvatska");
    } else if (countrySplitted === "cz") {
      setCountry("Česká Republika");
    } else if (countrySplitted === "hu") {
      setCountry("Magyarország");
    } else if (countrySplitted === "xs") {
      setCountry("Kosovo");
    } else if (countrySplitted === "mk") {
      setCountry("Северна Македонија");
    } else if (countrySplitted === "me") {
      setCountry("Montenegro");
    } else if (countrySplitted === "pl") {
      setCountry("Polska");
    } else if (countrySplitted === "ro") {
      setCountry("România");
    } else if (countrySplitted === "rs") {
      setCountry("Srbija");
    } else if (countrySplitted === "sl") {
      setCountry("Slovensko");
    } else if (countrySplitted === "sk") {
      setCountry("Slovensko");
    } else if (countrySplitted === "si") {
      setCountry("Slovenija");
    } else if (countrySplitted === "ch") {
      setCountry("schweizerischen");
    } else {
      setCountry("österreichischen");
    }
  }, [country]);

  const lang = localStorage.getItem("i18nextLng");

  const clearAllFilters = () => {
    dispatch(removeallselected());
    if (categoryId || supplierId || divisionId) {
      window.location.replace(`http://localhost:3001/products`);
    }
    localStorage.removeItem("DivisionID");
    localStorage.removeItem("DivisionFilter");

    localStorage.removeItem("AreaID");
    localStorage.removeItem("AreaFilter");

    localStorage.removeItem("CategoryID");
    localStorage.removeItem("CategoryFilter");

    localStorage.removeItem("SupplierID");
    localStorage.removeItem("SupplierFilter");

    localStorage.removeItem("PromotionID");
    localStorage.removeItem("PromotionFilter");

    localStorage.removeItem("SubCategoryID");
    localStorage.removeItem("SubCategoryFilter");

    localStorage.removeItem("AntigenID");
    localStorage.removeItem("AntigenFilter");

    localStorage.removeItem("ApplicationID");
    localStorage.removeItem("ApplicationFilter");

    localStorage.removeItem("ConjugateID");
    localStorage.removeItem("ConjugateFilter");

    localStorage.removeItem("HostID");
    localStorage.removeItem("HostFilter");

    localStorage.removeItem("ReactivityID");
    localStorage.removeItem("ReactivityFilter");
  };

  return (
    <div className="container">
      <div className="col-8">
        <SearchBar />
      </div>
      <div className="row">
        <div className="col-12 container__selected__categories">
          {searchKeyword1 ? (
            <p id="keyword">
              {t("You_searched_for")}:{" "}
              <span
                style={{
                  color: "#0bb3a2",
                }}
              >
                {searchKeyword1}
              </span>
            </p>
          ) : null}
          {newProductState.length > 0 ? (
            <p>
              {lang === "de" ? "" : t("Showing")}{" "}
              <strong>
                {page * 10 - 9} - {resultProd()}
              </strong>{" "}
              {t("products")} {t("from")}
              <strong> {totalCount}</strong>
            </p>
          ) : (
            <>
              <p>
                {lang === "de" ? "" : t("Showing")} <strong>0</strong>{" "}
                {t("Products")}
              </p>
            </>
          )}

          {totalCount > 10000 ? <p className="refine">{t("refine")}</p> : null}
          <p className="refine">
            {t("Displaying_Products_for")} {country} {t("Market")}
          </p>
          {selectedList?.length > 0 ? (
            <div className="selected__categories">
              <p id="selected_filters"> {t("selected_filters")}:</p>
              {selectedList?.map((item) => (
                <div
                  className="selected__card"
                  onClick={() => removeFitlerFromSelctedAndFull(item)}
                >
                  <span style={{ marginRight: "4px" }}>
                    <ClearIcon
                      // onClick={() => dispatch(unselectList(item))}
                      style={{ fontSize: 12, marginTop: 5 }}
                    />
                  </span>
                  {fullSelectedList.map((selected) => {
                    if (selected.id === item) {
                      return <p className="seleceted_name">{selected.name}</p>;
                    }
                  })}
                </div>
              ))}

              {selectedList?.length > 1 ? (
                <div
                  className="selected__card__clear__all"
                  onClick={() => clearAllFilters()}
                >
                  <p onClick={() => clearAllFilters()}>
                    {t("Clear_All_Filters")}
                  </p>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Index;
