import { filterConstans } from "../constants/index";
import {
  addItemToCart,
  removeItemFromCart,
  addItemToCartFromList,
  addWithQuantity,
} from "../utils/cartUtils";

const INITIAL_STATE = {
  hidden: true,
  cartItems: [],
};

export const cartReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case filterConstans.TOGGLE_CART_HIDDEN:
      return {
        ...state,
        hidden: !state.hidden,
      };
    case filterConstans.ADD_ITEM:
      return {
        ...state,
        cartItems: addItemToCart(state.cartItems, action.payload),
      };
    case filterConstans.ADD_ITEM_TO_CART_FROM_LIST:
      return {
        ...state,
        cartItems: addItemToCartFromList(state.cartItems, action.payload),
      };
    case filterConstans.REMOVE_ITEM:
      return {
        ...state,
        cartItems: removeItemFromCart(state.cartItems, action.payload),
      };
    case filterConstans.CLEAR_ITEM_FROM_CART:
      return {
        ...state,
        cartItems: state.cartItems.filter(
          (cartItem) => cartItem.documentId !== action.payload.documentId
        ),
      };
    case filterConstans.ADD_WITH_QUANTITY:
      return {
        ...state,
        cartItems: addWithQuantity(
          state.cartItems,
          action.payload[0],
          action.payload[1]
        ),
      };
    case filterConstans.CLEAR_CART:
      return {
        ...state,
        cartItems: [],
      };
    default:
      return state;
  }
};
