import React, { useState, useEffect } from "react";
import CardProduct from "../card-product/Index";
import "./card-list-product.scss";
import { useSelector } from "react-redux";
import NoProducts from "../../assets/no-products.png";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Index = () => {
  const { t } = useTranslation();
  let url = window.location.href;
  const loadingState = useSelector((state) => state.filter.loading);
  const newProductState = useSelector((state) => state.filter.products);
  const noProductMsg =
    "Sorry, but nothing matched your search terms. Please try again with some different keywords or click on any of the buttons below.";

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const search = query.get("search");
  const categoryId = query.get("category");
  const supplierId = query.get("sid");
  const divisionId = query.get("div");
  const mainAreaId = query.get("ma");
  const [URL, setURL] = useState();
  const [productURL, setProductURL] = useState();
  const [suppURL, setSuppURL] = useState();
  const [categoryURL, setCategoryURL] = useState();
  const [divisionURL, setDivisionURL] = useState();
  const [mainAreaURL, setMainAreaURL] = useState();

  useEffect(() => {
    if (url.includes("products?search")) {
      let urlSplited2 = url.replace(`products?search=${search}`, "");
      setProductURL(urlSplited2);
    }
    if (url.includes("products?sid")) {
      let urlSplited2 = url.replace(`products?sid=${supplierId}`, "");
      setSuppURL(urlSplited2);
    }

    if (url.includes("products?category")) {
      let urlSplited2 = url.replace(`products?category=${categoryId}`, "");
      setCategoryURL(urlSplited2);
    }

    if (url.includes("products?div")) {
      let urlSplited2 = url.replace(`products?div=${divisionId}`, "");
      setDivisionURL(urlSplited2);
    }

    if (url.includes("products?ma")) {
      let urlSplited2 = url.replace(`products?ma=${mainAreaId}`, "");
      setMainAreaURL(urlSplited2);
    }
    if (url.includes("products")) {
      let urlSplited2 = url.replace(`products`, "");
      setURL(urlSplited2);
    }
  }, []);

  const backToProducts = () => {
    if (url.includes("products?search")) {
      window.location.href = `${productURL}products`; // this works
    } else if (url.includes("products?sid")) {
      window.location.href = `${suppURL}products`; // this works
    } else if (url.includes("products?category")) {
      window.location.href = `${categoryURL}products`; // this works
    } else if (url.includes("products?div")) {
      window.location.href = `${divisionURL}products`; // this works
    } else if (url.includes("products?ma")) {
      window.location.href = `${mainAreaURL}products`; // this works
    } else if (url.includes("products")) {
      window.location.href = `${URL}products`;
    } else {
      window.location.href = `${url}products`;
    }

    //Fixed Bugs
  };

  const redirectToSuppliers = () => {
    if (url.includes("products?search")) {
      window.location.href = `${productURL}suppliers`; // this works
    } else if (url.includes("products?sid")) {
      window.location.href = `${suppURL}suppliers`; // this works
    } else if (url.includes("products?category")) {
      window.location.href = `${categoryURL}suppliers`; // this works
    } else if (url.includes("products?div")) {
      window.location.href = `${divisionURL}suppliers`; // this works
    } else if (url.includes("products?ma")) {
      window.location.href = `${mainAreaURL}suppliers`; // this works
    } else if (url.includes("products")) {
      window.location.href = `${URL}suppliers`;
    } else {
      window.location.href = `${url}suppliers`;
    }
  };

  return (
    <div className="col-12 col-md-12 col-lg-8 col-xl-9 lol">
      {newProductState.length > 0 ? (
        newProductState?.map((product) => {
          const splittedImg = product?.images[0]?.url.split(";");
          const PRODUCT_IMG = splittedImg?.[1] ? splittedImg?.[1] : "-";
          const PRODUCT_ID = product?.documentId ? product?.documentId : "-";
          const PRODUCT_NAME = product?.productName
            ? product?.productName
            : "-";
          const CLONE_NUMBER = product?.cloneNumber
            ? product?.cloneNumber
            : "-";
          const CLONALITY = product?.clonality ? product?.clonality : "-";
          const REACTTVITY = product?.reactivityFull
            ? product?.reactivityFull
            : "-";
          const HOST_FULL = product?.hostFull ? product?.hostFull : "-";
          const ISOTYPE = product?.isotype ? product?.isotype : "-";
          const PRODUCT_CATEGORY = product?.ProductCategory
            ? product?.ProductCategory
            : "-";
          const SupplierName = product?.supplier?.name
            ? product?.supplier?.name
            : "-";
          const SIZE = product?.size ? product?.size : "-";
          const APPLICATION = product?.applicationsAbbreviation
            ? product?.applicationsAbbreviation
            : "-";
          const SKU = product?.sku ? product?.sku : "-";
          const SOURCE = product?.source ? product?.source : "-";
          const ASSAY_RANGE = product?.assayRange ? product?.assayRange : "-";
          const SAMPLE_TYPE = product?.sampleType ? product?.sampleType : "-";
          const SENSITIVTY = product?.sensitivity ? product?.sensitivity : "-";
          const Host_Source = product?.hostSource ? product?.hostSource : "-";
          const Reactivity = product?.reactivity ? product?.reactivity : "-";

          return (
            <CardProduct
              key={PRODUCT_ID}
              id={PRODUCT_ID}
              productImg={PRODUCT_IMG}
              description={PRODUCT_NAME}
              clonality={CLONALITY}
              productName={PRODUCT_NAME}
              hostFull={HOST_FULL}
              cloneNumber={CLONE_NUMBER}
              reactivityFull={REACTTVITY}
              category={PRODUCT_CATEGORY}
              isotype={ISOTYPE}
              supplier={SupplierName}
              size={SIZE}
              application={APPLICATION}
              sku={SKU}
              source={SOURCE}
              assayRange={ASSAY_RANGE}
              sampleType={SAMPLE_TYPE}
              sensitivity={SENSITIVTY}
              host_Source={Host_Source}
              reactivity={Reactivity}
            />
          );
        })
      ) : (
        <div className="no_products_found">
          {loadingState ? (
            <p className="loading_state">{t("Loading")}</p>
          ) : null}
          <p id="no_products_msg">{t("nothing_matches")}</p>
          <img src={NoProducts} alt=" " />
          <p id="no_products">{t("No_Products_Found")}</p>
          <div className="btns_no_products">
            <button onClick={backToProducts} className="reload_prod">
              {t("show_all_products")}
            </button>
            <a href="#footer" className="reload_prod">
              {t("contact_us")}
            </a>
            <button onClick={redirectToSuppliers} className="reload_prod">
              {t("show_all_suppliers")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Index;
