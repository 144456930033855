export const filterConstans = {
  FULL_SELECT_FILTER: "FULL_SELECT_FILTER",
  FULL_UNSELECT_FILTER: "FULL_UNSELECT_FILTER",
  FULL_UNSELECT_FILTERID: "FULL_UNSELECT_FILTERID",
  SELECT_FILTER: "SELECT_FILTER",
  UNSELECT_FILTER: "UNSELECT_FILTER",
  REMOVE_ALL_FILTERS: "REMOVE_ALL_FILTERS",
  ADD_PAGE: "ADD_PAGE",
  ADD_TOTAL_COUNT: "ADD_TOTAL_COUNT",
  SEARCH_KEYWORD: "SEARCH_KEYWORD",
  GET_CONTENTS_SUCCESS: "GET_CONTENTS_SUCCESS",
  GET_CONTENTS_FAILURE: "GET_CONTENTS_FAILURE",
  SEARCHED_PRODUCT: "SEARCHED_PRODUCT",
  GET_PRODUCTS_LOADING: "GET_PRODUCTS_LOADING",
  GET_PRODUCTS_SUCCESS: "GET_PRODUCTS_SUCCESS",
  GET_PRODUCTS_FAILED: "GET_PRODUCTS_FAILED",
  SORT_PRODUCTS_DESC: "SORT_PRODUCTS_DESC",
  SORT_PRODUCTS_ASC: "SORT_PRODUCTS_ASC",
  GET_SUGGESTIONS_LOADING: "GET_SUGGESTIONS_LOADING",
  GET_SUGGESTIONS_SUCCESS: "GET_SUGGESTIONS_SUCCESS",
  GET_SUGGESTIONS_FAILURE: "GET_SUGGESTIONS_FAILURE",
  SUGGESTION_KEYWORD: "SUGGESTION_KEYWORD",
  // Cart Constans
  ADD_ITEM: "ADD_ITEM",
  REMOVE_ITEM: "REMOVE_ITEM",
  ADD_ITEM_TO_CART_FROM_LIST: "ADD_ITEM_TO_CART_FROM_LIST",
  CLEAR_ITEM_FROM_CART: "CLEAR_ITEM_FROM_CART",
  TOGGLE_CART_HIDDEN: "TOGGLE_CART_HIDDEN",
  ADD_WITH_QUANTITY: "ADD_WITH_QUANTITY",
  CLEAR_CART: "CLEAR_CART",

  // suppliers constans
  GET_SUPPLIERS_LOADING: "GET_SUPPLIERS_LOADING",
  GET_SUPPLIERS_SUCCESS: "GET_SUPPLIERS_SUCCESS",
  GET_SUPPLIERS_FAILED: "GET_SUPPLIERS_FAILED",

  GET_CATEGORY_SUGGESTIONS_SUCCESS: "GET_CATEGORY_SUGGESTIONS_SUCCESS",
  GET_SUPPLIER_SUGGESTIONS_SUCCESS: "GET_SUPPLIER_SUGGESTIONS_SUCCESS",
  CLEAR_FILTERED_LIST: "CLEAR_FILTERED_LIST",
  FILTER_SUPPLIERS: "FILTER_SUPPLIERS",
  FILTER_SUPPLIERS_LOADING: "FILTER_SUPPLIERS_LOADING",
  FILTER_SUPPLIERS_SUCCESS: "FILTER_SUPPLIERS_SUCCESS",
  FILTER_SUPPLIERS_FAILURE: "FILTER_SUPPLIERS_FAILURE",
  GET_PRODUCT_BY_NAME_NEW: "GET_PRODUCT_BY_NAME_NEW",
  GET_PRODUCTS_SUCCESS_NEW: "GET_PRODUCTS_SUCCESS_NEW",
  GET_PRODUCTS_BY_FILTERS_LOADING: "GET_PRODUCTS_BY_FILTERS_LOADING",
  GET_PRODUCTS_BY_FILTERS_SUCCESS: "GET_PRODUCTS_BY_FILTERS_SUCCESS",
  GET_PRODUCTS_BY_FILTERS_FAILURE: "GET_PRODUCTS_BY_FILTERS_FAILURE",
  GET_SUPPLIERS_SUCCESS_NEW: "GET_SUPPLIERS_SUCCESS_NEW",
  GET_PRODUCTS_BYID_LOADING: "GET_PRODUCTS_BYID_LOADING",
  GET_PRODUCTS_BYID_SUCCESS: "GET_PRODUCTS_BYID_SUCCESS",
  GET_PRODUCTS_BYID_FAILURE: "GET_PRODUCTS_BY_BID_FAILURE",

  SUPP_PAGE: "SUPP_PAGE",
};
