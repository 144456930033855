import { filterConstans } from "../constants";

const initialState = {
  products: [],
  newProd: [],
  newTotalCount: 10,
  totalCount: 10,
  loading: false,
  error: "",
  fetchingByName: false,
  product: null,
};

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case filterConstans.GET_PRODUCTS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case filterConstans.GET_PRODUCTS_SUCCESS_NEW:
      return {
        ...state,
        newProd: action.payload.products,
        newTotalCount: action.payload.totalHits,
        loading: false,
      };
    case filterConstans.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload.products.edges,
        totalCount: action.payload.products.totalCount,
        fetchingByName: action.payload.fetchingByName,
        loading: false,
      };
    case filterConstans.SORT_PRODUCTS_ASC:
      return {
        ...state,
        products: action.payload.products.edges.sort((a, b) =>
          a?.node?.ITEM_DESCRIPTION > b?.node?.ITEM_DESCRIPTION ? 1 : -1
        ),
        totalCount: action.payload.products.totalCount,
        fetchingByName: action.payload.fetchingByName,
        loading: false,
      };
    case filterConstans.SORT_PRODUCTS_DESC:
      return {
        ...state,
        products: action.payload.products.edges.sort((a, b) =>
          b?.node?.ITEM_DESCRIPTION > a?.node?.ITEM_DESCRIPTION ? 1 : -1
        ),
        totalCount: action.payload.products.totalCount,
        fetchingByName: action.payload.fetchingByName,
        loading: false,
      };
    case filterConstans.GET_PRODUCTS_FAILED:
      return {
        ...state,
        error: action.payload.error,
      };
    case filterConstans.GET_PRODUCTS_BYID_SUCCESS:
      return {
        ...state,
        product: action.payload,
      };
    case filterConstans.GET_PRODUCT_CATEGORY_FILTER:
      return {
        ...state,
        products: action.payload.products.edges,
      };
    default:
      return state;
  }
};

export default productsReducer;
