import React from "react";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import Badge from "@mui/material/Badge";
import { useSelector } from "react-redux";
import { selectCartItems } from "../redux/selectors/cartSelector";
import "./ShopIcon.scss";

const ShopIcon = () => {
  const cartTotal = useSelector(selectCartItems);
  return (
    <Badge badgeContent={cartTotal.length} color="secondary">
      <ShoppingCartOutlinedIcon />
    </Badge>
  );
};

export default ShopIcon;
