import { filterConstans } from "../constants";

export const addPage = (page) => {
  return {
    type: filterConstans.ADD_PAGE,
    payload: page,
  };
};

export const addTotalCount = (totalCount) => {
  return {
    type: filterConstans.ADD_TOTAL_COUNT,
    payload: totalCount,
  };
};

export const supPage = (supPage) => {
  return {
    type: filterConstans.SUPP_PAGE,
    payload: supPage,
  };
};
