import { filterConstans } from "../constants";

const initialState = {
  serach: "",
  suggestions: [],
  catSuggestions: [],
  supplierSuggestions: [],
  error: "",
};

const suggestionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case filterConstans.SEARCH_KEYWORD:
      return {
        ...state,
        serach: action.payload,
      };
    case filterConstans.GET_CATEGORY_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        catSuggestions: action.payload,
      };
    case filterConstans.GET_SUPPLIER_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        supplierSuggestions: action.payload,
      };

    default:
      return state;
  }
};

export default suggestionsReducer;
