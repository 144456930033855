import React, { useEffect, useState } from "react";
import "./filter-product.scss";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ProductCardList from "../card-list-products/Index";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import { useDispatch, useSelector } from "react-redux";
import {
  fullSelectedList,
  fullUnselectedList,
  selectList,
  unselectList,
  removeallselected,
} from "../../redux/actions/filterAction";
import { getProductsByFilters } from "../../httpClient/index";
import SearchIcon from "@material-ui/icons/Search";
import { useHistory, useLocation } from "react-router-dom";
import { addPage } from "../../redux/actions/pageActions";
import { useTranslation } from "react-i18next";

function AccordionComponent(props) {
  const [wordEntered, setWordEntered] = useState("");
  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    if (props?.expanded) {
      setExpanded(props.id);
    }
  }, []);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Accordion
      expanded={expanded === props.id}
      onChange={handleChange(props.id)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className="filter_Title">{props.filterTitles}</Typography>
      </AccordionSummary>
      <div className="accordian_search">
        <input
          className="filter_search"
          name="search"
          type="text"
          placeholder={`Search ${props.placeholder}...`}
          onChange={(e) => setWordEntered(e.target.value)}
          value={wordEntered}
        />
        <button className="search-icon">
          <SearchIcon color="secondary" />
        </button>
      </div>
      <AccordionDetails>
        <div className={`filters ${props?.className}`}>
          {props?.data
            ?.filter((filter) => {
              return filter?.name != null
                ? filter?.name.toLowerCase().includes(wordEntered.toLowerCase())
                : filter?.name
                    .toLowerCase()
                    .includes(wordEntered.toLowerCase());
            })
            .sort(function (a, b) {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
              // return a.id - b.id;
            })
            .map((filter, i) => {
              return (
                <FormControlLabel
                  key={i}
                  className="filter_label"
                  id={`${filter?.name}`}
                  control={
                    <Checkbox
                      name={filter?.name}
                      value={filter?.name}
                      checked={
                        props.selectedList?.includes(filter?.id) ? true : false
                      }
                      icon={
                        <AddCircleOutlineOutlinedIcon
                          style={{ width: "70%" }}
                        />
                      }
                      checkedIcon={
                        <CircleCheckedFilled
                          style={{ color: "#0BB3A2", width: "70%" }}
                        />
                      }
                      onChange={props.selectedFilter(filter, i)}
                    />
                  }
                  label={`${
                    filter?.name !== null ? filter?.name : filter?.name
                  }  (${
                    filter?.name !== null ? filter?.count : filter?.count
                  })`}
                />
              );
            })}
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
function Index() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const page = useSelector((state) => state.page.page);
  const [newProductData, setNewProductData] = useState([]);
  const newProductState = useSelector((state) => state.filter.products);
  const selectedList = useSelector((state) => state.filter.selectedList);
  const divisionList = useSelector((state) => state.filter.divisions);
  const categoryList = useSelector((state) => state.filter.category);
  const supplierList = useSelector((state) => state.filter.supplier);
  const promotionList = useSelector((state) => state.filter.promotion);
  const productSubCategoryList = useSelector(
    (state) => state.filter.subCategory
  );
  const groupList = useSelector((state) => state.filter.area);
  const antigenSpeciesList = useSelector(
    (state) => state.filter.filterAntigenSpecies
  );
  const applicationList = useSelector(
    (state) => state.filter.filterApplication
  );
  const filterConjugateList = useSelector(
    (state) => state.filter.filterConjugate
  );
  const hostList = useSelector((state) => state.filter.filterHost);
  const reactivityList = useSelector((state) => state.filter.filterReactivity);
  // Total Count of Filters
  const divisionTotal = useSelector((state) => state.filter.divisions.length);
  const categoryTotal = useSelector((state) => state.filter.category.length);
  const supplierTotal = useSelector((state) => state.filter.supplier.length);
  const groupTotal = useSelector((state) => state.filter.area.length);
  let divisionSelectedList = [];
  let areaSelectedList = [];
  let productSubCategorySelectedList = [];
  let promotionSelectedList = [];
  let categorySelectedList = [];
  let supplierSelectedList = [];
  let antigenSpeciesSelectedList = [];
  let applicationSelectedList = [];
  let conjugateSelectedList = [];
  let hostSelectedList = [];
  let reactivitySelectedList = [];
  let keyword = "";
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const productId = query.get("search");
  const categoryId = query.get("category");
  const supplierId = query.get("sid");
  const divisionId = query.get("div");

  // useEffect(() => {
  //   //remove from storage selected filters if previous page was not product-details
  //   if (!document.referrer.includes("/product-details")) {
  //     localStorage.removeItem("DivisionID");
  //     localStorage.removeItem("AreaID");
  //     localStorage.removeItem("CategoryID");
  //     localStorage.removeItem("SupplierID");
  //     localStorage.removeItem("PromotionID");
  //     localStorage.removeItem("SubCategoryID");
  //     localStorage.removeItem("AntigenID");
  //     localStorage.removeItem("ApplicationID");
  //     localStorage.removeItem("ConjugateID");
  //     localStorage.removeItem("HostID");
  //     localStorage.removeItem("ReactivityID");
  //     dispatch(removeallselected());
  //   }
  // }, []);

  const areaStorage = localStorage.getItem("AreaID");
  const categoryStorage = localStorage.getItem("CategoryID");
  const divisionStorage = localStorage.getItem("DivisionID");
  const supplierStorage = localStorage.getItem("SupplierID");
  const promotionStorage = localStorage.getItem("PromotionID");
  const subCategoryStorage = localStorage.getItem("SubCategoryID");
  const antigenStorage = localStorage.getItem("AntigenID");
  const applicationStorage = localStorage.getItem("ApplicationID");
  const conjugateStorage = localStorage.getItem("ConjugateID");
  const hostStorage = localStorage.getItem("HostID");
  const reactivityStorage = localStorage.getItem("ReactivityID");

  const DivisionFilter = JSON.parse(localStorage.getItem("DivisionFilter"));
  const AreaFilter = JSON.parse(localStorage.getItem("AreaFilter"));
  const CategoryFilter = JSON.parse(localStorage.getItem("CategoryFilter"));
  const SupplierFilter = JSON.parse(localStorage.getItem("SupplierFilter"));
  const PromotionFilter = JSON.parse(localStorage.getItem("PromotionFilter"));
  const SubCategoryFilter = JSON.parse(
    localStorage.getItem("SubCategoryFilter")
  );
  const AntigenFilter = JSON.parse(localStorage.getItem("AntigenFilter"));
  const ApplicationFilter = JSON.parse(
    localStorage.getItem("ApplicationFilter")
  );
  const ConjugateFilter = JSON.parse(localStorage.getItem("ConjugateFilter"));
  const HostFilter = JSON.parse(localStorage.getItem("HostFilter"));
  const ReactivityFilter = JSON.parse(localStorage.getItem("ReactivityFilter"));

  const selectedFilter = (item, i) => () => {
    if (selectedList.includes(item.id)) {
      dispatch(unselectList(item.id));
      dispatch(fullUnselectedList(item));
      if (divisionStorage == item?.id) localStorage.removeItem("DivisionID");
      if (DivisionFilter?.id == item?.id)
        localStorage.removeItem("DivisionFilter");

      if (areaStorage == item?.id) localStorage.removeItem("AreaID");
      if (AreaFilter?.id == item?.id) localStorage.removeItem("AreaFilter");

      if (categoryStorage == item?.id) localStorage.removeItem("CategoryID");
      if (CategoryFilter?.id == item?.id)
        localStorage.removeItem("CategoryFilter");

      if (supplierStorage == item?.id) localStorage.removeItem("SupplierID");
      if (SupplierFilter?.id == item?.id)
        localStorage.removeItem("SupplierFilter");

      if (promotionStorage == item?.id) localStorage.removeItem("PromotionID");
      if (PromotionFilter?.id == item?.id)
        localStorage.removeItem("PromotionFilter");

      if (subCategoryStorage == item?.id)
        localStorage.removeItem("SubCategoryID");
      if (SubCategoryFilter?.id == item?.id)
        localStorage.removeItem("SubCategoryFilter");

      if (antigenStorage == item?.id) localStorage.removeItem("AntigenID");
      if (AntigenFilter?.id == item?.id)
        localStorage.removeItem("AntigenFilter");

      if (applicationStorage == item?.id)
        localStorage.removeItem("ApplicationID");
      if (ApplicationFilter?.id == item?.id)
        localStorage.removeItem("ApplicationFilter");

      if (conjugateStorage == item?.id) localStorage.removeItem("ConjugateID");
      if (ConjugateFilter?.id == item?.id)
        localStorage.removeItem("ConjugateFilter");

      if (hostStorage == item?.id) localStorage.removeItem("HostID");
      if (HostFilter?.id == item?.id) localStorage.removeItem("HostFilter");

      if (reactivityStorage == item?.id)
        localStorage.removeItem("ReactivityID");
      if (ReactivityFilter?.id == item?.id)
        localStorage.removeItem("ReactivityFilter");
    } else {
      dispatch(selectList(item.id));
      dispatch(fullSelectedList(item));
    }
  };

  useEffect(() => {
    if (DivisionFilter) {
      dispatch(fullSelectedList(DivisionFilter));
    }
    if (AreaFilter) {
      dispatch(fullSelectedList(AreaFilter));
    }
    if (CategoryFilter) {
      dispatch(fullSelectedList(CategoryFilter));
    }
    if (SupplierFilter) {
      dispatch(fullSelectedList(SupplierFilter));
    }
    if (PromotionFilter) {
      dispatch(fullSelectedList(PromotionFilter));
    }
    if (SubCategoryFilter) {
      dispatch(fullSelectedList(SubCategoryFilter));
    }
    if (AntigenFilter) {
      dispatch(fullSelectedList(AntigenFilter));
    }
    if (ApplicationFilter) {
      dispatch(fullSelectedList(ApplicationFilter));
    }
    if (ConjugateFilter) {
      dispatch(fullSelectedList(ConjugateFilter));
    }
    if (HostFilter) {
      dispatch(fullSelectedList(HostFilter));
    }
    if (ReactivityFilter) {
      dispatch(fullSelectedList(ReactivityFilter));
    }
  }, [
    DivisionFilter,
    AreaFilter,
    CategoryFilter,
    SupplierFilter,
    PromotionFilter,
    SubCategoryFilter,
    AntigenFilter,
    ApplicationFilter,
    ConjugateFilter,
    HostFilter,
    ReactivityFilter,
  ]);

  useEffect(() => {
    supplierList.map((supp) => {
      if (supp.id == supplierId) {
        dispatch(fullSelectedList(supp));
      }
    });

    categoryList.map((category) => {
      if (category.id == categoryId || categoryStorage) {
        dispatch(fullSelectedList(category));
      }
    });
    groupList.map((areaSt) => {
      if (areaSt.id == areaStorage) {
        dispatch(fullSelectedList(areaSt));
      }
    });
    divisionList.map((division) => {
      if (division.id == divisionId || divisionStorage) {
        dispatch(fullSelectedList(division));
      }
    });

    productSubCategoryList.map((sub) => {
      if (sub.id == subCategoryStorage) {
        dispatch(fullSelectedList(sub));
      }
    });

    promotionList.map((prom) => {
      if (prom.id == promotionStorage) {
        dispatch(fullSelectedList(prom));
      }
    });

    antigenSpeciesList.map((antigen) => {
      if (antigen.id == antigenStorage) {
        dispatch(fullSelectedList(antigen));
      }
    });

    applicationList.map((app) => {
      if (app.id == applicationStorage) {
        dispatch(fullSelectedList(app));
      }
    });

    filterConjugateList.map((con) => {
      if (con.id == conjugateStorage) {
        dispatch(fullSelectedList(con));
      }
    });

    hostList.map((host) => {
      if (host.id == hostStorage) {
        dispatch(fullSelectedList(host));
      }
    });

    reactivityList.map((react) => {
      if (react.id == reactivityStorage) {
        dispatch(fullSelectedList(react));
      }
    });
  }, [
    categoryList,
    groupList,
    divisionList,
    supplierList,
    productSubCategoryList,
    promotionList,
    antigenSpeciesList,
    applicationList,
    filterConjugateList,
    hostList,
    reactivityList,
  ]);

  useEffect(() => {
    dispatch(addPage(1));
  }, [selectedList]);

  const [isClicked, setIsClicked] = useState(true);
  let filterSplittedUrl = window.location.href.split("/");
  filterSplittedUrl.splice(0, 3);
  const countrySelected = window.location.pathname.split("/");
  const countrySplitted = countrySelected[1];
  let divisionFilters = "&did=";
  let promotionFilters = "&fpid=";
  let subCategoryFilters = "&fpscid=";
  let areaFilters = "&aid=";
  let categoryFilters = "&cid=";
  let supplierFilters = "&sid=";
  let antigenSpeciesListFilters = "&fasid=";
  let applicationListFilters = "&fappid=";
  let hostListFilters = "&fhid=";
  let reactivityListFilters = "&frid=";
  let conjugateListFilters = "&fcid=";

  useEffect(() => {
    if (selectedList.length > 0) {
      selectedList.map((item) => {
        divisionList.map((divisionItem) => {
          if (divisionItem.id === item) {
            divisionSelectedList.push(divisionItem);
          }
        });
        promotionList.map((prom) => {
          if (prom.id === item) {
            promotionSelectedList.push(prom);
          }
        });
        productSubCategoryList.map((sub) => {
          if (sub.id === item) {
            productSubCategorySelectedList.push(sub);
          }
        });
        groupList.map((areaOfInterestItem) => {
          if (areaOfInterestItem?.id === item) {
            areaSelectedList.push(areaOfInterestItem);
          }
        });
        categoryList.map((categoryItem) => {
          if (categoryItem?.id === item) {
            categorySelectedList.push(categoryItem);
          }
        });
        supplierList.map((supplierItem) => {
          if (supplierItem?.id === item) {
            supplierSelectedList.push(supplierItem);
          }
        });
        antigenSpeciesList.map((filterAntigen) => {
          if (filterAntigen?.id === item) {
            antigenSpeciesSelectedList.push(filterAntigen);
          }
        });
        applicationList.map((filterApp) => {
          if (filterApp?.id === item) {
            applicationSelectedList.push(filterApp);
          }
        });
        filterConjugateList.map((filterConjugate) => {
          if (filterConjugate?.id === item) {
            conjugateSelectedList.push(filterConjugate);
          }
        });
        hostList.map((filterHost) => {
          if (filterHost?.id === item) {
            hostSelectedList.push(filterHost);
          }
        });
        reactivityList.map((filterReact) => {
          if (filterReact?.id === item) {
            reactivitySelectedList.push(filterReact);
          }
        });
      });
    }
    divisionSelectedList.map((division) => {
      divisionFilters += `${division.id},`;
      localStorage.setItem("DivisionID", division.id);
      localStorage.setItem("DivisionFilter", JSON.stringify(division));
    });
    areaSelectedList.map((area) => {
      areaFilters += `${area.id},`;
      localStorage.setItem("AreaID", area.id);
      localStorage.setItem("AreaFilter", JSON.stringify(area));
    });
    categorySelectedList.map((category) => {
      categoryFilters += `${category.id},`;
      localStorage.setItem("CategoryID", category.id);
      localStorage.setItem("CategoryFilter", JSON.stringify(category));
    });
    supplierSelectedList.map((supp) => {
      supplierFilters += `${supp.id},`;
      localStorage.setItem("SupplierID", supp.id);
      localStorage.setItem("SupplierFilter", JSON.stringify(supp));
    });
    promotionSelectedList.map((promotion) => {
      promotionFilters += `${promotion.id},`;
      localStorage.setItem("PromotionID", promotion.id);
      localStorage.setItem("PromotionFilter", JSON.stringify(promotion));
    });
    productSubCategorySelectedList.map((subCategory) => {
      subCategoryFilters += `${subCategory.id},`;
      localStorage.setItem("SubCategoryID", subCategory.id);
      localStorage.setItem("SubCategoryFilter", JSON.stringify(subCategory));
    });
    antigenSpeciesSelectedList.map((antigen) => {
      antigenSpeciesListFilters += `${antigen.id},`;
      localStorage.setItem("AntigenID", antigen.id);
      localStorage.setItem("AntigenFilter", JSON.stringify(antigen));
    });
    applicationSelectedList.map((app) => {
      applicationListFilters += `${app.id},`;
      localStorage.setItem("ApplicationID", app.id);
      localStorage.setItem("ApplicationFilter", JSON.stringify(app));
    });
    conjugateSelectedList.map((conjugate) => {
      conjugateListFilters += `${conjugate.id},`;
      localStorage.setItem("ConjugateID", conjugate.id);
      localStorage.setItem("ConjugateFilter", JSON.stringify(conjugate));
    });
    hostSelectedList.map((host) => {
      hostListFilters += `${host.id},`;
      localStorage.setItem("HostID", host.id);
      localStorage.setItem("HostFilter", JSON.stringify(host));
    });
    reactivitySelectedList.map((react) => {
      reactivityListFilters += `${react.id},`;
      localStorage.setItem("ReactivityID", react.id);
      localStorage.setItem("ReactivityFilter", JSON.stringify(react));
    });

    let allFiltersConcat =
      divisionFilters.slice(0, -1) +
      areaFilters.slice(0, -1) +
      categoryFilters.slice(0, -1) +
      supplierFilters.slice(0, -1) +
      promotionFilters.slice(0, -1) +
      subCategoryFilters.slice(0, -1) +
      antigenSpeciesListFilters.slice(0, -1) +
      applicationListFilters.slice(0, -1) +
      conjugateListFilters.slice(0, -1) +
      hostListFilters.slice(0, -1) +
      reactivityListFilters.slice(0, -1);

    if (productId !== null) {
      keyword = productId;
    } else {
      keyword = "";
    }

    // ↓ filters in local storage
    // console.log("inside local storage for category", fullSelectedListT);

    if (
      categoryStorage !== null &&
      isClicked &&
      !selectedList.includes(categoryStorage)
    ) {
      allFiltersConcat = allFiltersConcat.replace(
        "cid",
        `cid=${categoryStorage}`
      );
      selectedList.push(categoryStorage);
      setIsClicked(false);
    }

    if (
      areaStorage !== null &&
      isClicked &&
      !selectedList.includes(areaStorage)
    ) {
      allFiltersConcat = allFiltersConcat.replace("aid", `aid=${areaStorage}`);
      selectedList.push(areaStorage);
      setIsClicked(false);
    }

    if (
      divisionStorage !== null &&
      isClicked &&
      !selectedList.includes(divisionStorage)
    ) {
      allFiltersConcat = allFiltersConcat.replace(
        "did",
        `did=${divisionStorage}`
      );
      selectedList.push(divisionStorage);
      setIsClicked(false);
    }

    if (
      supplierStorage !== null &&
      isClicked &&
      !selectedList.includes(supplierStorage)
    ) {
      allFiltersConcat = allFiltersConcat.replace(
        "sid",
        `sid=${supplierStorage}`
      );
      selectedList.push(supplierStorage);
      const supplierFullObj = supplierList.filter(
        (supp) => supp.id == supplierStorage
      );

      console.log("supplierFullObj", supplierList);
      setIsClicked(false);
    }

    if (
      promotionStorage !== null &&
      isClicked &&
      !selectedList.includes(promotionStorage)
    ) {
      allFiltersConcat = allFiltersConcat.replace(
        "fpid",
        `fpid=${promotionStorage}`
      );
      selectedList.push(promotionStorage);
      setIsClicked(false);
    }

    if (
      subCategoryStorage !== null &&
      isClicked &&
      !selectedList.includes(subCategoryStorage)
    ) {
      allFiltersConcat = allFiltersConcat.replace(
        "fpscid",
        `fpscid=${subCategoryStorage}`
      );
      selectedList.push(subCategoryStorage);
      setIsClicked(false);
    }

    if (
      antigenStorage !== null &&
      isClicked &&
      !selectedList.includes(antigenStorage)
    ) {
      allFiltersConcat = allFiltersConcat.replace(
        "fasid",
        `fasid=${antigenStorage}`
      );
      selectedList.push(antigenStorage);
      setIsClicked(false);
    }

    if (
      applicationStorage !== null &&
      isClicked &&
      !selectedList.includes(applicationStorage)
    ) {
      allFiltersConcat = allFiltersConcat.replace(
        "fappid",
        `fappid=${applicationStorage}`
      );
      selectedList.push(applicationStorage);
      setIsClicked(false);
    }

    if (
      conjugateStorage !== null &&
      isClicked &&
      !selectedList.includes(conjugateStorage)
    ) {
      allFiltersConcat = allFiltersConcat.replace(
        "fcid",
        `fcid=${conjugateStorage}`
      );
      selectedList.push(conjugateStorage);
      setIsClicked(false);
    }

    if (
      hostStorage !== null &&
      isClicked &&
      !selectedList.includes(hostStorage)
    ) {
      allFiltersConcat = allFiltersConcat.replace(
        "fhid",
        `fhid=${hostStorage}`
      );
      selectedList.push(hostStorage);
      setIsClicked(false);
    }

    if (
      reactivityStorage !== null &&
      isClicked &&
      !selectedList.includes(reactivityStorage)
    ) {
      allFiltersConcat = allFiltersConcat.replace(
        "frid",
        `frid=${reactivityStorage}`
      );
      selectedList.push(reactivityStorage);
      setIsClicked(false);
    }

    // ↑ filters in local storage

    if (
      categoryId !== null &&
      isClicked &&
      !selectedList.includes(categoryId)
    ) {
      allFiltersConcat = allFiltersConcat.replace("cid", `cid=${categoryId}`);
      selectedList.push(categoryId);
      setIsClicked(false);
    }

    if (
      divisionId !== null &&
      isClicked &&
      !selectedList.includes(divisionId)
    ) {
      allFiltersConcat = allFiltersConcat.replace("did", `did=${divisionId}`);
      selectedList.push(divisionId);
      setIsClicked(false);
    }
    if (
      supplierId !== null &&
      isClicked &&
      !selectedList.includes(supplierId)
    ) {
      allFiltersConcat = allFiltersConcat.replace("sid", `sid=${supplierId}`);
      selectedList.push(supplierId);
      setIsClicked(false);
    }

    if (countrySplitted === "al") {
      dispatch(
        getProductsByFilters(allFiltersConcat, keyword, "AL", (page - 1) * 10)
      );
    } else if (countrySplitted === "bih") {
      dispatch(
        getProductsByFilters(allFiltersConcat, keyword, "BIH", (page - 1) * 10)
      );
    } else if (countrySplitted === "bg") {
      dispatch(
        getProductsByFilters(allFiltersConcat, keyword, "BG", (page - 1) * 10)
      );
    } else if (countrySplitted === "hr") {
      dispatch(
        getProductsByFilters(allFiltersConcat, keyword, "HR", (page - 1) * 10)
      );
    } else if (countrySplitted === "cz") {
      dispatch(
        getProductsByFilters(allFiltersConcat, keyword, "CZ", (page - 1) * 10)
      );
    } else if (countrySplitted === "hu") {
      dispatch(
        getProductsByFilters(allFiltersConcat, keyword, "HU", (page - 1) * 10)
      );
    } else if (countrySplitted === "xs") {
      dispatch(
        getProductsByFilters(allFiltersConcat, keyword, "XK", (page - 1) * 10)
      );
    } else if (countrySplitted === "mk") {
      dispatch(
        getProductsByFilters(allFiltersConcat, keyword, "MK", (page - 1) * 10)
      );
    } else if (countrySplitted === "me") {
      dispatch(
        getProductsByFilters(allFiltersConcat, keyword, "ME", (page - 1) * 10)
      );
    } else if (countrySplitted === "pl") {
      dispatch(
        getProductsByFilters(allFiltersConcat, keyword, "PL", (page - 1) * 10)
      );
    } else if (countrySplitted === "ro") {
      dispatch(
        getProductsByFilters(allFiltersConcat, keyword, "RO", (page - 1) * 10)
      );
    } else if (countrySplitted === "sr") {
      dispatch(
        getProductsByFilters(allFiltersConcat, keyword, "SR", (page - 1) * 10)
      );
    } else if (countrySplitted === "sl") {
      dispatch(
        getProductsByFilters(allFiltersConcat, keyword, "SK", (page - 1) * 10)
      );
    } else if (countrySplitted === "sk") {
      dispatch(
        getProductsByFilters(allFiltersConcat, keyword, "SK", (page - 1) * 10)
      );
    } else if (countrySplitted === "si") {
      dispatch(
        getProductsByFilters(allFiltersConcat, keyword, "SI", (page - 1) * 10)
      );
    } else if (countrySplitted === "ch") {
      dispatch(
        getProductsByFilters(allFiltersConcat, keyword, "CH", (page - 1) * 10)
      );
    } else {
      dispatch(
        getProductsByFilters(allFiltersConcat, keyword, "AT", (page - 1) * 10)
      );
    }
  }, [selectedList, page, dispatch]);

  const isMobile = 700;

  useEffect(() => {
    setNewProductData([
      {
        mainFilters: (
          <div className="filter__Header">
            <p className="filter_header_title">Filters</p>
          </div>
        ),
      },
      {
        id: "panel1",
        filterTitles: "Promotions",
        filters: promotionList,
        className: "promotions_filter",
        expanded: window.screen.width < isMobile ? false : true,
      },
      {
        id: "panel2",
        filterTitles: "Divisions",
        filters: divisionList,
        className: "divisions_filter",
        expanded: window.screen.width < isMobile ? false : true,
      },
      {
        id: "panel3",
        filterTitles: "Area of Interest",
        filters: groupList,
        className: "area_of_interest_filter",
        expanded: window.screen.width < isMobile ? false : true,
      },
      {
        id: "panel4",
        filterTitles: "Product Group",
        filters: categoryList,
        className: "product_category_filter",
        expanded: window.screen.width < isMobile ? false : true,
      },
      {
        id: "panel5",
        filterTitles: "Product Sub Group",
        filters: productSubCategoryList,
        className: "product_sub_category_filter",
        expanded: window.screen.width < isMobile ? false : true,
      },
      {
        id: "panel6",
        filterTitles: "Suppliers",
        filters: supplierList,
        className: "suppliers_filter",
        expanded: window.screen.width < isMobile ? false : true,
      },
      {
        otherFilters: (
          <div className="filter__Header">
            <p className="filter_header_title">Other Filters</p>
          </div>
        ),
      },
      // {
      //   id: "panel7",
      //   filterTitles: "Antigen Species",
      //   filters: antigenSpeciesList,
      //   className: "antigen_species_filter",
      // },
      {
        id: "panel8",
        filterTitles: "Application",
        filters: applicationList,
        className: "application_filter",
      },
      {
        id: "panel9",
        filterTitles: "Conjugate",
        filters: filterConjugateList,
        className: "conjugate_filter",
      },
      {
        id: "panel10",
        filterTitles: "Host",
        filters: hostList,
        className: "host_filter",
      },
      {
        id: "panel11",
        filterTitles: "Reactivity",
        filters: reactivityList,
        className: "reactivity_filter",
      },
    ]);
  }, [divisionTotal, groupTotal, categoryTotal, supplierTotal]);

  return (
    <div className="container">
      <div className="row">
        {newProductState.length > 0 ? (
          <div className="col-12 col-md-12 col-lg-4 col-xl-3 filters_for_products">
            {newProductData.map((items, i) => (
              <>
                {items.mainFilters}
                {newProductData[i].filters?.length > 0 && (
                  <AccordionComponent
                    key={i}
                    id={items.id}
                    className={items.className}
                    filterTitles={items.filterTitles}
                    data={items.filters}
                    selectedList={selectedList}
                    selectedFilter={selectedFilter}
                    placeholder={items.filterTitles}
                    expanded={items?.expanded}
                  />
                )}
                {items.otherFilters}
              </>
            ))}
          </div>
        ) : null}
        <ProductCardList />
      </div>
    </div>
  );
}
export default Index;
